import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Dashboard from '@mui/icons-material/Dashboard';
import MoneyIcon from '@mui/icons-material/Savings';
import Code from '@mui/icons-material/Code';
import StreamIcon from '@mui/icons-material/Stream';


import Logs from '@mui/icons-material/Summarize';
import AdminIcon from '@mui/icons-material/Security';
import Settings from '@mui/icons-material/Settings';
import SignalDashboard from '@mui/icons-material/DashboardCustomize';

import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../interface/ApplicationState';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { toggleDrawer } from '../../redux/actions/userActions';
import { DrawerHeader } from './DrawerHeader';
import { CustomDrawer } from './CustomDrawer';



export const NavigationDrawer = () =>
{
  const isDarkModeOn = useSelector((state: ApplicationState) => state.userStore.user.isDarkModeOn)
  const isAdmin = useSelector((state: ApplicationState) => state.userStore.user.isAdmin)
  const isDrawerOpen = useSelector((state: ApplicationState) => state.userStore.isDrawerOpen)
  const dispatch = useDispatch()


  const styles = {
    link: {
      textDecoration: "none",
      color: "grey"
    },
    selectedItem: {
      backgroundColor: isDarkModeOn ? "#424242" : "#EEEEEE"
    }
  }



  return(
    <CustomDrawer
      open={isDrawerOpen}
      variant="permanent" >

    <DrawerHeader>
      <IconButton onClick={() => dispatch(toggleDrawer(!isDrawerOpen))}>
        {<ChevronLeftIcon />}
      </IconButton>
    </DrawerHeader>
    
     <List>
       {/* Dashboard */}
       <NavLink to="/dashboard" style={styles.link}>
        {({ isActive }) => (
          <ListItem disablePadding style={isActive ? styles.selectedItem : undefined}>
          <ListItemButton>
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </ListItem>) }
      </NavLink>

      {/* Backtesting Overview */}
      <NavLink to="/backtesting" style={styles.link}>
        {({ isActive }) => (
          <ListItem disablePadding style={isActive ? styles.selectedItem : undefined}>
          <ListItemButton>
            <ListItemIcon>
              <Code />
            </ListItemIcon>
            <ListItemText primary="Backtesting" />
          </ListItemButton>
        </ListItem>) }
      </NavLink>

      {/* Profit Overview */}
        <NavLink to="/profit" style={styles.link}>
        {({ isActive }) => (
          <ListItem disablePadding style={isActive ? styles.selectedItem : undefined}>
          <ListItemButton>
            <ListItemIcon>
              <MoneyIcon />
            </ListItemIcon>
            <ListItemText primary="Profit" />
          </ListItemButton>
        </ListItem>) }
      </NavLink>

      {/* Trends */}
      <NavLink to="/signals" style={styles.link}>
        {({ isActive }) => (
          <ListItem disablePadding style={isActive ? styles.selectedItem : undefined}>
          <ListItemButton>
            <ListItemIcon>
              <StreamIcon />
            </ListItemIcon>
            <ListItemText primary="Signals" />
          </ListItemButton>
        </ListItem>) }
      </NavLink>

        {/* Logs */}
        <NavLink to="/logs" style={styles.link}>
        {({ isActive }) => (
          <ListItem disablePadding style={isActive ? styles.selectedItem : undefined}>
          <ListItemButton>
            <ListItemIcon>
              <Logs />
            </ListItemIcon>
            <ListItemText primary="Logs" />
          </ListItemButton>
        </ListItem>) }
      </NavLink>

      { isAdmin && <NavLink to="/admin" style={styles.link}>
        {({ isActive }) => (
          <ListItem disablePadding style={isActive ? styles.selectedItem : undefined}>
          <ListItemButton>
            <ListItemIcon>
              <AdminIcon />
            </ListItemIcon>
            <ListItemText primary="User Admin" />
          </ListItemButton>
        </ListItem>
        )}
      </NavLink> }

      { isAdmin && <NavLink to="/signalStats" style={styles.link}>
        {({ isActive }) => (
          <ListItem disablePadding style={isActive ? styles.selectedItem : undefined}>
          <ListItemButton>
            <ListItemIcon>
              <SignalDashboard />
            </ListItemIcon>
            <ListItemText primary="Signals Admin" />
          </ListItemButton>
        </ListItem>
        )}
      </NavLink> }
    
      <NavLink to="/settings" style={styles.link}>
        {({ isActive }) => (
          <ListItem disablePadding style={isActive ? styles.selectedItem : undefined}>
          <ListItemButton>
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </ListItem>
        )}
      </NavLink>

    

  </List>
  </CustomDrawer>
   
  )
}

