import { BillingActions } from "../../actions/billingActions"

const initialState = {

}

export const billingReducer = (state = initialState, action: any) =>
{
  switch(action.type)
  {
    case BillingActions.CREATE_USER_BEGIN:
      return {
        ...state,
        isCreatingUser: true,
      }
  case BillingActions.CREATE_USER_SUCCESS:
    return {
      ...state,
      isCreatingUser: false,
      stripeCustomer: action.data,
      createCustomerSuccess: "Successfully created stripe user",
    }  
  case BillingActions.CREATE_USER_ERROR:
    return {
      ...state,
      isCreatingUser: false,
      createCustomerError: action.error,
      createCustomerSuccess: "",
    }
    case BillingActions.CREATE_USER_CLEAR:
      return {
        ...state,
        isCreatingUser: false,
        createCustomerError: undefined,
        createCustomerSuccess: undefined,
      }
    case BillingActions.GET_SUBSCRIPTION_BEGIN:
      return {
        ...state,
        isGettingSubscriptions: true,
        subscriptionsError: undefined,
      }
    case BillingActions.GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isGettingSubscriptions: false,
        subscriptions: action.data,
      }
    case BillingActions.GET_SUBSCRIPTION_ERROR:
      return {
        ...state,
        isGettingSubscriptions: false,
        subscriptionsError: action.error,
      }
    case BillingActions.GET_SUBSCRIPTION_STATUS_BEGIN:
      return {
        ...state,
        isGettingSubscriptions: true,
        subscriptionsError: undefined,
      }
    case BillingActions.GET_SUBSCRIPTION_STATUS_SUCCESS:
      return {
        ...state,
        isGettingSubscriptions: false,
        subscriptionStatus: action.data,
      }
    case BillingActions.GET_SUBSCRIPTION_STATUS_ERROR:
      return {
        ...state,
        isGettingSubscriptions: false,
        subscriptionsError: action.error,
      }
    case BillingActions.ADD_CARD_BEGIN:
      return {
        ...state,
        isAddingCreditCard: true,
        addCardError: undefined,
        addCardSuccess: undefined,
      }
    case BillingActions.ADD_CARD_SUCCESS:
      return {
        ...state,
        isAddingCreditCard: false,
        addCardSuccess: action.data,
        addCardError: undefined,
      }
    case BillingActions.ADD_CARD_ERROR:
      return {
        ...state,
        isAddingCreditCard: false,
        addCardError: action.error,
      }
    case BillingActions.ADD_CARD_CLEAR:
      return {
        ...state,
        isAddingCreditCard: false,
        addCardError: undefined,
        addCardSuccess: undefined,
      }
    case BillingActions.ADD_SUBSCRIPTION_BEGIN:
      return {
        ...state,
        isAddingSubscription: true,
        addSubscriptionError: undefined,
        addSubscriptionSuccess: undefined,
      }
    case BillingActions.ADD_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isAddingSubscription: false,
        addSubscriptionSuccess: action.data,
      }
    case BillingActions.ADD_SUBSCRIPTION_ERROR:
      return {
        ...state,
        isAddingSubscription: false,
        addSubscriptionError: action.error,
      }
      case BillingActions.ADD_SUBSCRIPTION_CLEAR:
      return {
        ...state,
        isAddingSubscription: false,
        addSubscriptionError: undefined,
        addSubscriptionSuccess: undefined,
      }
    default:
      return state
  }
}