import Grid from "@mui/material/Grid"
import { AdminApi } from "../../../api/AdminApi"
import { User } from "../../../interface/User"
import { AccountInfoSection } from "./AccountInfoSection"
import Alert from "@mui/material/Alert"
import { useEffect, useState } from "react"
import { AccountInfo } from "../../../interface/AccountInfo"
import List from "@mui/material/List"
import { LoadingView } from "../../placeholder/LoadingView"


interface UserAssetsSummaryProps
{
  user: User | undefined
}

export const UserAssetsSummary = ({ user }: UserAssetsSummaryProps) =>
{
  const [ errorGettingAccountInfo, setErrorGettingAccountInfo ] = useState("")
  const [ accountInfo, setAccountInfo ] = useState<AccountInfo | undefined>()
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {

    getAccountInfo()

  }, [])

  const getAccountInfo = async () =>
  {
    try
    {
      setLoading(true)
      if(user?._id)
      {
        setErrorGettingAccountInfo("")
        const adminApi = new AdminApi()
        const accountInfo = await adminApi.getAccountInfo(user?._id)
        setAccountInfo(accountInfo)
        setLoading(false)

      }
    }
    catch(error)
    {
      const errorCast = error as any
      setErrorGettingAccountInfo(errorCast?.message?.response?.data?.error?.message || "Looks like something went wrong while fetching the user account info")
      setLoading(false)
      console.error(error)
    }
  }

  return(
    <>
     <Grid item>
      { !loading ? <List style={styles.list}>
        { !errorGettingAccountInfo && <AccountInfoSection accountInfo={accountInfo} userId={user?._id} /> }
        { errorGettingAccountInfo && <Alert color="error">{errorGettingAccountInfo}</Alert>}
      </List> : <LoadingView />}
      </Grid>
    </>
  )
}

const styles = {
  list:{
    overflow: "auto",
    height: window.screen.height / 2
  },
}