import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/CurrencyBitcoin';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { Stripe } from "stripe";
import CardActionArea from "@mui/material/CardActionArea";

interface SubscriptionItemProps
{
  product: Stripe.Product
  setSelectedProductId: (id: string) => void
  selectedProductId: string
}

export const SubscriptionItem = ({ setSelectedProductId, product, selectedProductId }: SubscriptionItemProps) =>
{
  return(
    <CardActionArea onClick={() => setSelectedProductId(product.id === selectedProductId ? "" : product.id)}>
    <ListItem >
    <ListItemAvatar>
      <Avatar>
        { selectedProductId === product.id ? <CheckCircle color="primary" /> : <ImageIcon />} 
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary={product.name} secondary={product.description} />
  </ListItem>
  </CardActionArea>
  )
}