import Typography from "@mui/material/Typography"
import { GREEN, RED } from "../../Theme/theme"
import Badge from "@mui/material/Badge"


interface ProfitPillProps
{
  profit: number
}

export const ProfitTablet = ({ profit }: ProfitPillProps) =>
{
  return(
    <div style={{ borderRadius: 16, backgroundColor: profit > 0 ? GREEN : RED, padding: 4, textAlign: 'center' }}>
    <Typography fontWeight={700} variant="subtitle2">Profit: ${profit}</Typography>
    </div>
  )
}