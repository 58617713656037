import { useState } from 'react';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';


import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import ChatIcon from '@mui/icons-material/SupportAgent'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import UserIcon from '@mui/icons-material/AccountCircle';
import RunLogsIcon from '@mui/icons-material/ListOutlined';
import ProfitIcon from '@mui/icons-material/Money';
import UserAssetsIcon from '@mui/icons-material/Home'
import EmailIcon from '@mui/icons-material/Email'
import CircularProgress from '@mui/material/CircularProgress';

// Custom
import { Transition } from '../SellModal/Transition';
import { AdminApi } from '../../../api/AdminApi';
import { User } from '../../../interface/User';
import { ConfirmModal } from '../Confirm/ConfirmModal';
import { ProfitSummarySection } from './ProfitSummarySection';
import { useDispatch, useSelector } from 'react-redux';
import { startChatWithUser, toggleChatModal } from '../../../redux/actions/chatActions';
import { ApplicationState } from '../../../interface/ApplicationState';
import { UserInfo } from './UserInfo';
import { UserAssetsSummary } from './UserAssetsSummary';
import { UserRunLogs } from './UserRunLogs';

interface UserDetailsModalProps
{
  open: boolean
  handleClose: (reload: boolean) => void
  user: User | undefined
}

export const UserDetailsModal = ({ open, handleClose, user }: UserDetailsModalProps) =>
{
  const [ showConfirmDeleteUserModal , setShowConfirmDeleteUserModal ] = useState(false)
  const [ isSendingOnboardEmail, setSendingOnboardEmail ] = useState(false)
  const [ didSendOnboardingEmail, setSentOnboardingEmail ] = useState("")
  const [ currentTab, setCurrentTab ] = useState(0)
  const isCreatingChat = useSelector((state: ApplicationState) => state.chat.isCreatingChat)

  const dispatch = useDispatch()

  const deleteUser = async (userId: string | undefined) =>
  {
    if(userId)
    {
      const adminApi = new AdminApi()
      await adminApi.deleteUser(userId)
      handleClose(true)
    }
    
  }

  const sendOnboardEmail = async (email: string | undefined) =>
  {
    try
    {
      if(email)
      {
        setSendingOnboardEmail(true)
        const adminApi = new AdminApi()
        await adminApi.sendOnboardEmail(email)
        setSentOnboardingEmail("Successfully sent email OK")
      }
  
      setSendingOnboardEmail(false)
    }
    catch(error)
    {
      console.error(error)
      setSendingOnboardEmail(false)
      setSentOnboardingEmail("Error sending email to user" )

    }
   

  }

  const handleStartChat = () =>
  {
    if(user && user._id)
    {
      dispatch(toggleChatModal(true))
      dispatch(startChatWithUser("AlgoTrader Team", user?._id))
      handleClose(false)
    }

  }

  return(
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth="xl"
      onClose={() => handleClose(false)}
      aria-describedby="alert-dialog-slide-description">
    <DialogTitle>User Details for: {user?.username}</DialogTitle>
    <DialogContent>       

    <Tabs value={currentTab} onChange={(e, i) => setCurrentTab(i)} style={styles.tabs}>
      <Tab icon={<UserIcon />} label="User Details" />
      <Tab icon={<ProfitIcon />} label="Profit" />
      <Tab icon={<UserAssetsIcon />} label="Assets" />
      <Tab icon={<RunLogsIcon />} label="Run Logs" />

    </Tabs>

    <Grid container direction="column" spacing={2}>

      { currentTab === 0 && <UserInfo user={user} />} 
      { currentTab === 1 && <ProfitSummarySection user={user} />} 
      { currentTab === 2 && <UserAssetsSummary user={user} />} 
      { currentTab === 3 && <UserRunLogs user={user} />} 

      </Grid>

  

    </DialogContent>
    <DialogActions>
      <Button disabled={isCreatingChat} onClick={handleStartChat} startIcon={isCreatingChat ? <CircularProgress size={16} /> : <ChatIcon />} >Chat</Button>
      <Button disabled={isSendingOnboardEmail} onClick={() => sendOnboardEmail(user?.email)} startIcon={isSendingOnboardEmail ? <CircularProgress size={16} /> : <EmailIcon />} >Send onboard email</Button>
      <Button onClick={() => setShowConfirmDeleteUserModal(true)} startIcon={<DeleteIcon />} >Delete User</Button>
      <Button onClick={() => handleClose(false)} startIcon={<CloseIcon />}>Close</Button>
    </DialogActions>

    { showConfirmDeleteUserModal && <ConfirmModal 
      title="Confirm delete user"
      description="This will delete the user, stripe account, and run events. Are you sure?"
      id={user?._id || ""} 
      open={showConfirmDeleteUserModal} 
      handleClose={() => setShowConfirmDeleteUserModal(false)} 
      onConfirm={(userId) => deleteUser(userId)} /> }

    <Snackbar open={!!didSendOnboardingEmail} ><Alert severity="info">{didSendOnboardingEmail}</Alert></Snackbar>

  </Dialog>
  )
}

const styles = {
  tabs: {
    marginBottom: 16,
  }
}
