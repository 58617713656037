import Card from '@mui/material/Card'
import { AVATAR_SIZE, styles } from '../../Pages/Dashboard'
import Typography from '@mui/material/Typography'
import { Skeleton } from '@mui/material'
import Avatar from '@mui/material/Avatar'

import { Summary, SummaryItem } from '../../interface/Summary'

import { getBalanceForAsset } from '../../utils/getBalanceForAsset'
import Tooltip from '@mui/material/Tooltip'

interface DailyStatProps
{
  summary: Summary | undefined
  summaryItem: SummaryItem | undefined
  title: string
  loading: boolean
  color: string
  assetName: string
}

export const DailyStat = ({ summaryItem, summary, title, loading, assetName, color }: DailyStatProps) =>
{

  const getChangePercentColor = () =>
  {
    if(summary && summaryItem && Number(summaryItem?.dailyStats.priceChangePercent) > 0) return "#66BB6A"
    return "#FF5722"
  }

  return(
    <Card style={styles.card}>
    { !loading ? <Typography fontWeight={700}>{title}</Typography> : <Skeleton width={200} />}

    <div style={{ display: 'flex', flexDirection: "row"}}>


    <div style={{ display: 'flex', flexDirection: "column", flex: 1}}>
      { !loading ? 
      <>
      <Typography color="textSecondary" variant="caption">Asset Value (USD): ${summary && summaryItem?.estValueInUsd.toFixed(2)}</Typography>
      <Typography color="textSecondary" variant="caption">Ask Price: ${summary && Number(summaryItem?.dailyStats.askPrice).toFixed(2)}</Typography>
      <Typography color="textSecondary" variant="caption">Bid Price: ${summary && Number(summaryItem?.dailyStats.bidPrice).toFixed(2)}</Typography>
      <Typography color="textSecondary" variant="caption">High Price: ${summary && Number(summaryItem?.dailyStats.highPrice).toFixed(2)}</Typography>
      <Typography color="textSecondary" variant="caption">Last Price: ${summary && Number(summaryItem?.dailyStats.lastPrice).toFixed(2)}</Typography>
      <Typography color="textSecondary" variant="caption">Low Price: ${summary && Number(summaryItem?.dailyStats.lowPrice).toFixed(2)}</Typography>
      <Typography color="textSecondary" variant="caption">Price Change: ${summary && Number(summaryItem?.dailyStats.priceChange).toFixed(2)}</Typography>
      <Typography color="textSecondary" variant="caption" style={{ backgroundColor: getChangePercentColor(), borderRadius: 10, width: 160, padding: 2}}>Change Percent: {summary && Number(summaryItem?.dailyStats.priceChangePercent)}%</Typography> 
       </>
      :
      [0, 0, 0, 0, 0, 0, 0, 0].map((i, key) => <Skeleton width={150} key={key} />)
      }

    </div>

    {loading ? 
    <Skeleton style={styles.loading} variant="circular" width={AVATAR_SIZE} height={AVATAR_SIZE} /> : 
     
      <Tooltip title={`Current ${assetName}`}>
        <Avatar style={{ fontWeight: "700", width: AVATAR_SIZE, height: AVATAR_SIZE, float: "right", backgroundColor: color }}>{getBalanceForAsset(summary, assetName)?.free}</Avatar>
      </Tooltip>
     
    }

    </div>




    </Card>
  )
}