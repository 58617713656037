
import Typography from '@mui/material/Typography'
import { OcoOrder } from '../interface/OcoOrder'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import moment from 'moment'
import Card from '@mui/material/Card';

// Icons
import StreamIcon from '@mui/icons-material/Stream';
import DeleteForever from '@mui/icons-material/DeleteForever';

import { IconButton } from '@mui/material';
import { ConfirmModal } from './modals/Confirm/ConfirmModal';
import { useState } from 'react';

interface OCOOrdersProps
{
  ocoOrders: OcoOrder[] | undefined
  handleRemoveOco: (marketId: string, orderId: string, ocoOrderId: number) => void
}

export const OCOOrders = ({ ocoOrders, handleRemoveOco }: OCOOrdersProps) =>
{
  
  const [ ocoOrder, setOcoOrder ] = useState<OcoOrder | undefined>()

  const getColor = (ocoOrder: OcoOrder) =>
  { 
    if(ocoOrder.currentPrice > ocoOrder.entry) return "#66BB6A"
    if(ocoOrder.currentPrice < ocoOrder.entry) return "#EF5350"
    return "#BDBDBD"
  }

  const getProfitPercent = (ocoOrder: OcoOrder) =>
  {
    try
    {
      return (ocoOrder.currentPrice / ocoOrder.entry * 100 - 100).toFixed(2) + "%"
    }
    catch(error)
    {
      return "0%"
    }
  }
  

  const handleRemove = () =>
  {
    if(ocoOrder?.marketId && ocoOrder?._id && ocoOrder?.ocoOrderId) 
    {
      handleRemoveOco(ocoOrder?.marketId, ocoOrder._id, ocoOrder.ocoOrderId)
      setOcoOrder(undefined)
    }
  }

  return(<>

      <Typography color="textSecondary" variant="subtitle1" style={styles.subText}>Current OCO Orders</Typography>

      { ocoOrders && ocoOrders.length > 0 ? <TableContainer component={Card} style={{ padding: 6, margin: 6}}>

      { !!ocoOrder && <ConfirmModal
        id={ocoOrder?._id || ""}
        open={!!ocoOrder} 
        handleClose={() => setOcoOrder(undefined)}
        title={`Confirm Delete Order for ${ocoOrder.marketId} - Order List Id ${ocoOrder.ocoOrderId}`}
        description="This will remove the order and attemp to remove the OCO if it exists" 
        onConfirm={handleRemove} /> }

      <Table>
        <TableHead>
          <TableRow>
          <TableCell >Current Profit Percent</TableCell>
            <TableCell>Event Time</TableCell>
            <TableCell align="right">Market Id</TableCell>
            <TableCell align="right">Source</TableCell>
            <TableCell align="right">Current Price</TableCell>
            <TableCell align="right">Entry (Buy Price)</TableCell>
            <TableCell align="right">Stop Loss</TableCell>
            <TableCell align="right">Take Profit</TableCell>
            <TableCell align="right">Remove</TableCell>

          </TableRow>
        </TableHead>
        <TableBody >
          {ocoOrders && ocoOrders.map((row) => (
            <TableRow key={row._id} hover >
                <TableCell align="center">{
                <div style={{ width: 60, borderRadius: 12, padding: 4, backgroundColor: getColor(row)}}>
                  {getProfitPercent(row)}
                </div>
              }</TableCell>
              <TableCell component="th" scope="row"> {moment(row.timestamp).format("lll")} - {moment(row.timestamp).fromNow()}</TableCell>
              <TableCell align="right">{row.marketId}</TableCell>
              <TableCell align="right">{row.source || "-"}</TableCell>
              <TableCell align="right">{row.currentPrice}</TableCell>
              <TableCell align="right">{row.entry}</TableCell>
              <TableCell align="right">{row.stopLoss}</TableCell>
              <TableCell align="right">{row.takeProfit}</TableCell>
              <TableCell align="right">
                <IconButton onClick={() => setOcoOrder(row)}>
                  <DeleteForever />
                </IconButton>
              </TableCell>
            </TableRow>
          )) }
        </TableBody>
      </Table>
    </TableContainer> 
     : 
     
       <Card style={styles.card}>
        <StreamIcon htmlColor="grey" fontSize="large" />
        <Typography color="textSecondary" textAlign="center" variant="subtitle2">
          No Current OCO Orders in Play
        </Typography>
        </Card>
   }

  </>)
}

const styles = {
  container: {
    margin: 16,
  },
  card: {
    padding: 24,
    margin: 12,
    flex: 1,
    display: "flex" as "flex",
    justifyContent: 'center',
    flexDirection: "column" as "column",
    alignItems: 'center'
  },
  subText: {
    marginTop: 12,
  },
  noSigals: {
    paddingTop: 16,
    paddingBottom: 16
  },
  item: {
    marginTop: 36,
    marginBottom: 12,
  }
}