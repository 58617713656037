import { SubscriptionStatus } from "../interface/SubscriptionStatus";
import { HttpService } from "./HttpService";
import { Stripe } from 'stripe'

export class BillingApi extends HttpService
{
  createCustomer = async (email: string, name: string): Promise<Stripe.Response<Stripe.Customer> | undefined> =>
  {
    const response = await this.request<Stripe.Response<Stripe.Customer>>("POST", `billing/create/customer`, { email, name })
    if(response) return response.data
  }

  updateCustomer = async (email: string, name: string) =>
  {
    const response = await this.request<Stripe.Response<Stripe.Customer>>("PATCH", `billing/customer`, { email, name })
    if(response) return response.data
  }

  getCustomer = async (): Promise<Stripe.Response<Stripe.Customer> | undefined> =>
  {
    const response = await this.request<Stripe.Response<Stripe.Customer>>("GET", `billing/customer`, null)
    if(response) return response.data
  }

  getPaymentMethods = async (): Promise<Stripe.Response<Stripe.ApiList<Stripe.PaymentMethod>> | undefined> =>
  {
    const response = await this.request<Stripe.Response<Stripe.ApiList<Stripe.PaymentMethod>>>("GET", `billing/customer/paymentmethods`, null)
    if(response) return response.data
  }

  addCreditCard = async (cvc: string, expMonth: string, expYear: string, cardNumber: string): Promise<Stripe.Response<Stripe.Customer> | undefined> =>
  {
    const response = await this.request<Stripe.Response<Stripe.Customer>>("POST", `billing/add/creditcard`, { cvc, expMonth, expYear, cardNumber })
    if(response) return response.data
  }

  removeCreditCard = async (paymentMethod: string): Promise<Stripe.Response<Stripe.PaymentMethod> | undefined> =>
  {
    const response = await this.request<Stripe.Response<Stripe.PaymentMethod>>("POST", `billing/remove/creditcard?paymentMethod=${paymentMethod}`)
    if(response) return response.data
  }

  addSubscription = async (subscriptionId: string): Promise<Stripe.Response<Stripe.Subscription> | undefined> =>
  {
    const response = await this.request<Stripe.Response<Stripe.Subscription>>("POST", `billing/subscription?subscriptionId=${subscriptionId}`)
    if(response) return response.data
  }

  getSubscriptions = async (): Promise<Stripe.Response<Stripe.ApiList<Stripe.Subscription>> | undefined> =>
  {
    const response = await this.request<Stripe.Response<Stripe.ApiList<Stripe.Subscription>>>("GET", `billing/subscription`)
    if(response) return response.data
  }

  getSubscriptionStatus = async (): Promise<SubscriptionStatus | undefined> =>
  {
    const response = await this.request<SubscriptionStatus>("GET", `billing/subscriptionStatus`)
    if(response) return response.data
  }

  deleteSubscription = async (subscriptionId: string): Promise<Stripe.Response<Stripe.Subscription> | undefined> =>
  {
    const response = await this.request<Stripe.Response<Stripe.Subscription>>("DELETE", `billing/subscription?subscriptionId=${subscriptionId}`)
    if(response) return response.data
  }

  getProducts = async (): Promise<Stripe.Response<Stripe.ApiList<Stripe.Product>> | undefined> =>
  {
    const response = await this.request<Stripe.Response<Stripe.ApiList<Stripe.Product>>>("GET", `billing/products`)
    if(response) return response.data
  }

  

}