import { useEffect, useState } from "react"
import { AdminApi } from "../../../api/AdminApi"
import { User } from "../../../interface/User"
import { IRunEvent } from "../../../interface/IRunEvent"
import { LogsTable } from "../../../Pages/Logs/LogsTable"
import { OrderDetailsModal } from "../OrderDetails/OrderDetailsModal"
import { LoadingView } from "../../placeholder/LoadingView"
import { SearchBar } from "../../SearchBar"
import Grid from "@mui/material/Grid"


interface UserRunLogsProps
{
  user: User | undefined
}

export const UserRunLogs = ({ user }: UserRunLogsProps) =>
{ 
  const [ logs, setLogs ] = useState<IRunEvent[] | undefined>([]);
  const [ showOrderDetails, setShowOrderDetails ] = useState<IRunEvent | undefined>(undefined)
  const [ query, setQuery ] = useState("")
  const [ loading, setLoading ] = useState(false)

  useEffect(() => { 
    getRunLogs() 
  }, [])

  const getRunLogs = async () =>
  {
    try
    {
      setLoading(true)
      const logs = await new AdminApi().getRunEventsForUser(user?._id)
      setLogs(logs)
      setLoading(false)
    }
    catch(error)
    {
      setLoading(false)
    }
  }

  const useSearchFilter = () => {
    if(!query) return logs
    return logs?.filter(log => {
      if(log?.marketId.toLowerCase().includes(query.toLowerCase())) return true
      if(log?.sellResult?.toLowerCase().includes(query.toLowerCase())) return true
      if(log?.buyResult?.toLowerCase().includes(query.toLowerCase())) return true
    })
  }

  const filteredLogs = useSearchFilter()

  return(<Grid container spacing={3} direction="column">

    <Grid item xs={12} sx={{ mt: 1 }}>
      <SearchBar placeholder="Search by trading pair or reason" setQuery={setQuery} />
    </Grid>

    <Grid item>
      { !loading ? <LogsTable logs={filteredLogs} setShowOrderDetails={setShowOrderDetails} /> : <LoadingView /> }
    </Grid>

    { showOrderDetails && showOrderDetails?.marketId && showOrderDetails?.orderId && <OrderDetailsModal open={!!showOrderDetails} handleClose={() => setShowOrderDetails(undefined)} marketId={showOrderDetails?.marketId} orderId={showOrderDetails.orderId} /> }

  </Grid>)
}
