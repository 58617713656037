import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import { Link } from "react-router-dom"
import Lottie from "lottie-react"
import Button from "@mui/material/Button"


interface TrialOverModalProps
{
  open: boolean
  handleClose: () => void
}

export const TrialOverModal = ({ open, handleClose }: TrialOverModalProps) => {


  return(
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle textAlign='center'>Looks like you're free trial has expired</DialogTitle>

     <DialogContent>

      <Grid container direction="column" justifyContent='center' alignItems='center'>
        <Grid item>
        <Lottie animationData={require("../../../animations/person-lottie.json")} loop autoplay style={styles.lottie} />

        </Grid>

        <Grid item>
          <Typography>
          Your 30 day free trial has expired. Don't worry you're bot will still be trading however you'll now loose access to the WebApp and multi pair trading support.
          If you were using multi pair trading - you will need to adjust your main single trading pair to be the one that you like (In the mobile app)
          </Typography>
        </Grid>
      </Grid>

     </DialogContent>
      <DialogActions>
        <Button variant="contained">
        <Link to="/settings" style={{ }} >Setup Subscription</Link>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  lottie: {
    width: 200,
    height: 200,
  }
}