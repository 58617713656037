import Clear from "@mui/icons-material/Clear"
import Search from "@mui/icons-material/Search"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import { useState } from "react"

const DEBOUNCE = 500

interface SearchBarProps
{
  placeholder: string
  setQuery: (query: string) => void
}

export const SearchBar = ({ placeholder, setQuery }: SearchBarProps) =>
{
  const [ id, setId ] = useState<any>()
  const [ localQuery, setLocalQuery ] = useState("")

  const onChangeText = (text: string) =>
  {

    setLocalQuery(text)
    clearTimeout(id)

    const theId = setTimeout(() => {

      setQuery(text)

    }, DEBOUNCE)

    setId(theId)
  }

  const handleClearSearch = () =>
  {
    setLocalQuery("")
    setQuery("")
  }

  return(
    <TextField 
      InputProps={{
        startAdornment: <Search htmlColor="grey" sx={{ mr: 1 }} />,
        endAdornment: <IconButton onClick={handleClearSearch}>
          <Clear />
        </IconButton>
      }}
      placeholder={placeholder} 
      value={localQuery} 
      fullWidth 
      onChange={(e) => onChangeText(e.target.value)} />
  )
}