import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"


export const LogsHeader = () =>
{
  return(
    <TableHead>
          <TableRow>
            <TableCell>Event Time</TableCell>
            <TableCell align="right">Market Id</TableCell>
            <TableCell align="right">Reason</TableCell>
            <TableCell align="right">Price (USD)</TableCell>
            <TableCell align="right">Crypto Qty</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Profit</TableCell>
            <TableCell align="right">Fee</TableCell>
            <TableCell align="right">RSI</TableCell>
            <TableCell align="right">MFI</TableCell>
            <TableCell align="right">Total Assets</TableCell>
          </TableRow>
        </TableHead>
  )
}