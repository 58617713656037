
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon';
import DollarBill from '@mui/icons-material/MonetizationOn'
import ListItemText from '@mui/material/ListItemText';
import Card from "@mui/material/Card";
import Grid from '@mui/material/Grid';
import { LogApi } from '../api/LogApi';
import { useState } from 'react';
import { ProfitSummary } from '../interface/ProfitSummary';
import { useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';

export const ProfitSnapshot = () =>
{
  const [ loading, setLoading ] = useState(false)
  const [ summary, setSummary ] = useState<ProfitSummary[] | undefined>()

  const getProfitSummary = async () =>
  {
    setLoading(true)
    const logApi = new LogApi()
    const logData = await logApi.getProfitSummary();
    setSummary(logData)
    setLoading(false)
  }

  useEffect(() => {

    getProfitSummary()

  }, [])



  return(
    <List>

    <Grid container spacing={3} justifyContent="space-evenly">

    { loading && 
    <Grid container direction="row">
          {[0,1,2,4,5].map((i) => <Skeleton key={i} style={{ margin: 16, display: 'flex', flex: 1, height: 100 }} />)}
    </Grid>
    }

    {
      !loading && summary && summary.map((item, key) => {
        return(
          <Grid key={key} item xs={12} md={12} lg={2} xl={2}>
          <Card>
              <ListItem>
                  <ListItemIcon>
                    <DollarBill />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.time}
                    secondary={`$${item.profit} (USD)`}
                  />
                </ListItem>
              </Card>
          </Grid>
        )
      })
    }
   </Grid>
  </List>
  )
}