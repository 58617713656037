import Fab from "@mui/material/Fab"
import Badge from "@mui/material/Badge"

import SupportIcon from "@mui/icons-material/SupportAgent"
import { useEffect } from "react"
import { ChatWindow } from "./ChatWindow"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../interface/ApplicationState"
import { getUnseenMessageCountStart, toggleChatModal } from "../../redux/actions/chatActions"
import { MESSAGE_COUNT_POLL_INTERVAL } from "../../const"
const audio = require('../../assets/notification.mp3')

let pollId: any

export const ChatWidget = () =>
{

  const showModal = useSelector((state: ApplicationState) => state.chat.showModal)
  const unSeenMessageCount = useSelector((state: ApplicationState) => state.chat.unseenMessageCount)
  const dispatch = useDispatch()

  useEffect(() => {

    dispatch(getUnseenMessageCountStart())
    pollForNewMessages()

    return function()
    {
      clearInterval(pollId)
    }

  }, [])

  useEffect(() => {

    if(unSeenMessageCount > 0) new Audio(audio).play()

  }, [ unSeenMessageCount ])

  const pollForNewMessages = () =>
  {
    pollId = setInterval(() => {
      dispatch(getUnseenMessageCountStart())
    }, MESSAGE_COUNT_POLL_INTERVAL)
  }

  return(<div style={styles.container}>
    
    { !showModal && 
    <Badge anchorOrigin={{ vertical: "top", horizontal: "right" }} badgeContent={unSeenMessageCount} color="secondary">
    <Fab color="primary" aria-label="add" onClick={() => dispatch(toggleChatModal(true))}>
      <SupportIcon />
    </Fab>
    </Badge> }
    { showModal && <ChatWindow onClose={() => dispatch(toggleChatModal(false))} />}

  </div>)
}

const styles = {
  container: {
    zIndex: 10000,
    position: "fixed" as "fixed",
    margin: 0, 
    top: "auto", 
    left: 32, 
    bottom: 75, 
    right: "auto" 
  }
}