import { Dialog } from "@mui/material"
import { BacktestingChart } from "../../charts/BacktestingChart"


interface BacktestModalProps
{
  open: boolean
  handleClose: () => void
}

export const BacktestModal = ({ open, handleClose }: BacktestModalProps) =>
{
  return(<Dialog
    fullWidth
    maxWidth="xl"
    open={open}
    onClose={handleClose}>

   <BacktestingChart />

  </Dialog>)
}