import { Typography } from "@mui/material";
import Lottie from "lottie-react";



interface NoMessagesPlaceholderProps
{
  animation: string;
  loop: boolean;
  width: number;
  height: number;
  text: string;
}

export const NoMessagesPlaceholder = ({ animation, loop, width, height, text }: NoMessagesPlaceholderProps) =>
{
  return(
    <div style={styles.container}>
      <Lottie animationData={animation} loop={loop} style={{ width, height }} />
      <Typography sx={{ mt: -3 }} variant="subtitle1" fontWeight={700} color='textSecondary'>{text}</Typography>
    </div>
  )
}

const styles = {
  container: {
    flex: 1,
    height: "40vh",
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'center' as 'center',
    flexDirection: 'column' as 'column'
  }
}