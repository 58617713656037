
import List from '@mui/material/List'
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Card from "@mui/material/Card";

import SignupIcon from '@mui/icons-material/Subscriptions'

import { purple } from '@mui/material/colors';
import { TextWithIcon } from './TextWithIcon';
import { UserStatsResponse } from '../interface/UserStatsResponse';
import Divider from '@mui/material/Divider';
import { getIconForTitle } from '../utils/getIconForType';

interface UserStatsSummaryProps
{
  summary: UserStatsResponse | undefined
}


export const UserSignupSummary = ({ summary }: UserStatsSummaryProps) =>
{

  return(
    <Card style={styles.cardContainer}>

      <TextWithIcon text='User Signups' icon={<SignupIcon htmlColor={purple[400]} fontSize='large' sx={{ mr: 1 }} />} style={{}}/>
      <Divider />

      <List>
        {
          summary && summary.userSignupStats.map((summaryItem, key) => (
           <div style={styles.profitItem} key={key}>
            <Avatar style={styles.avatarProfit}>{getIconForTitle(summaryItem.title, "white")}</Avatar>
            <Typography fontSize={14} color="textSecondary" style={styles.profitTime}>{summaryItem.title}: </Typography>
            <Typography fontSize={14}>{summaryItem.value}</Typography>
           </div>
          ))
        }
      
      </List>

    </Card>
  )
}

const styles = {
  cardContainer: {
    padding: 12,
  },
  avatarProfit:{
    backgroundColor: purple[700],
    marginRight: 12,
    width: 28,
    height: 28,
  },
  profitTime: {
    marginRight: 6,
  },
  profitItem: {
    flexDirection: "row" as 'row',
    display: "flex" as 'flex',
    alignItems: 'center' as 'center',
    margin: 6
  }
}