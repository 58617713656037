import ListItem from "@mui/material/ListItem"
import List from "@mui/material/List"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemText from "@mui/material/ListItemText"
import ListIcon from '@mui/icons-material/MonetizationOn';
import { ProfitSummary } from "../../../interface/ProfitSummary"
import { User } from "../../../interface/User"
import { AdminApi } from "../../../api/AdminApi"
import { useEffect, useState } from "react"
import { LoadingView } from "../../placeholder/LoadingView"

interface ProfitSummaryProps
{
  user: User | undefined

}

export const ProfitSummarySection = ({ user }: ProfitSummaryProps) =>
{
  
  const [ profit, setProfit ] = useState<ProfitSummary[]>([])
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {

    getUserProfit()

  }, [])

  const getUserProfit = async () =>
  {
    try
    {
      if(user?._id)
      {
        setLoading(true)
        const adminApi = new AdminApi()
        const response = await adminApi.getUserProfit(user?._id)
        if(response) setProfit(response)
        setLoading(false)
      }
    }
    catch(error)
    {
      console.error(error)
      setLoading(false)
    }
  }

  return(
    <>
        <List>
            { !loading && profit ? profit.map((profitItem, key) => {
              return(
                <ListItem key={key}>
                <ListItemAvatar>
                  <ListIcon style={styles.avatar} />
                </ListItemAvatar>
                <ListItemText
                  primary={profitItem.time}
                  secondary={`$${profitItem.profit} USD`}
                />
              </ListItem>
              )
            }) : <LoadingView />}
        </List>
    </>
  )
}

const styles = {
  avatar: {
    backgroundColor: "#3F51B5",
    borderRadius: 50,
    width: 25,
    height: 25
  }
}