import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import IconButton from "@mui/material/IconButton"
import Paper from "@mui/material/Paper"
import CloseIcon from "@mui/icons-material/Close"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { BackTest } from "../../../interface/BackTest"
import { LogItem } from "../../../Pages/Logs/LogItem"


interface BackTestRunEventModalProps
{
  backtestItem: BackTest | undefined
  open: boolean
  handleClose: () => void
}

export const BackTestRunEventModal = ({ open, handleClose, backtestItem }: BackTestRunEventModalProps) =>
{
  return(<Dialog
    fullWidth
    maxWidth="xl"
    open={open}
    onClose={handleClose}>

    <DialogTitle>Run event details for backtest ({backtestItem?.indicator})</DialogTitle>

      <DialogContent>
    <TableContainer component={Paper} >
       <Table aria-label="simple table">
         <TableHead>
           <TableRow>
           <TableCell>Event Time</TableCell>
            <TableCell align="right">Market Id</TableCell>
            <TableCell align="right">Reason</TableCell>
            <TableCell align="right">Price (USD)</TableCell>
            <TableCell align="right">Crypto Qty</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Profit</TableCell>
            <TableCell align="right">Fee</TableCell>
            <TableCell align="right">RSI</TableCell>
            <TableCell align="right">MFI</TableCell>
            <TableCell align="right">Total Assets</TableCell>

           </TableRow>
         </TableHead>
         <TableBody>
         { backtestItem && backtestItem.runEvents && backtestItem.runEvents.map((runEvent, key) => <LogItem row={runEvent} key={key} setShowOrderDetails={() => {}}/>)}
         </TableBody>
       </Table>
     </TableContainer>


    </DialogContent>
    <DialogActions>
      <IconButton onClick={handleClose}><CloseIcon /></IconButton>
    </DialogActions>


  </Dialog>)
}

