import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import ArrowBack from "@mui/icons-material/ArrowBack"
import ArrowForward from "@mui/icons-material/ArrowForward"

import DialogTitle from "@mui/material/DialogTitle"
import { useState } from "react"
import { PAGES, pagesData } from "./pageData"
import { PageSection } from "./PageSection"


interface OnboardModalProps
{
  open: boolean
  handleClose: () => void
}

export const OnboardModal = ({ open, handleClose }: OnboardModalProps) => {

  const [ index, setIndex ] = useState<PAGES>(PAGES.WELCOME)


  const handleFoward = () => {
    if(index < 4) setIndex(index+1)
  }

  const handleBack = () => {
    if(index > PAGES.WELCOME) setIndex(index-1)
  }

  return(
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle></DialogTitle>

      <PageSection pageSection={pagesData[index as any]} />
     
      <DialogActions>
       { index !== PAGES.WELCOME && <Button startIcon={<ArrowBack />} onClick={handleBack}>Back</Button> }
        <div style={{ flex: 1 }} />
       { index !== PAGES.BACKTESTING &&  <Button startIcon={<ArrowForward />} onClick={handleFoward}>Next</Button> }
      </DialogActions>
    </Dialog>
  )
}