import { CircularProgress } from "@mui/material"


export const LoadingView = () =>
{
  return(
    <div style={styles.loading}>
      <CircularProgress />
    </div>
  )
}

const styles = {

  loading: {
    flex: 1,
    display: 'flex' as 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: window.screen.height / 2
  }
}