/* eslint-disable react/jsx-no-undef */
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { User } from "../../../interface/User"
import FormControlLabel from "@mui/material/FormControlLabel"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import EditIcon from "@mui/icons-material/Edit"

import Switch from "@mui/material/Switch"
import { AdminApi } from "../../../api/AdminApi"
import { useEffect, useState } from "react"
import ListItem from "@mui/material/ListItem"
import Avatar from "@mui/material/Avatar"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ExpandIcon from '@mui/icons-material/ExpandMore'
import ListItemText from "@mui/material/ListItemText"
import List from "@mui/material/List"
import { LoadingView } from "../../placeholder/LoadingView"
import { useGravatar } from "./hooks/useGravatar"
import moment from "moment"
import { UserEndpointSection } from "./UserEndpointSection"
import Divider from "@mui/material/Divider"
import Slider from "@mui/material/Slider"
import { MultiPairSection } from "../../../Pages/settings/MultiPairSection"
import { UserMultiPairSection } from "./UserMultiPairSection"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"

interface UserInfoProps
{
  user: User | undefined
}


export const UserInfo = ({ user }: UserInfoProps) =>
{
  const [ userDetails, setUserDetails ] = useState<User | undefined>()
  const [ loading, setLoading ] = useState(false)
  const { gravatarImage } = useGravatar(256, user?.username)

  useEffect(() => {

    getUserDetails(user?._id, true)

  }, [])

  const updateUserSettings = async (settings: Partial<User>) =>
    {
      if(user?._id)
      {
        const adminApi = new AdminApi()
        const userUpdated = await adminApi.updateUser(user?._id, settings)
        if(userUpdated) setUserDetails(userUpdated)
      }
      
    }

  const getUserDetails = async (userId: string | undefined, showProgress: boolean) =>
  {
    if(showProgress) setLoading(true)
    const user = await new AdminApi().userDetails(userId)
    setUserDetails(user)
    if(showProgress) setLoading(false)
  }


  return(
    <>
      <Grid item>

        <Grid item container alignItems='center' spacing={3}>

          <Grid item>
            <Avatar style={styles.avatar} src={gravatarImage} />
          </Grid>

          <Grid item>

            <Typography fontWeight="700" variant="subtitle1">Name: {user?.name || "Unknown"} </Typography>
            <Typography fontWeight="700" color="textSecondary" variant="body2">Email: {user?.email} </Typography>
            <Typography fontWeight="700" color="textSecondary" variant="body2">Username: {user?.username} </Typography>

            <Typography color="textSecondary" variant="caption">API Key: {user?.binanceApiKey || "None"} </Typography>
            <br />
            <Typography color="textSecondary" variant="caption">API Secret: {user?.binanceApiSecret || "None"} </Typography>
            <br />
            <UserEndpointSection onChangeEndpoint={(endpoint: string) => updateUserSettings({ binanceApiEndpoint: endpoint })} endpoint={userDetails?.binanceApiEndpoint!} />
            <Typography color="textSecondary" variant="caption">Stripe Customer ID: {user?.stripeCustomerId || "None"} </Typography>


          </Grid>
         
       
        </Grid>

        <Grid item>
           <FormControlLabel checked={userDetails?.tradingEnabled} control={<Switch value="on" /> } label="Trading State" onChange={(e: any) => updateUserSettings({ tradingEnabled: e.target.checked })} />
        </Grid>

        <Grid item>
           <FormControlLabel checked={userDetails?.isSignalsEnabled} control={<Switch value="on" /> } label="Signals State" onChange={(e: any) => updateUserSettings({ isSignalsEnabled: e.target.checked })} />
        </Grid>

        <Grid item>
           <FormControlLabel checked={userDetails?.isMultiTradingEnabled} control={<Switch value="on" /> } label="Multi Pair Trading" onChange={(e: any) => updateUserSettings({ isMultiTradingEnabled: e.target.checked })} />
        </Grid>


       { userDetails && !loading ?
       <>
        <Accordion >
          <AccordionSummary expandIcon={<ExpandIcon />}>
          <Typography fontWeight={700}>Main Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>

          <Divider />
          
          <List style={styles.userDetailsList}>

          { userDetails.isMultiTradingEnabled ? <UserMultiPairSection userDetails={userDetails} updateUserSettings={updateUserSettings} /> : 

          <ListItem >
          <ListItemAvatar>
            <Avatar style={styles.info}>
              TP
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Trading Pair" secondary={userDetails.tradingPair} />
          <TextField placeholder="Trading Pair" value={userDetails.tradingPair} onChange={(e: any) => updateUserSettings({ tradingPair: e.target.value })} />

        </ListItem>
          }

      <Divider />


          <ListItem >
            <ListItemAvatar>
              <Avatar style={styles.info}>
                FC
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Funding Currency" secondary={userDetails.fundingCurrency} />
            <TextField placeholder="Funding Currency" value={userDetails.fundingCurrency} onChange={(e: any) => updateUserSettings({ fundingCurrency: e.target.value })} />
          </ListItem> 

          <Divider />

          <ListItem >
            <ListItemAvatar>
              <Avatar style={styles.info}>
                CS
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Candles Interval" secondary={userDetails.chartInterval} />
            <TextField placeholder="Candles Interval" value={userDetails.chartInterval} onChange={(e: any) => updateUserSettings({ chartInterval: e.target.value })} />
          </ListItem> 

          <Divider />

          <ListItem >
            <ListItemAvatar>
              <Avatar style={styles.info}>
                BS
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Buy and Sell" secondary={`Max Buy: $${userDetails.maxBuy} USD | Max Sell: $${userDetails.maxSell} USD`} />
            <TextField sx={{ mr: 6 }} label="Max Buy" placeholder="Max Buy" value={userDetails.maxBuy} onChange={(e: any) => updateUserSettings({ maxBuy: Number(e.target.value)})} />
            <TextField label="Max Sell" placeholder="Max Sell" value={userDetails.maxSell} onChange={(e: any) => updateUserSettings({ maxSell: Number(e.target.value)})} />

          </ListItem> 


          <Typography fontWeight={700}>Max Buy</Typography>
          <Slider 
              valueLabelDisplay="auto"
              marks
              min={0}
              max={5000}
              step={100}
              value={userDetails.maxBuy}
              onChange={(e: any) => updateUserSettings({ maxBuy: e.target.value})}
            />

        <Typography fontWeight={700}>Max Sell</Typography>
          <Slider 
              valueLabelDisplay="auto"
              marks
              min={0}
              max={5000}
              step={100}
              value={userDetails.maxSell}
              onChange={(e: any) => updateUserSettings({ maxSell: e.target.value})}
            />



          <Divider />

            <ListItem >
            <ListItemAvatar>
              <Avatar style={userDetails.enableLong ? styles.enabled : styles.disabled}>
                LM
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Enable Long" secondary={userDetails.enableLong ? "Enabled" : "Disabled"} />
            <Switch checked={userDetails.enableLong} onChange={(e: any) => updateUserSettings({ enableLong: e.target.checked })}  />

          </ListItem> 

          <Divider />

          <ListItem >
            <ListItemAvatar>
              <Avatar style={userDetails.preferMarket ? styles.enabled : styles.disabled}>
                M
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Use Market Orders" secondary={userDetails.preferMarket ? "Status: Prefer Market Order" : "Status: Prefer Limit Order"} />
            <Switch checked={userDetails.preferMarket} onChange={(e: any) => updateUserSettings({ preferMarket: e.target.checked })}  />

          </ListItem> 

          <Divider />


          <ListItem >
            <ListItemAvatar >
              <Avatar style={userDetails.isTakeProfitEnabled ? styles.enabled : styles.disabled}>
                TP
              </Avatar>
            </ListItemAvatar>
            <ListItemText 
              primary="Take Profit" 
              secondary={`${userDetails.takeProfitPercentage}% - Last Take Profit Event (Manual or Auto Sell): ${userDetails.lastTakeProfitEvent ? moment(userDetails.lastTakeProfitEvent).fromNow() : "No take profit in effect"}` } />

          <Switch checked={userDetails.isTakeProfitEnabled} onChange={(e: any) => updateUserSettings({ isTakeProfitEnabled: e.target.checked })}  />
          { userDetails.isTakeProfitEnabled && <TextField disabled={!userDetails.isTakeProfitEnabled} placeholder="Take Profit %" value={userDetails?.takeProfitPercentage} onChange={(e: any) => updateUserSettings({ takeProfitPercentage: Number(e.target.value) })}  /> }
          </ListItem> 

          <Divider />

          <ListItem >
            <ListItemAvatar>
              <Avatar style={userDetails.isStopLossEnabled ? styles.enabled : styles.disabled}>
                SL
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Stop Loss" secondary={userDetails.isStopLossEnabled ? `Enabled at ${userDetails.stopLossPercentage}%` : `Disabled at ${userDetails.stopLossPercentage}%`} />

            <Switch checked={userDetails.isStopLossEnabled} onChange={(e: any) => updateUserSettings({ isStopLossEnabled: e.target.checked })}  />
            { userDetails.isStopLossEnabled && <TextField disabled={!userDetails.isStopLossEnabled} placeholder="Stop Loss %" value={userDetails?.stopLossPercentage} onChange={(e: any) => updateUserSettings({ stopLossPercentage: Number(e.target.value) })}  /> }

          </ListItem> 

          <Divider />

          <ListItem >
            <ListItemAvatar >
              <Avatar style={userDetails.isMinSellProfitEnabled ? styles.enabled : styles.disabled}>
                M
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Min Sell Threshold" secondary={userDetails.isMinSellProfitEnabled ? `Enabled at ${userDetails.takeProfitThreshold}%` : `Disabled at ${userDetails.takeProfitThreshold}%`} />
            <Switch checked={userDetails.isMinSellProfitEnabled} onChange={(e: any) => updateUserSettings({ isMinSellProfitEnabled: e.target.checked })}  />
            { userDetails.isMinSellProfitEnabled && <TextField disabled={!userDetails.isMinSellProfitEnabled} placeholder="Min Sell Threshold %" value={userDetails?.takeProfitThreshold} onChange={(e: any) => updateUserSettings({ takeProfitThreshold: Number(e.target.value) })}  /> }
          </ListItem> 

          <Divider />

          <ListItem >
            <ListItemAvatar>
              <Avatar style={userDetails.sellProfitsToFiat ? styles.enabled : styles.disabled}>
                SP
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Sell Profits to Fiat" secondary={`Current amount: $${ userDetails.currentSellProfitAmount} | Threshold: $${userDetails.sellProfitsThreshold}`} />
            <Switch checked={userDetails.sellProfitsToFiat} onChange={(e: any) => updateUserSettings({ sellProfitsToFiat: e.target.checked })}  />
          </ListItem> 

          <ListItem >
            <ListItemAvatar>
              <Avatar style={userDetails.isSignalsEnabled ? styles.enabled : styles.disabled}>
                SI
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Signals" secondary={`Max Invest: $${userDetails.signalsMaxBuy} | Sources: ${userDetails.signalsInvestSources?.toString()}`} />
          </ListItem>  
          </List> 
          </AccordionDetails>

        </Accordion>

        <Divider />

        <Accordion>
          <AccordionSummary expandIcon={<ExpandIcon />}><Typography fontWeight={700}>Technical Indicators</Typography></AccordionSummary>
          <AccordionDetails>
          <List style={styles.userDetailsList}>
          <ListItem 
            secondaryAction={<Switch checked={userDetails.isDynamicEnabled} onChange={(_, value) => updateUserSettings({ isDynamicEnabled: value })} />}>
            <ListItemAvatar>
              <Avatar style={userDetails.isDynamicEnabled ? styles.enabled : styles.disabled}>
                DN
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Dynamic" 
            secondary={
              `Current Indicator: ${userDetails.currentIndicator} 
              | Change threshold: ${userDetails.switchToTrendingIndicatorThreshold}%
              | SEMA: ${userDetails.tripleEmaShortPeriod} 
              | MEMA: ${userDetails.tripleEmaMediumPeriod} 
              | LEMA: ${userDetails.tripleEmaLongPeriod}
              | RSI Buy: ${userDetails.rsiThresholdBuy} | RSI Sell: ${userDetails.rsiThresholdSell}
              | MFI Buy: ${userDetails.mfiThresholdBuy} | MFI Sell: ${userDetails.mfiThresholdSell}` } />
          </ListItem> 

          <Divider />

          <ListItem 
          secondaryAction={<Switch checked={userDetails.rsiCrossOverEnabled} onChange={(_, value) => updateUserSettings({ rsiCrossOverEnabled: value })} />}>
            <ListItemAvatar>
              <Avatar style={userDetails.rsiCrossOverEnabled ? styles.enabled : styles.disabled}>
                RX
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="RSI Crossover" secondary={`RSI Short: ${userDetails.rsiShort} | RSI Medium: ${userDetails.rsiMedium}  | RSI Long: ${userDetails.rsiLong}`} />
          </ListItem> 

          <Divider />

          <ListItem secondaryAction={<Switch checked={userDetails.isTripleRSIEnabled} onChange={(_, value) => updateUserSettings({ isTripleRSIEnabled: value })} />}>
            <ListItemAvatar>
              <Avatar style={userDetails.isTripleRSIEnabled ? styles.enabled : styles.disabled}>
                TI
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Triple RSI" secondary={`RSI Period 1: ${userDetails.tripleRsi1} | RSI Period 2: ${userDetails.tripleRsi2}  | RSI Period 3: ${userDetails.tripleRsi3}`} />
          </ListItem> 

          <Divider />


          <ListItem secondaryAction={<Switch checked={userDetails.macdEnabled} onChange={(_, value) => updateUserSettings({ macdEnabled: value })} />}>
            <ListItemAvatar>
              <Avatar style={userDetails.macdEnabled ? styles.enabled : styles.disabled}>
                MD
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="MACD" secondary={userDetails.macdEnabled ? `Enabled Buy Threshold: ${userDetails.macdBuyThreshold} | Sell Threshold: ${userDetails.macdBuyThreshold}` : `Disabled BT: ${userDetails.macdBuyThreshold} ST: ${userDetails.macdBuyThreshold}`} />
          </ListItem> 

          <Divider />

          <ListItem secondaryAction={<Switch checked={userDetails.isAdxEnabled} onChange={(_, value) => updateUserSettings({ isAdxEnabled: value })} />}>
            <ListItemAvatar>
              <Avatar style={userDetails.isAdxEnabled ? styles.enabled : styles.disabled}>
                AD
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="ADX Trend Detection" secondary={userDetails.isAdxEnabled ? `Enabled at ${userDetails.adxTrendLimit}` : `Disabled at ${userDetails.adxTrendLimit}`} />
          </ListItem> 

          <Divider />


          <ListItem secondaryAction={<Switch checked={userDetails.emaEnabled} onChange={(_, value) => updateUserSettings({ emaEnabled: value })} />}>
            <ListItemAvatar>
              <Avatar style={userDetails.emaEnabled ? styles.enabled : styles.disabled}>
                DE
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="EMA Crossover" secondary={`SEMA: ${userDetails.emaShortPeriod} | LEMA: ${userDetails.emaLongPeriod}`} />
          </ListItem> 

          <Divider />


          <ListItem secondaryAction={<Switch checked={userDetails.tripleEmaEnabled} onChange={(_, value) => updateUserSettings({ tripleEmaEnabled: value })} />}>
            <ListItemAvatar>
              <Avatar style={userDetails.tripleEmaEnabled ? styles.enabled : styles.disabled}>
                TE
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Triple EMA Crossover" secondary={`SEMA: ${userDetails.tripleEmaShortPeriod} | MEMA: ${userDetails.tripleEmaMediumPeriod} | LEMA: ${userDetails.tripleEmaLongPeriod}`} />
          </ListItem> 

          <Divider />


          <ListItem secondaryAction={<Switch checked={userDetails.customEnabled} onChange={(_, value) => updateUserSettings({ customEnabled: value })} />}>
            <ListItemAvatar>
              <Avatar style={userDetails.customEnabled ? styles.enabled : styles.disabled}>
                CU
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Custom Combo" secondary={`RSI Sell: ${userDetails.rsiThresholdSell} | SEMA: ${userDetails.tripleEmaShortPeriod} | MEMA: ${userDetails.tripleEmaMediumPeriod} LEMA: ${userDetails.tripleEmaLongPeriod}`} />
          </ListItem> 

          <Divider />


          <ListItem secondaryAction={<Switch checked={userDetails.smaEnabled} onChange={(_, value) => updateUserSettings({ smaEnabled: value })} />}>
            <ListItemAvatar>
              <Avatar style={userDetails.smaEnabled ? styles.enabled : styles.disabled}>
                SM
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="SMA" secondary={`10 Period`} />
          </ListItem> 

          <Divider />


          <ListItem secondaryAction={<Switch checked={userDetails.rsiEnabled} onChange={(_, value) => updateUserSettings({ rsiEnabled: value })} />}>
            <ListItemAvatar>
              <Avatar style={userDetails.rsiEnabled ? styles.enabled : styles.disabled}>
                RS
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="RSI" secondary={`RSI Buy: ${userDetails.rsiThresholdBuy} | RSI Sell: ${userDetails.rsiThresholdSell}`} />
          
          </ListItem> 

          <Slider
              sx={{ mt: 3 }}
              value={[userDetails.rsiThresholdBuy || 30, userDetails.rsiThresholdSell || 70]}
              onChange={(e: any) => {
                const buyThreshold = e.target.value[0]
                const sellThreshold = e.target.value[1]
                updateUserSettings({ rsiThresholdBuy: buyThreshold, rsiThresholdSell: sellThreshold })
              }} valueLabelDisplay="on" />

          <Divider />


          <ListItem secondaryAction={<Switch checked={userDetails.mfiEnabled} onChange={(_, value) => updateUserSettings({ mfiEnabled: value })} />}>
            <ListItemAvatar>
              <Avatar style={userDetails.mfiEnabled ? styles.enabled : styles.disabled}>
                MF
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="MFI" secondary={`MFI Buy: ${userDetails.mfiThresholdBuy} | MFI Sell: ${userDetails.mfiThresholdSell}`} />
          </ListItem> 

          <Slider
              sx={{ mt: 3 }}
              value={[userDetails.mfiThresholdBuy || 30, userDetails.mfiThresholdSell || 70]}
              onChange={(e: any) => {
                const buyThreshold = e.target.value[0]
                const sellThreshold = e.target.value[1]
                updateUserSettings({ mfiThresholdBuy: buyThreshold, mfiThresholdSell: sellThreshold })
              }} valueLabelDisplay="on" />

          <Divider />


          <ListItem secondaryAction={<Switch checked={userDetails.candleStickPatternEnabled} onChange={(_, value) => updateUserSettings({ candleStickPatternEnabled: value })} />}>
            <ListItemAvatar>
              <Avatar style={userDetails.candleStickPatternEnabled ? styles.enabled : styles.disabled}>
                CS
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="CandleStick" secondary={`Bullish & Bearish`} />
          </ListItem> 
          </List> 
          </AccordionDetails>
        </Accordion>
        </>

      : <LoadingView /> }
      

      </Grid>
    </>
  )
}

const styles = {
  avatar: {
    width: 130,
    height: 130,
  },
  enabled: {
    backgroundColor: "#66BB6A"
  },
  userDetailsList: {
    overflow: "auto"
  },
  disabled: {
    backgroundColor: "#BDBDBD"
  },
  info: {
    backgroundColor: "#2196F3"
  }
}