import Today from '@mui/icons-material/Today'
import Week from '@mui/icons-material/DateRange'
import Month from '@mui/icons-material/CalendarMonth'
import AllTime from '@mui/icons-material/Schedule'
import ActiveSubscriptions from '@mui/icons-material/Loyalty'
import Check from '@mui/icons-material/Done'
import MoneyOff from '@mui/icons-material/MoneyOff'
import Group from '@mui/icons-material/Group'
import Key from '@mui/icons-material/Key'


export const getIconForTitle = (title: string, color?: string) =>
{
  if(title.toLowerCase().includes("day")) return <Today htmlColor={color || "grey"} fontSize='small'/>
  if(title.toLowerCase().includes("week")) return <Week htmlColor={color || "grey"} fontSize='small'/>
  if(title.toLowerCase().includes("month")) return <Month htmlColor={color || "grey"} fontSize='small'/>
  if(title.toLowerCase().includes("year")) return <Month htmlColor={color || "grey"} fontSize='small'/>
  if(title.toLowerCase().includes("all")) return <AllTime htmlColor={color || "grey"} fontSize='small'/>

  if(title.toLowerCase().includes("active")) return <ActiveSubscriptions htmlColor={color || "grey"} fontSize='small'/>
  if(title.toLowerCase().includes("enabled")) return <Check htmlColor={color || "grey"} fontSize='small'/>
  if(title.toLowerCase().includes("disabled")) return <MoneyOff htmlColor={color || "grey"} fontSize='small'/>
  if(title.toLowerCase().includes("total")) return <Group htmlColor={color || "grey"} fontSize='small'/>
  if(title.toLowerCase().includes("key")) return <Key htmlColor={color || "grey"} fontSize='small'/>

}