import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import MobileStepper from '@mui/material/MobileStepper';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../interface/ApplicationState';
import { updateLocalUser } from '../../redux/actions/userActions';
import { useTheme } from '@mui/material';
import { styles } from '../Settings';

export const ProfitConfiguration = () =>
{
  const dispatch = useDispatch();
  const user = useSelector((state: ApplicationState) => state.userStore.user)
  const theme = useTheme()

  const cardBackGroundColor = user.isDarkModeOn ? theme.palette.grey[900] : theme.palette.background.paper

  return(
    <Card style={{ backgroundColor: cardBackGroundColor, borderRadius: 12, margin: 12, padding: 16 }}>


          {/* Take Profit Section */}
          <Grid item>

          <FormControlLabel
              control={<Switch checked={user.isTakeProfitEnabled} onChange={(e) => dispatch(updateLocalUser({ isTakeProfitEnabled: e.target.checked }))} name="Take Profit" />}
              label="Enable Take Profit (Sell when profit margin reached) " />
          </Grid>

          <div style={styles.spacer} />


          { user.isTakeProfitEnabled && <Grid item>
              <TextField
              InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
              type="number"
              label="Percentage"
              placeholder="Take Profit Percentage %"
              helperText="Take Profit Threshold (Sell once market price reaches percentage regardless of indicators)"
              value={user.takeProfitPercentage}
              onChange={(e: any) => dispatch(updateLocalUser({ takeProfitPercentage: e.target.value }))}/>
          </Grid> }

          {/* Sell Profit Section */}
           <Grid item>

          <FormControlLabel
              control={<Switch checked={user.sellProfitsToFiat} onChange={(e) => dispatch(updateLocalUser({ sellProfitsToFiat: e.target.checked }))} name="Sell Profit To Fiat" />}
              label="Sell Profit to a USD base (If using USDT profit will be converted to TUSD and vice versa)" />
          </Grid>


          <div style={styles.spacer} />



          { user.sellProfitsToFiat && <Grid item>

            <MobileStepper 
                style={{ height: 50 }}
                position="static" 
                steps={user.sellProfitsThreshold || 0} 
                variant="progress" 
                activeStep={user.currentSellProfitAmount}
                backButton={<Button>{`Current Amount: $${user.currentSellProfitAmount ? user.currentSellProfitAmount.toFixed(2) : 0} USD`}</Button>} 
                nextButton={<Button>{`Threshold: $${user.sellProfitsThreshold} USD`}</Button>}/>

            <Slider 
              value={user.sellProfitsThreshold}
              valueLabelDisplay="auto"
              min={20} 
              max={200} 
              onChange={(e, newValue) => dispatch(updateLocalUser({ sellProfitsThreshold: Number(newValue) }))}
              />

          </Grid> }

          </Card>
  )
}