import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import { BINANCE_COM_ENDPOINT, BINANCE_TESTNET_ENDPOINT, BINANCE_US_ENDPOINT } from "../../../const";

interface UserEndpointSectionProps
{
  onChangeEndpoint: (endpoint: string) => void
  endpoint: string
}

export const UserEndpointSection = ({ onChangeEndpoint, endpoint }: UserEndpointSectionProps) =>
{

  return(
    <Grid container spacing={2}>
      <Grid item>
      <Button variant={endpoint === BINANCE_TESTNET_ENDPOINT ? "contained" : "outlined"} value={BINANCE_TESTNET_ENDPOINT} key={1} onClick={() => onChangeEndpoint(BINANCE_TESTNET_ENDPOINT)}>TestNet</Button>
      </Grid>

      <Grid item>
      <Button variant={endpoint === BINANCE_COM_ENDPOINT ? "contained" : "outlined"} value={BINANCE_COM_ENDPOINT} key={2} onClick={() => onChangeEndpoint(BINANCE_COM_ENDPOINT)}>Binance.com</Button>
      </Grid>

      <Grid item>
      <Button variant={endpoint === BINANCE_US_ENDPOINT ? "contained" : "outlined"} value={BINANCE_US_ENDPOINT} key={3} onClick={() => onChangeEndpoint(BINANCE_US_ENDPOINT)}>Binance.US</Button>
      </Grid>
    </Grid>
  )
}

const styles = {
  container: {
    display: 'flex' as 'flex',
    flex: 1,

  }
}