import { useState } from "react"
import { useEffect } from "react"
import { KEY_TOKEN } from "../const"
import App from "./App"
import moment from 'moment'
import jwtDecode from 'jwt-decode'
import { NonAuthRoutes } from "./NonAuthRoutes"

export const AuthProvider = () =>
{
  const [ isAuthed, setIsAuthed ] = useState(false)

  const isTokenValid = (token: string) =>
  {
    const decoded = jwtDecode(token) as any
    return moment().isAfter(moment(decoded.exp))
  }

  useEffect(() =>
  {
    attemptToDecodeToken()
  }, [])

  const attemptToDecodeToken = () =>
  {
    try
    {
      const token = localStorage.getItem(KEY_TOKEN)

      if(token)
      {
        const decoded = JSON.parse(token)
        if(decoded && decoded.token && isTokenValid(decoded.token))
        {
          setIsAuthed(true)
        }
      }
      else
      {
        setIsAuthed(false)
      }
    }
    catch(error)
    {
      // Any errors here then just logout...
      setIsAuthed(false)
    }
  }


  return(
    isAuthed ? <App /> : <NonAuthRoutes />
  )
}