
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stripe } from 'stripe'
import moment from 'moment'

interface SubscriptionsListProps
{
  subscriptions: Stripe.Response<Stripe.ApiList<Stripe.Subscription>> | undefined
  setShowConfirmDeleteSubscriptionModal: (id: string) => void
}

export const SubscriptionsList = ({ subscriptions, setShowConfirmDeleteSubscriptionModal }: SubscriptionsListProps) =>
{
  const getPrettyPrice = (subscription: Stripe.Subscription) =>
  {
    try
    {
      if(subscription && subscription.items && subscription.items.data && subscription.items.data.length > 0)
      {
        const unitAmount = subscription?.items?.data[0].price?.unit_amount;
        return unitAmount as number / 100
      }
    }
    catch(error)
    {
      return ""
    }
  }

  const getInterval = (subscription: Stripe.Subscription) =>
  {
    try
    {
      if(subscription.items.data[0].price.recurring?.interval === "year") return "Yearly"
      if(subscription.items.data[0].price.recurring?.interval === "month") return "Monthly"

    }
    catch(error)
    {
      return "Monthly"
    }

  }


  return(
    <>
    <Typography variant="subtitle2" color="textSecondary">Subscriptions</Typography>
    <List dense>
      { subscriptions && subscriptions.data.map((subscription, key) => (
         <ListItem
         key={key}
         secondaryAction={
           <IconButton edge="end" aria-label="delete" onClick={() => setShowConfirmDeleteSubscriptionModal(subscription.id)}>
             <DeleteIcon />
           </IconButton>
         }
       >
         <ListItemAvatar >
           <Avatar style={styles.avatar}>
            S
           </Avatar>
         </ListItemAvatar>
         <ListItemText
           primary={`${getInterval(subscription)} subscription - next payment ${moment(subscription.current_period_end * 1000).fromNow()} for $${getPrettyPrice(subscription)} AUD`}
           secondary={`Status: ${subscription.status}`}
         />
       </ListItem>
      ))}
     </List>
     </>
  )
}

const styles = 
{
  avatar: {
    backgroundColor: "#2196F3"
  }
}