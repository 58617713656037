
import Skeleton from '@mui/material/Skeleton';

export const SubscriptionSkeleton = () =>
{
  return(
    <div>
      <Skeleton height={40} />
      <Skeleton height={30} width={500} />
      <Skeleton height={30} width={200} />
      <Skeleton height={30} width={200} />

    </div>
  )
}