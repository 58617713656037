const authReducerInitialState = {
  isAuthenticated: false,
}

export const authReducer = (state = authReducerInitialState, action: any) =>
{
  switch(action.type)
  {
    default:
      return state
  }
}