
import IconButton from "@mui/material/IconButton";
import ArrowRight from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowLeft from "@mui/icons-material/ArrowCircleLeftOutlined";
import StripeIcon from "@mui/icons-material/MonetizationOn";
import ActiveIcon from "@mui/icons-material/Subscriptions";
import EnabledIcon from "@mui/icons-material/Check";
import HomeIcon from "@mui/icons-material/Home";
import DoneAll from "@mui/icons-material/DoneAll";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { UserSubscription } from "../../interface/User";
import { FilterType } from "./Admin";



interface PaginationFooterProps
{
  handleGoBack: () => void
  setFilterType: (value: FilterType) => void
  handleHome: () => void
  handleNext: () => void
  offset: number
  users: UserSubscription[] | undefined
  filterType: FilterType
}

export const PaginationFooter = ({ users, handleNext, handleGoBack, setFilterType, handleHome, offset, filterType }: PaginationFooterProps) =>
{
  return(
    <div style={styles.paginationContainer}>
    <IconButton disabled={offset === 0} onClick={handleGoBack}><ArrowLeft fontSize="large" /></IconButton>
    <Button disabled={offset === 0} onClick={handleHome} startIcon={<HomeIcon />}>Home</Button>
    <IconButton disabled={users?.length === 0} onClick={handleNext}><ArrowRight fontSize="large" /></IconButton>
    <div style={{ flex: 1}} />
    <ButtonGroup>
      <Button onClick={() => setFilterType("isActive")} variant={filterType === "isActive" ? "contained" : "outlined"} startIcon={<ActiveIcon />}>Active Subscription</Button>
      <Button onClick={() => setFilterType("enabled")} variant={filterType === "enabled" ? "contained" : "outlined"} startIcon={<EnabledIcon />}>Trading Enabled</Button>
      <Button onClick={() => setFilterType("stripe")} variant={filterType === "stripe" ? "contained" : "outlined"} startIcon={<StripeIcon />}>Has Stripe ID</Button>
      <Button onClick={() => setFilterType("all")} variant={filterType === "all" ? "contained" : "outlined"} startIcon={<DoneAll />}>All</Button>
    </ButtonGroup>
  </div>
  )
}

const styles = {
  paginationContainer: {
    marginTop: 6,
    flex: 1,
    display: 'flex' as 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: "center"
  },
}