import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

interface LogTimeRangeProps
{
  setFromTime: (hours: number) => void
  fromTime: number
}

export const LogTimeRange = ({ setFromTime, fromTime }: LogTimeRangeProps) =>
{
  return(
    <Grid container justifyContent="center" spacing={2} style={{ margin: 6, marginBottom: 12 }}>
      <Grid item>
      <Button style={styles.button} size="small" onClick={() => setFromTime(8)} variant={fromTime === 8 ?  "contained" : "outlined"}>8 hours</Button>
    </Grid>

    <Grid item>
      <Button style={styles.button} size="small" onClick={() => setFromTime(24)} variant={fromTime === 24 ?  "contained" : "outlined"}>24 hours</Button>
      </Grid>

     <Grid item>
      <Button style={styles.button} size="small" onClick={() => setFromTime(48)} variant={fromTime === 48 ?  "contained" : "outlined"}>48 hours</Button>
    </Grid>

      <Grid item>
        <Button style={styles.button} size="small" onClick={() => setFromTime(168)} variant={fromTime === 168 ?  "contained" : "outlined"}>7 days</Button>
      </Grid>

      <Grid item>
        <Button style={styles.button} size="small" onClick={() => setFromTime(168 * 4)}variant={fromTime === 168 * 4 ?  "contained" : "outlined"}>1 month</Button>
      </Grid>

      <Grid item>
        <Button style={styles.button} size="small" onClick={() => setFromTime(168 * 8)} variant={fromTime === 168 * 8 ?  "contained" : "outlined"}>2 months</Button>
      </Grid>

      <Grid item>
        <Button style={styles.button} size="small" onClick={() => setFromTime(168 * 26)} variant={fromTime === 168 * 26 ?  "contained" : "outlined"}>6 months</Button>
      </Grid>

      <Grid item>
        <Button style={styles.button} size="small" onClick={() => setFromTime(168 * 52)} variant={fromTime === 168 * 52 ?  "contained" : "outlined"}>12 months</Button>
      </Grid>

  </Grid>
  )
}

const styles = {
  button: {
    width: 100
  }
}