import { useEffect, useState } from "react"
import Skeleton from '@mui/material/Skeleton';
import CssBaseline from '@mui/material/CssBaseline';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { SignalsList } from './SignalsList';
import { UserApi } from "../../api/UserApi";
import { SignalItem } from "../../interface/SignalItem";
import { BtcMarkets } from "../../api/BtcMarkets";
import { OcoOrder } from "../../interface/OcoOrder";
import { OCOOrders } from "../../components/OCOOrders";


export const Signals = () =>
{
  const [ loading, setLoading ] = useState(false)
  const [ signals, setSignals ] = useState<SignalItem[] | undefined>([])
  const [ ocoOrders, setOCOorders ] = useState<OcoOrder[] | undefined>([])

  const [ ocoOrderRemoveStatus, setOcoOrderRemoveStatus ] = useState("")

  const getSignals = async () =>
  {
    try
    {
      const userApi = new UserApi()
      const signals = await userApi.getSignalsList()
      setSignals(signals?.sort((a, b) => b.timeout - a.timeout))
    }
    catch(error)
    {

    }

  }

  const getOCOOrders = async () =>
  {
    try
    {
      const orderApi = new BtcMarkets()
      const orders = await orderApi.getOCOorders()
      setOCOorders(orders)
    }
    catch(error)
    {

    }

  }

  const handleRemoveOco = async (marketId: string, orderId: string, ocoOrderId: number) =>
  {

    try
    {
      const response = await new BtcMarkets().removeOcoOrder(marketId, orderId, ocoOrderId)
      if(response)
      {
        if(response.deletedOco && response.deletedOrder) setOcoOrderRemoveStatus("Deleted order and OCO OK")
        if(!response.deletedOco && response.deletedOrder) setOcoOrderRemoveStatus("Deleted order but we couldn't find the OCO to delete")
        getOCOOrders()
      }
    }
    catch(error)
    {
      console.log(error)
      setOcoOrderRemoveStatus("Some other error occurred")
    }


  }

  const getAllData = async () =>
  {
    setLoading(true)
    await getOCOOrders()
    await getSignals()
    setLoading(false)
  }

  useEffect(() => {

    getAllData()
    
  }, [ ])


  return(
    <div style={styles.container}>

      <CssBaseline />

      { loading ? <div style={styles.loadingView}>

        <Skeleton height={30} width="20vw" />

        <Skeleton height={50} width="50vw" />
        <Skeleton height={50} width="100%" />

        <Skeleton height={600} width="100%" style={{ marginTop: -120 }} />
        <Skeleton height={50} width="100%" style={{ marginTop: -90 }} />

      </div> : 
      <div>
          <OCOOrders ocoOrders={ocoOrders} handleRemoveOco={handleRemoveOco} />
          <SignalsList signals={signals} />
      </div>

  
      }

      <Snackbar open={!!ocoOrderRemoveStatus} message={ocoOrderRemoveStatus} autoHideDuration={6000} onClose={() => setOcoOrderRemoveStatus("")}>
          <Alert severity="info" > {ocoOrderRemoveStatus} </Alert>
      </Snackbar>
   
    </div>
  )
}

const styles = {
  container: {
    overflow: "auto",
    height: "90vh"
  },
  loadingView:{
    height: "90vh"
  },
}