import CardHeader from "@mui/material/CardHeader"
import Card from "@mui/material/Card"
import Avatar from "@mui/material/Avatar"
import { red } from "@mui/material/colors"
import Grid from "@mui/material/Grid"

interface HeaderCardProps
{
  title: string
  subText: string
  value: number | string | undefined
  color: string
}


export const HeaderCard = ({ title, subText, value, color }: HeaderCardProps) =>
{
  return(
    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
    <Card >
        <CardHeader
          titleTypographyProps={{
            variant: "h6",
            fontWeight: 700,
          }}
          avatar={
            <Avatar style={{ backgroundColor: color, fontWeight: 700, fontSize: 32, width: 64, height: 64, color: "white" }} sx={{ bgcolor: red[500] }} >
              {value}
            </Avatar>
          }

          title={title}
          subheader={subText}
        ></CardHeader>
        </Card>
      </Grid>

  )
}