import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Candle } from '../../interface/Candle';
import moment from 'moment'
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useState } from 'react';

interface MarketChartProps
{
  candles: Candle[] | undefined;
  chartInterval: string;
  tradingPair: string;
}


export const MarketChart = ({ candles, chartInterval, tradingPair }: MarketChartProps) =>
{

  const [ showClose, setShowClose ] = useState(true)
  const [ showOpen, setShowOpen ] = useState(false)
  const [ showLow, setShowLow ] = useState(false)
  const [ showHigh, setShowHigh ] = useState(false)
  const [ showVolume, setShowVolume ] = useState(false)

  const dataMin = Math.min.apply(null, candles?.map(c => Number(c.close)) || [])
  const dataMax = Math.max.apply(null, candles?.map(c => Number(c.close)) || [])

  const dataMinVolume = Math.min.apply(null, candles?.map(c => Number(c.volume)) || [])
  const dataMaxVolume = Math.max.apply(null, candles?.map(c => Number(c.volume)) || [])

  return(
    <Card style={styles.container}>
    <Typography color="textSecondary">[{tradingPair}] Chart Interval: {chartInterval}</Typography>
    <ResponsiveContainer width="100%" height={300}>
    <LineChart
      data={candles || []}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <XAxis dataKey="openTime" tickFormatter={(ts: number) => moment.unix(ts / 1000).format("DD-MMM hh:mmA")} minTickGap={200} />
      <YAxis yAxisId="left" tickFormatter={(tick) => `$${tick}`} domain={[dataMin, dataMax]} />
      <YAxis yAxisId="right" tickFormatter={(tick) => `$${tick}`} orientation="right" domain={[dataMinVolume, dataMaxVolume]} />

      <Legend />
      { showOpen && <Line yAxisId="left" type="monotone" dataKey="open" stroke="#4CAF50"  dot={false} /> }
      { showClose && <Line yAxisId="left" type="monotone" dataKey="close" stroke="#2196F3" dot={false} strokeWidth={3}/> }
      { showLow && <Line yAxisId="left" type="monotone" dataKey="low" stroke="#FF9800"  dot={false} /> }
      { showHigh && <Line yAxisId="left" type="monotone" dataKey="high" stroke="#00897B" dot={false} /> }
      { showVolume && <Line yAxisId="right" type="monotone" dataKey="volume" stroke="#9B59B6" dot={false} /> }

      <Tooltip 
        labelStyle={{ color: "grey"}} 
        formatter={(tick) => `$${Number(tick).toFixed(8)} USD`} 
        labelFormatter={(ts) => `${moment.unix(ts / 1000).format("DD-MMM hh:mmA")} - ${moment.unix(ts / 1000).fromNow()}`}/>

    </LineChart>
    </ResponsiveContainer>
    
    <FormControlLabel control={<Switch size="small" checked={showClose} onChange={(e: any) => setShowClose(e.target.checked)} />} label="Close" />
    <FormControlLabel control={<Switch size="small" checked={showOpen} onChange={(e: any) => setShowOpen(e.target.checked)} />} label="Open" />
    <FormControlLabel control={<Switch size="small" checked={showHigh} onChange={(e: any) => setShowHigh(e.target.checked)} />} label="High" />
    <FormControlLabel control={<Switch size="small" checked={showLow} onChange={(e: any) => setShowLow(e.target.checked)} />} label="Low" />
    <FormControlLabel control={<Switch size="small" checked={showVolume} onChange={(e: any) => setShowVolume(e.target.checked)} />} label="Volume" />

    </Card>

  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    padding: 12,
    margin: 8,
    fontSize: 10,
    textAlign: 'center' as 'center'
  }
}