import { useEffect, useState } from "react"
import { LogApi } from "../../api/LogApi"
import { IRunEvent } from "../../interface/IRunEvent"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CssBaseline from '@mui/material/CssBaseline';

import moment from 'moment'
import { LogTimeRange } from "../../components/LogTimeRange";
import { OrderDetailsModal } from "../../components/modals/OrderDetails/OrderDetailsModal";
import { NoLogs } from "../../components/placeholder/NoLogs";
import { LogItem } from "./LogItem";
import { LogsHeader } from "./LogsHeader";
import { LogsTable } from "./LogsTable";

export const Logs = () =>
{

  const [ logs, setLogs ] = useState<IRunEvent[] | undefined>([])
  const [ loading, setLoading ] = useState(false)

  const [ order, setOrder ] = useState<"asc" | "desc">("desc")
  const [ limit, setLimit ] = useState(10000)
  const [ skip, setSkip ] = useState(0)
  const [ fromTime, setFromTime ] = useState(48)
  const [ showOrderDetails, setShowOrderDetails ] = useState<IRunEvent | undefined>(undefined)


  const getLogs = async () =>
  {
    setLoading(true)
    const logApi = new LogApi()
    const logData = await logApi.getRunEvents(order, limit, skip, moment().subtract(fromTime, "hours").toISOString());
    setLogs(logData)
    setLoading(false)
  }

  useEffect(() => {

    getLogs()

  }, [ fromTime ])


  return(
    <div style={{ height: "100vh"}}>

      <CssBaseline />

      {
        loading ? 
        <div>
              <Skeleton height={80} />
              {
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i, k) => <Skeleton key={k} height={60} />)
              }

        </div> : 
      <div>
          
      <LogTimeRange fromTime={fromTime} setFromTime={setFromTime} />

      { logs?.length === 0 && !loading && <NoLogs />}

      { logs && logs.length > 0 && <LogsTable logs={logs} setShowOrderDetails={setShowOrderDetails} /> }

      { showOrderDetails && showOrderDetails?.marketId && showOrderDetails?.orderId && <OrderDetailsModal open={!!showOrderDetails} handleClose={() => setShowOrderDetails(undefined)} marketId={showOrderDetails?.marketId} orderId={showOrderDetails.orderId} /> }
    </div>
      }
    </div>
  )
}

