
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from "@mui/material/Grid";
import { StripeProfitSummary } from "../../components/StripeProfitSummary";
import { UserStatsSummary } from "../../components/UserStatsSummary";
import { UserSignupSummary } from "../../components/UserSignupSummary";
import { LoadingStats } from "../../components/placeholder/LoadingStats";
import { TextWithIcon } from "../../components/TextWithIcon";
import MonetizationOn from "@mui/icons-material/MonetizationOn";
import { useState } from 'react';
import { ProfitSummary } from "../../interface/ProfitSummary";
import { UserStatsResponse } from "../../interface/UserStatsResponse";
import { AdminApi } from '../../api/AdminApi';
import Skeleton from '@mui/material/Skeleton';

export const SummaryAccordion = () =>
{
  const [ gettingStats, setGettingStats ] = useState(false)
  const [ summary, setProfitSummary ] = useState<ProfitSummary[] | undefined>()
  const [ userStats, setUserStats ] = useState<UserStatsResponse | undefined>()
  const [ expanded, setExpanded ] = useState(false) 

  const getDashboardData = async () =>
  {
    setGettingStats(true)
    await getProfitSummary()
    await getUserStats()
    setGettingStats(false)
  }

  const getProfitSummary = async () =>
  {
    const adminApi = new AdminApi()
    const profit = await adminApi.getStripeProfit();
    setProfitSummary(profit)
  }

  const getUserStats = async () =>
  {
    const adminApi = new AdminApi()
    const userStats = await adminApi.userStats();
    setUserStats(userStats)
  }

  const handleOpenStats = () =>
  {
    setExpanded(!expanded)
    if(!expanded) getDashboardData()
  }

  return(
    <Accordion expanded={expanded} onChange={handleOpenStats}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header" >
          { gettingStats ? <Skeleton animation="wave" height={60} width={100} style={{ flex: 1, display: "flex "}} /> : 
          <TextWithIcon style={{}} icon={<MonetizationOn sx={{ mr: 1 }} htmlColor="grey" />} text="User payment summary" />  }

        </AccordionSummary>
        <AccordionDetails>
          
      <Grid container spacing={3}>

        <Grid item xs={4} sm={12} lg={4} xl={4} md={12}>
          { gettingStats ? <LoadingStats /> : <StripeProfitSummary summary={summary}/> }
        </Grid>

        <Grid item xs={4} sm={12} lg={4} xl={4} md={12}>
        { gettingStats ? <LoadingStats /> : <UserStatsSummary summary={userStats} /> }
        </Grid>

        <Grid item xs={4} sm={12} lg={4} xl={4} md={12}>
          { gettingStats ? <LoadingStats /> :  <UserSignupSummary summary={userStats} /> }
        </Grid>

        </Grid>
        </AccordionDetails>
      </Accordion>
  )
}