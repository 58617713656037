import { useEffect, useRef, useState } from "react"
import CssBaseline from '@mui/material/CssBaseline';
import { User, UserSubscription } from "../../interface/User";
import { AdminApi } from "../../api/AdminApi";
import { useNavigate } from "react-router-dom";
import { SummaryAccordion } from "./SummaryAccordion";
import { SearchView } from "./SearchView";
import { LoadingUsers } from "./LoadingUsers";
import { UsersList } from "./UsersList";
import { UserDetailsModal } from "../../components/modals/UserDetails/UserDetailsModal";
import { PaginationFooter } from "./PaginationFooter";

const DEBOUNCE = 300
const PAGE_LIMIT = 10

export type FilterType = "isActive" | "enabled" | "stripe" | "all"

export const Admin = () =>
{

  const [ loading, setLoading ] = useState(false)
  const [ refreshing, setRefreshing ] = useState(false)
  const timerRef = useRef<NodeJS.Timeout>()
  const navigate = useNavigate();
  const [ users, setUsers ] = useState<UserSubscription[] | undefined>([])
  const [ limit, setLimit ] = useState(PAGE_LIMIT)
  const [ offset, setOffset ] = useState(0)
  const [ filterType, setFilterType ] = useState<FilterType>("all")
  const [ openModal, setOpenModal ] = useState<User | undefined>()
  const [ searchQuery, setSearchQuery ] = useState("")


  useEffect(() => {

    getUsers()

  }, [])

  useEffect(() => {

    getUsers()

  }, [ limit, offset, filterType ])

  useEffect(() => {

    searchWithDebounce()

  },[ searchQuery ] )

  const getUsers = async () =>
  {
    try
    {
      setLoading(true)
      const adminApi = new AdminApi()
      const users = await adminApi.listUsers(offset, limit, searchQuery, filterType)
      setUsers(users)
      setLoading(false)
    }
    catch(error)
    {
      setLoading(false)
    }
  }

  const handleCloseUserDetailsModal = async (reload: boolean) =>
  {
    handleOpenUserModal(undefined)

    if(reload)
    {
      setRefreshing(true)
      const users = await new AdminApi().listUsers(offset, limit, searchQuery, filterType)
      setUsers(users)
      setRefreshing(false)
    }
  }

  const handleGoBack = () =>
  {
    if(offset > 0) setOffset(prevOffset => prevOffset - PAGE_LIMIT)
  }

  const handleNext = () =>
  {
    setOffset(prevOffset => prevOffset + PAGE_LIMIT)
  }

  const handleHome = () =>
  {
    setOffset(0)
    setLimit(PAGE_LIMIT)
  }

  const searchWithDebounce = () =>
  {
    clearTimeout(timerRef.current)

    timerRef.current = setTimeout(() => {

      getUsers()

    }, DEBOUNCE)
  }

  const handleOpenUserModal = (user?: User) =>
  {
    if(user) navigate(`?userId=${user._id}`)
    if(!user) navigate(-1)
    setOpenModal(user)
  }



  return(
    <div >
      <CssBaseline />

      <SummaryAccordion />

      <SearchView searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

      { loading ? <LoadingUsers /> : <UsersList users={users} handleOpenUserModal={handleOpenUserModal} refreshing={refreshing} />}

      { openModal && <UserDetailsModal user={openModal} open={!!openModal} handleClose={handleCloseUserDetailsModal} /> }

      <PaginationFooter 
        filterType={filterType}
        setFilterType={setFilterType}
        handleGoBack={handleGoBack}
        handleHome={handleHome}
        handleNext={handleNext}
        offset={offset}
        users={users}
        />

    </div>
  )
}

