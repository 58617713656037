import  TrendingUp from "@mui/icons-material/TrendingUp"
import TrendingDown from "@mui/icons-material/TrendingDown"
import TrendingFlat from "@mui/icons-material/TrendingFlat"

import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import { useState } from "react"
import { useEffect } from "react"
import { LogApi } from "../api/LogApi"
import { REFRESH_INTERVAL } from "../const"
import Skeleton from "@mui/material/Skeleton"

interface BullishBearishProps
{
  marketId: string;
  interval: string;
}

export const BullishBearish = ({ marketId, interval }: BullishBearishProps) =>
{

  const [ isBullish, setIsBullish ] = useState<boolean | undefined>(undefined)
  const [ isBearish, setIsBearish ] = useState<boolean | undefined>(undefined)
  const [ loading, setLoading ] = useState(false)


  useEffect(() => {

    checkBullish()
    startRefresh()

  }, [])

  const checkBullish = async () =>
  {
    try
    {
      setLoading(true)
      const logApi = new LogApi()
      const response = await logApi.checkIsBullishOrBearish(marketId, interval)
      setIsBullish(response?.isBullish)
      setIsBearish(response?.isBearish)
      setLoading(false)
    }
    catch(error)
    {
      setLoading(false)

    }

  }

  const startRefresh = async () =>
  {
    
    setInterval(() => {

      checkBullish()

    }, REFRESH_INTERVAL)


  }

  return(
    loading ? <Skeleton style={{margin: 6, borderRadius: 60 }} width={30} height={30} variant="circular" animation="wave" /> : <div>


    { ((isBullish && isBearish) || (!isBullish && !isBearish)) && <Tooltip title={`Candlestick pattern indicates that your trading pair trending flat`}>
      <IconButton onClick={checkBullish}>
        <TrendingFlat />
      </IconButton> 
      </Tooltip>}

      { isBullish && !isBearish && <Tooltip title={`Candlestick pattern indicates that your trading pair is bullish`}>
      <IconButton onClick={checkBullish}>
        <TrendingUp />
      </IconButton> 
      </Tooltip>}

      { isBearish && !isBullish && <Tooltip title={`Candlestick pattern indicates that your trading pair is bearish`}>
      <IconButton onClick={checkBullish}>
      <TrendingDown />
      </IconButton> 
      </Tooltip>}

    </div>
  )
}