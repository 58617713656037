
import { UserRowItem } from "./UserRowItem";
import Card from "@mui/material/Card";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import LinearProgress from '@mui/material/LinearProgress';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { UserSubscription } from "../../interface/User";

interface UsersListProps
{
  refreshing: boolean
  users: UserSubscription[] | undefined
  handleOpenUserModal: (user: UserSubscription) => void
}

export const UsersList = ({ refreshing, users, handleOpenUserModal }: UsersListProps ) =>
{
  return(
    <div>
    { refreshing && <LinearProgress /> }
    <Card>
    <TableContainer style={styles.body}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell align="right">Created</TableCell>
            <TableCell align="right">Device</TableCell>
            <TableCell align="right">Last Login</TableCell>
            <TableCell align="right">Country</TableCell>
            <TableCell align="right">Trading Pair</TableCell>
            <TableCell align="right">Stripe Customer Id</TableCell>
            <TableCell align="right">Subscription Status</TableCell>
            <TableCell align="right">Last Payment</TableCell>
            <TableCell align="right">Next Payment</TableCell>
            <TableCell align="right">Trading Enabled</TableCell>
          </TableRow>
        </TableHead>
        <TableBody >
          {users && users.map((user, key) => <UserRowItem key={key} user={user} setOpenModal={handleOpenUserModal} />)}
        </TableBody>
      </Table>

</TableContainer>
</Card>

</div>
  )
}

const styles = {
  body: {
    overflow: "auto",
    height: "70vh"
  },
}