import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import ChatIcon from '@mui/icons-material/Forum';

import { styles } from './ChatWindow';
import { IChat } from '../../interface/Chat';
import { useDispatch, useSelector } from 'react-redux';
import { markReadBegin, removeConversationStart, removeMyChatStart, setCurrentChat } from '../../redux/actions/chatActions';
import { purple } from '@mui/material/colors';
import { NoConversations } from '../placeholder/NoConversations';
import { ApplicationState } from '../../interface/ApplicationState';
import moment from 'moment';
import { SearchBar } from '../SearchBar';
import { useState } from 'react';
import { useSearchFilter } from './hooks/useSearchFilter';

interface PastHelpRequestsProps
{
  conversations: IChat[]
  isAdmin: boolean | undefined
}
export const PastHelpRequests = ({ conversations, isAdmin }: PastHelpRequestsProps) =>
{
  const dispatch = useDispatch()
  const [ query, setQuery ] = useState("")
  const isRemovingMyChat = useSelector((state: ApplicationState) => state.chat.isRemovingMyChat)
  const filteredConversations = useSearchFilter(query, conversations)

  const handleGoIntoChat = (conversationItem: IChat) =>
  {
    dispatch(setCurrentChat(conversationItem._id))
    dispatch(markReadBegin(conversationItem._id))
  }

  const handleRemoveChat = (conversationItem: IChat) =>
  {
    if(!isAdmin) dispatch(removeMyChatStart(conversationItem._id))
    if(isAdmin) dispatch(removeConversationStart(conversationItem._id))
  }

  const getDescription = (conversationItem: IChat) =>
  {
    if(isAdmin && conversationItem.unSeenUserMessageCount > 0) return `${conversationItem.unSeenUserMessageCount} messages not seen by user - `
    if(!isAdmin && conversationItem.unSeenAdminMessageCount > 0) return `${conversationItem.unSeenAdminMessageCount} messages not seen by support - `
    return ""
  }

  return(
    <>
    <div style={localStyles.searchContainer}><SearchBar placeholder='Search conversations' setQuery={setQuery} /></div>
    <List style={styles.list}>
    {
     filteredConversations && filteredConversations.length > 0 ? filteredConversations.map((conversationItem, key) => (
        <ListItem 
          key={key}
          secondaryAction={
            <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveChat(conversationItem)} disabled={isRemovingMyChat}>
              <DeleteIcon />
            </IconButton>
          }>
          <ListItemButton style={localStyles.listButton} onClick={() => handleGoIntoChat(conversationItem)}>

          <ListItemAvatar>
          <Badge anchorOrigin={{ vertical: 'top', horizontal: 'right' }} overlap="circular" color="primary" badgeContent={isAdmin ? conversationItem.unSeenAdminMessageCount : conversationItem.unSeenUserMessageCount}>
            <Avatar style={localStyles.avatar}>
              <ChatIcon />
            </Avatar>
          </Badge>

          </ListItemAvatar>
            <ListItemText 
              secondaryTypographyProps={{ fontSize: 10 }} 
              primaryTypographyProps={{ fontSize: 14 }} 
              primary={`${conversationItem.email}: ${conversationItem.subject}`} 
              secondary={`${getDescription(conversationItem)}${moment(conversationItem.timestamp).fromNow()}`} />
          </ListItemButton>

        </ListItem>
      )) : <NoConversations isAdmin={isAdmin} />
    } 


  </List>
  </>

  )
}

const localStyles = {
  listButton: {
    borderRadius: 12
  },
  searchContainer: {
    marginRight: 16,
    marginLeft: 16,
  },
  avatar: {
    backgroundColor: purple[400]
  }
}