import { Line, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart, XAxis} from 'recharts';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

import { LogApi } from '../../api/LogApi';
import { useState } from 'react';
import { useEffect } from 'react';
import { EMASMA } from '../../interface/EMASMA';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { EMA_TYPES } from '../../interface/EMA_TYPES';

interface EMASMAChartProps
{
  chartInterval: string;
  tradingPair: string;
  emaShortPeriod: number;
  emaLongPeriod: number;
  emaType: EMA_TYPES | undefined
}


export const EMASMAChart = ({ chartInterval, tradingPair, emaShortPeriod, emaLongPeriod, emaType }: EMASMAChartProps) =>
{

  const [ emaSma, setEmaSma ] = useState<EMASMA[] | undefined>(undefined)
  const [ loading, setLoading ] = useState(false)

  useEffect(() =>
  {
    getEmaSma(true)
  }, [ ])

  useEffect(() =>
  {
    getEmaSma(false)
  }, [ chartInterval ])

  const getEmaSma = async (load: boolean) =>
  {
    if(tradingPair && chartInterval && emaType)
    {
      try
      {
        const logApi = new LogApi()
        load && setLoading(true)
        const emaSma = await logApi.getEMA(tradingPair, chartInterval, emaShortPeriod, emaLongPeriod, emaType)
        setEmaSma(emaSma)
        load && setLoading(false)
      }
      catch(error)
      {
        load && setLoading(false)
      }
 
    }

  }

  const getEmaLabel = () =>
  {
    if(emaType === EMA_TYPES.SINGLE_EMA) return "Single"
    if(emaType === EMA_TYPES.DOUBLE_EMA) return "Double"
    if(emaType === EMA_TYPES.TRIPLE_EMA) return "Triple"
  }

  return(
  !loading ? <Card style={styles.container}>
    <Typography color="textSecondary">[{tradingPair}] {getEmaLabel()} EMA Crossover Chart Interval: {chartInterval}</Typography>
      <ResponsiveContainer width="100%" height={324}>
      <ComposedChart
        width={100}
        height={500}
        data={emaSma || []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <YAxis tickFormatter={(tick) => `$${tick}`}  domain={['dataMin', 'dataMax']} /> 

        <XAxis dataKey="timestamp" tickFormatter={(ts: number) => moment(ts).format("DD-MMM hh:mmA")} minTickGap={100} />

        <Legend formatter={(e) => {
          if(e === "shortEma") return `Short EMA Period (${emaShortPeriod})`
          if(e === "longEma") return `Long EMA Period (${emaLongPeriod})`
          if(e === "close") return "Close Price"

        }} />
        <Line type="monotone" dataKey="shortEma" stroke="#E91E63" dot={false} />
        <Line type="monotone" dataKey="longEma" stroke="#4CAF50" dot={false} strokeWidth={2} />
        <Line type="monotone" dataKey="close" stroke="#FFB300" dot={false} strokeWidth={1} />

        <Tooltip formatter={tick => `$${Number(tick).toFixed(2)} USD`} labelStyle={{ color: "grey"}} labelFormatter={(ts) => `${moment(ts).format("DD-MMM hh:mmA")} - ${moment(ts).fromNow()}`} />

      </ComposedChart>
      </ResponsiveContainer>
    </Card> : <div style={styles.loader}><CircularProgress /></div>
  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 10,
    padding: 12,
    margin: 8,
    textAlign: 'center' as 'center'
  },
  loader: {
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}