import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { validateEmail } from '../../utils/validateEmail';


interface ForgotPasswordModalProps
{
  open: boolean
  loading: boolean
  resetEmail: string
  handleClose: () => void
  onEmailChange: (email: string) => void
  handleReset: () => void
}

export const ForgotPasswordModal = ({ open, handleClose, onEmailChange, loading, handleReset, resetEmail }: ForgotPasswordModalProps) =>
{

    return(
      <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter your email to get the reset link
        </DialogContentText>
        <TextField
          onChange={(e: any) => onEmailChange(e.target.value)}
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          value={resetEmail}
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={loading || !validateEmail(resetEmail)} onClick={handleReset}>{loading ? "Requesting..." : "Request Reset"}</Button>
      </DialogActions>

    </Dialog>
    )
}