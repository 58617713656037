

import { combineReducers } from "redux"

import { userReducer } from './reducers/user/userReducer'
import { authReducer } from './reducers/auth/authReducer'

import { billingReducer } from './reducers/billing/billingReducer'
import { chatReducer } from "./reducers/chat/chatReducer"

export const rootReducer = combineReducers({
  userStore: userReducer,
  auth: authReducer,
  billing: billingReducer,
  chat: chatReducer,
})
