import { useEffect, useState } from 'react';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';

import Alert from '@mui/material/Alert';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import LinearProgress from '@mui/material/LinearProgress';

import Switch from '@mui/material/Switch';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';

// Icons
import SellIcon from '@mui/icons-material/Sell';

// API
import { AdminApi } from '../../../api/AdminApi';

// Interface
import { MarketSellResponse } from '../../../interface/MarketSellResponse';
import { CheckProfitResponse } from '../../../interface/CheckProfitResponse';

// Custom
import { Transition } from './Transition';
import { CheckProfitSection } from './CheckProfitSection';
import { SellResultSection } from './SellResultSection';
import { Summary } from '../../../interface/Summary';
import { LogApi } from '../../../api/LogApi';

interface UserSellModalProps
{
  open: boolean
  userId: string
  handleClose: () => void
  tradingPair: string
  freeAmount: number
}

export const UserSellModal = ({ open, handleClose, userId, tradingPair, freeAmount }: UserSellModalProps) =>
{

  const [ amountToSell, setAmountToSell ] = useState(0)
  const [ summary, setSummary ] = useState<Summary | undefined>(undefined)
  const [ sellLoading, setSellLoading ] = useState(false)
  const [ checkProfitLoading, setCheckProfitLoading ] = useState(false)
  const [ createRunEvent, setCreateRunEvent ] = useState(true)
  const [ sellReason, setSellReason ] = useState("")

  const [ checkProfitResponse, setCheckProfit ] = useState<CheckProfitResponse | undefined>(undefined)
  const [ sellResponse, setSellResponse ] = useState<MarketSellResponse | undefined>(undefined)

  const [ sellError, setSellError ] = useState("")


  useEffect(() => {
    getSummary()
  }, [ ])

  useEffect(() => {
    setAmountToSell(getMaxSell())
  }, [ summary ])

  useEffect(() => {
    checkProfit(true)
  }, [ amountToSell ])

  const getSummary = async () =>
  {
    try
    {
      setCheckProfitLoading(true)
      const logApi = new LogApi()
      const summary = await logApi.getSummary(tradingPair)
      setSummary(summary)
      setCheckProfitLoading(false)
    }
    catch(error)
    {
      console.error("Error getting summary")
      setCheckProfitLoading(false)
    }

  }

  const getMaxSell = () =>
  {
    try
    {
      return freeAmount * Number(summary?.prices[tradingPair])
    }
    catch(error)
    {
      return 0
    }
  }

  const checkProfit = async (refresh: boolean) =>
  {
    try
    {
      if(!amountToSell) return
      refresh && setCheckProfitLoading(true)
      const adminApi = new AdminApi()
      const response = await adminApi.checkProfitForUser(userId, tradingPair, amountToSell)
      if(response) setCheckProfit(response)
      refresh && setCheckProfitLoading(false)
    }
    catch(error)
    {
      refresh && setCheckProfitLoading(false)
    }

  }

  const doSell = async () =>
  {
    try
    {
      setSellLoading(true)
      const adminApi = new AdminApi()
      const response = await adminApi.sellAssetForUser(userId, tradingPair, amountToSell, sellReason, createRunEvent)
      if(response) setSellResponse(response)
      setSellLoading(false)
      setSellError("")
    }
    catch(error)
    {
      const errorCast = error as any
      setSellLoading(false)
      setSellError(getParsedError(errorCast))
    }

  }

  const getParsedError = (error: any) =>
  {
    try
    {
      if(error.message.response.data.error.code === -2010) return "Account has insufficient balance for requested action."
      if(error?.message?.response?.data?.error && !error.message.response.data.error.code) return error.message.response.data.error
      return "Looks like there was an issue executing the sale. Maybe try reduce your sale amount to ensure a successful sale."
    }
    catch(error)
    {
      return "Looks like there was an issue executing the sale. Maybe try reduce your sale amount to ensure a successful sale."
    }
  }

  return(
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
    <DialogTitle>Confirm sale ({tradingPair})</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        Confirm sale of asset for user
      </DialogContentText>

     { createRunEvent && <TextField value={sellReason} placeholder='Enter reason for sale' onChange={(e: any) => setSellReason(e.target.value)} fullWidth sx={{ mt: 1 }} /> }

      <FormControlLabel sx={{ mt: 1, ml: 1 }}  label="Create run event?" control={<Switch defaultChecked={createRunEvent} onChange={(_, checked) => setCreateRunEvent(checked)} /> } />

      { checkProfitLoading && <LinearProgress variant="indeterminate" title="checking sell profit" style={styles.loading} /> }
      { checkProfitResponse && <CheckProfitSection checkProfitResponse={checkProfitResponse} />}

      <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
               $
              </InputAdornment> )
          }}
          value={amountToSell}
          onChange={(e: any) => setAmountToSell(e.target.value)}
          inputMode="decimal"
          autoFocus
          margin="dense"
          id="amountToSell"
          label="Amount to sell (USD)"
          type="number"
          fullWidth
          variant="outlined" />

    { sellResponse && <SellResultSection sellResponse={sellResponse} />}

    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>{sellResponse ? "Close" : "Cancel"}</Button>
      <Button disabled={!!sellResponse} startIcon={sellLoading ? <CircularProgress size={24} /> : <SellIcon />} onClick={doSell}>{sellLoading ? "Selling" : "Sell"}</Button>
    </DialogActions>

    <Snackbar open={!!sellError} message={sellError} autoHideDuration={6000} onClose={() => setSellError("")}>
      <Alert severity="error" > {sellError} </Alert>
    </Snackbar>

  </Dialog>
  )
}

const styles = {
  loading: {
    marginTop: 16,
    marginBottom: 16,
    height: 20,
    borderRadius: 12,
  },
  checkbox: {
    marginTop: 12
  }
}