import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Save from "@mui/icons-material/Save"
import ResetIcon from "@mui/icons-material/Refresh"

import { TradingPair } from "../../../interface/TradingPair"
import { useState } from "react"
import { User } from "../../../interface/User"
import CircularProgress from "@mui/material/CircularProgress"
import { CandleStickIntervalChooser } from "../../CandleStickIntervalChooser"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import IconButton from "@mui/material/IconButton"

interface MultiPairEditModalProps
{
  open: boolean
  tradingPair: TradingPair
  userDetails: User
  onClose: () => void
  updateUserSettings: (user: Partial<User>) => Promise<void>
}
export const MultiPairEditModal = ({ open, userDetails, onClose, tradingPair, updateUserSettings }: MultiPairEditModalProps) =>
{
  const [ currentTradingPair, setPair ] = useState(tradingPair.pair)
  const [ maxInvest, setMaxInvest ] = useState(tradingPair.maxInvest)
  const [ currentInvest, setCurrentInvest ] = useState(tradingPair.currentInvest)
  const [ interval, setInterval ] = useState(tradingPair.interval)
  const [ isStopLossEnabled, setStopEnabled ] = useState(tradingPair.isStopLossEnabled)
  const [ useDynamicStop, setDynamicStop ] = useState(tradingPair.useDynamicStop)
  const [ stopLossPercentage, setStopPercent ] = useState(tradingPair.stopLossPercentage)

  const [ saving, setSaving ] = useState(false)

  const onSave = async () => {

    setSaving(true)

    const updatedPairs = userDetails?.tradingPairs?.map(pair => {
      if(pair._id === tradingPair._id)
      {
        return (
          { ...pair, 
            maxInvest, 
            pair: currentTradingPair,
            currentInvest,
            useDynamicStop,
            isStopLossEnabled, 
            stopLossPercentage, 
            interval 
          }
        )
      }
      
      return pair
    })

    await updateUserSettings({ tradingPairs: updatedPairs })
    setSaving(false)


  }

  return (<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
    <DialogTitle>Edit Pair</DialogTitle>
    <DialogContent>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField fullWidth placeholder="Pair" value={currentTradingPair} onChange={(e: any) => setPair(e.target.value)}></TextField>
        </Grid>

        <Grid item>
          <TextField label="Max Investment" InputProps={{ startAdornment: "$" }} fullWidth placeholder="Max Invest" value={maxInvest} onChange={(e: any) => setMaxInvest(e.target.value)}></TextField>
        </Grid>

        <Grid item>
          <TextField 
            label="Current Investment" 
            InputProps={{ startAdornment: "$", endAdornment: <IconButton onClick={() => setCurrentInvest(0)}><ResetIcon /></IconButton> }} 
            fullWidth 
            placeholder="Current Investment" 
            value={currentInvest} 
            onChange={(e: any) => setCurrentInvest(e.target.value)}></TextField>
        </Grid>

        <Grid item>
          <TextField label="Chart Interval" fullWidth placeholder="Chart Interval" value={interval} onChange={(e: any) => setInterval(e.target.value)}></TextField>
        </Grid>

        <Grid item>
          <FormControlLabel label="Enable Stop Loss" control={<Switch checked={isStopLossEnabled} onChange={(_, checked) => setStopEnabled(checked)} />} ></FormControlLabel>
        </Grid>

        <Grid item>
          <FormControlLabel label="Enable Dynamic Stop" control={<Switch checked={useDynamicStop} onChange={(_, checked) => setDynamicStop(checked)} />} ></FormControlLabel>
        </Grid>

        { isStopLossEnabled && <Grid item>
          <TextField label="Stop Loss Percentage (Calculated automatically when dynamic is enabled)" InputProps={{ endAdornment: "%" }} disabled={useDynamicStop} fullWidth placeholder="Stop Loss Percentage" value={stopLossPercentage} onChange={(e: any) => setStopPercent(e.target.value)}></TextField>
        </Grid> }



      </Grid>
    </DialogContent>

    <DialogActions>
      <Button onClick={onClose}>Close</Button>
      <Button disabled={saving} onClick={onSave} startIcon={saving ? <CircularProgress size={16} /> : <Save />}>Save</Button>
    </DialogActions>
  </Dialog>)
}