import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card';


import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../interface/ApplicationState';
import { updateLocalUser } from '../../redux/actions/userActions';
import { useTheme } from '@mui/material';
import { UserApi } from '../../api/UserApi';
import { useEffect, useState } from 'react';
import { ApiKeyCheckAdornment } from './ApiKeyCheckAdornment';
import { EndpointSection } from './EndpointSection';


export const BinanceConnectionSettings = () =>
{
  const dispatch = useDispatch();
  const user = useSelector((state: ApplicationState) => state.userStore.user)
  const theme = useTheme()
  const [ isValid, setIsValid ] = useState(false)
  const [ loading, setLoading ] = useState(false)

  const [ error, setError ] = useState("")


  const cardBackGroundColor = user.isDarkModeOn ? theme.palette.grey[900] : theme.palette.background.paper

  const validateKey = async () =>
  {
    try
    {
      setLoading(true)
      await new UserApi().validateApiKey()
      setError("")
      setIsValid(true)
      setLoading(false)
    }
    catch(error)
    {
      const errorCast = error as any
      setIsValid(false)
      setLoading(false)
      setError(errorCast?.message?.response?.data?.error)

    }
  }

  useEffect(() => {

    validateKey()

  }, [user.binanceApiKey, user.binanceApiSecret])

  return(
    <Card style={{ backgroundColor: cardBackGroundColor, borderRadius: 12, margin: 12, padding: 16 }}>


    <Grid item>
      <TextField
         error={!isValid && !loading}
         InputProps={{ endAdornment: <ApiKeyCheckAdornment isValid={isValid} />}} 
         style={{ marginBottom: 16 }} 
         value={user.binanceApiKey} 
         fullWidth 
         label={isValid ? "Binance API Key" : error}
         onChange={(e) => dispatch(updateLocalUser({ binanceApiKey: e.target.value }))} />
    </Grid>

    <Grid item>
      <TextField
        error={!isValid && !loading}
        InputProps={{ endAdornment: <ApiKeyCheckAdornment isValid={isValid} />}} 
        style={{ marginBottom: 16 }} 
        value={user.binanceApiSecret} 
        fullWidth 
        label={isValid ? "Binance API Secret" : error}
        placeholder="Binance API Secret"
        onChange={(e) => dispatch(updateLocalUser({ binanceApiSecret: e.target.value }))}/>
    </Grid>

    <Grid item>
      <EndpointSection />
    </Grid>

  </Card>

  )
}