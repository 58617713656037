
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'

import CheckCircle from '@mui/icons-material/CheckCircle'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../interface/ApplicationState'
import Typography from '@mui/material/Typography'

export const FinishedScreen = () =>
{

  const isAddingSubscription = useSelector((state: ApplicationState) => state.billing.isAddingSubscription)
  const addSubscriptionSuccess = useSelector((state: ApplicationState) => state.billing.addSubscriptionSuccess)
  const addSubscriptionError = useSelector((state: ApplicationState) => state.billing.addSubscriptionError)

  return(<div>
    <Grid container justifyContent="center" flexDirection='column' alignItems="center" spacing={3}>


      <Grid item>

       { isAddingSubscription && <CircularProgress size={32} />  }

       { addSubscriptionSuccess && <CheckCircle fontSize="large" style={styles.icon} />}

      </Grid>

      <Grid item>

      { addSubscriptionSuccess && <Typography style={styles.text} variant="h6" color="textSecondary">Successfully added your subscription</Typography>}
      { addSubscriptionError && <Typography style={styles.text} variant="h6" color="textSecondary">Looks like there was an issue adding your subscription. Please try again</Typography>}

      </Grid>



    </Grid>
  </div>)
}

const styles = {
  icon: {
    height: 64,
    width: 64,
    color: "#4CAF50"
  },
  text: {
    marginBottom: 24,
  }
}