import { useEffect, useState } from "react"
import gravatarUrl from 'gravatar-url';


export const useGravatar = (size: number, email?: string, ) =>
{
  const [ gravatarImage, setGravatarImage ] = useState("")

  useEffect(() => {

    getGravatar()

  }, [ ])

  const getGravatar = () =>
  {
    if(email)
    {
      const imageUrl = gravatarUrl(email, { size })
      setGravatarImage(imageUrl)
    }
  }

  return { gravatarImage }

}