import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import ChartIcon from '@mui/icons-material/GraphicEq';
import { SearchBar } from "../../components/SearchBar";

interface ControlsProps
{
  showChart: (show: boolean) => void
  onOrderChange: (order: string) => void
  clearFilters: () => void
  onTimeRangeChange: (timeRange: number) => void
  setQuery: (query: string) => void
  order: string
  timeRange: number;
}

export const Controls = ({ showChart, clearFilters, onTimeRangeChange, onOrderChange, setQuery, timeRange, order }: ControlsProps) =>
{
  return(
    <div>

  <Grid container direction="row" spacing={2} alignItems="center">

    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >

      <SearchBar placeholder="Search Indicators" setQuery={setQuery} />

    </Grid>
    

    <Grid item  xs={12} sm={12} md={12} lg={4} xl={4}>
      <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Order</InputLabel>
      <Select label="Win Percent Order" value={order} onChange={(e: any) => onOrderChange(e.target.value)}>
        <MenuItem value="highestWin">Highest Win</MenuItem>
        <MenuItem value="lowestWin">Lowest Win</MenuItem>
        <MenuItem value="newest">Latest Result First</MenuItem>
        <MenuItem value="oldest">Oldest Result First</MenuItem>
      </Select>
      </FormControl>
     </Grid>

     <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
      <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Time range</InputLabel>
      <Select label="Time range" value={timeRange} onChange={(e: any) => onTimeRangeChange(e.target.value)}>
        <MenuItem value={1}>Last hour</MenuItem>
        <MenuItem value={4}>Last 4 hours</MenuItem>
        <MenuItem value={8}>Last 8 hours</MenuItem>
        <MenuItem value={12}>Last 12 hours</MenuItem>
        <MenuItem value={24}>Last 24 hours</MenuItem>
        <MenuItem value={48}>Last 48 hours</MenuItem>
        <MenuItem value={168}>Last 7 days</MenuItem>
        <MenuItem value={720}>Last 30 days</MenuItem>
      </Select>
      </FormControl>
     </Grid>

     <Grid item xs={12} sm={12} md={12} lg={1} xl={1}>
       <Button fullWidth onClick={() => showChart(true)} variant="contained" startIcon={<ChartIcon />}>Show Graph</Button>
     </Grid>

     <Grid item xs={12} sm={12} md={12} lg={1} xl={1}>
       <Button fullWidth onClick={clearFilters} variant="outlined" startIcon={<ClearIcon />}>Clear Filters</Button>
     </Grid>

      </Grid>

    </div>
  )
}