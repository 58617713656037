import { Line, YAxis, XAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart } from 'recharts';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment'
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { LogApi } from '../../api/LogApi';
import { useState } from 'react';
import { useEffect } from 'react';
import { TripleRSIItem } from '../../interface/RSIItem';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../interface/ApplicationState';

interface TripleRSIProps
{
  tradingPair: string;
}


export const TripleRSI = ({ tradingPair }: TripleRSIProps) =>
{

  const [ rsi, setRSI ] = useState<TripleRSIItem[] | undefined>(undefined)
  const tripleRsi1 = useSelector((state: ApplicationState) => state.userStore.user.tripleRsi1)
  const tripleRsi2 = useSelector((state: ApplicationState) => state.userStore.user.tripleRsi2)
  const tripleRsi3 = useSelector((state: ApplicationState) => state.userStore.user.tripleRsi3)

  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    getRSI()
  }, [])


  const getRSI = async () =>
  {
    try
    {
      setLoading(true)
      if(tradingPair)
      {
        const response = await new LogApi().getTripleRSI(tradingPair, tripleRsi1 || '15m', tripleRsi2 || '1h' , tripleRsi3 || '4h')
        setRSI(response)
      }
      setLoading(false)
    }
    catch(error)
    {
      setLoading(false)
      return []
    }
    
  }


  return(
   !loading ? <Card style={styles.container}>
    <Typography color="textSecondary">[{tradingPair}] Triple RSI Chart Intervals: {tripleRsi1} /{tripleRsi2}/{tripleRsi3}</Typography>
      <ResponsiveContainer width="100%" height={324}>
      <ComposedChart
        width={100}
        height={500}
        data={rsi || []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >

        <YAxis yAxisId="left"  />
        <YAxis yAxisId="right" domain={['dataMin', 'dataMax']} orientation="right" />
        <XAxis dataKey="timestamp" tickFormatter={(ts: string) => moment(ts).format("DD-MMM hh:mmA")} minTickGap={100}  />
        
        <Legend formatter={(e) => {
          if(e === "rsi1") return `RSI ${tripleRsi1}`
          if(e === "rsi2") return `RSI ${tripleRsi2}`
          if(e === "rsi3") return `RSI ${tripleRsi3}`
          if(e === "close") return "Close Price"

        }} />

        <Line yAxisId="left" type="monotone" dataKey="rsi1" stroke="#42A5F5" dot={false} strokeWidth={3} />
        <Line yAxisId="left" type="monotone" dataKey="rsi2" stroke="#26C6DA" dot={false} strokeWidth={3} />
        <Line yAxisId="left" type="monotone" dataKey="rsi3" stroke="#5C6BC0" dot={false} strokeWidth={3} />
        <Line yAxisId="right" type="monotone" dataKey="close" stroke="#66BB6A" dot={false} strokeWidth={2} />

        <Tooltip
        labelStyle={{ color: "grey" }} 
        labelFormatter={(ts) => `${moment(ts).format("DD-MMM hh:mmA")} - ${moment(ts).fromNow()}`}
        formatter={(tick, itemName) => {

          if(itemName === 'rsi1') return `${Number(tick)} (${tripleRsi1}}`
          if(itemName === 'rsi2') return `${Number(tick)} (${tripleRsi2}}`
          if(itemName === 'rsi3') return `${Number(tick)} (${tripleRsi3}}`
          if(itemName === 'close') return `$${tick} USD`

          return tick
          
          }}/>

      </ComposedChart>
      </ResponsiveContainer>
    </Card> : <div style={styles.loader}><CircularProgress /></div>

  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    padding: 12,
    fontSize: 10,
    margin: 8,
    textAlign: 'center' as 'center'
  },
  loader: {
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}