import { SignalItem } from "../../interface/SignalItem"
import FormControlLabel from '@mui/material/FormControlLabel';

import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../interface/ApplicationState";
import { updateLocalUser } from "../../redux/actions/userActions";


interface SignalSourceProps
{
  signal: SignalItem
}

export const SignalSource = ({ signal }: SignalSourceProps) =>
{
  const dispatch = useDispatch();
  const signalsInvestSources = useSelector((state: ApplicationState) => state.userStore.user.signalsInvestSources) || [];

  const isEnabled = () =>
  {
    if(signalsInvestSources && signalsInvestSources.includes(signal.source)) return true;
  }

  const handleChange = (e: any) =>
  {
    if(e.target.checked)
    {
      dispatch(updateLocalUser({ signalsInvestSources: [ signal.source, ...signalsInvestSources ] }))
    }
    else
    {
      dispatch(updateLocalUser({ signalsInvestSources: signalsInvestSources.filter(s => s !== signal.source) }))
    }
  }

  return(

    <div style={styles.item}>
      <FormControlLabel
      control={<Switch checked={isEnabled()} onChange={handleChange} name={signal.source} />}
      label={signal.source} />
   
    </div>

  )
}

const styles = {
  item: {
    marginLeft: 10,
  }
}