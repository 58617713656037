import { SignalInvestment } from "../../interface/SignalInvestmentsResponse"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import ClockIcon from "@mui/icons-material/PunchClock"

import { blue, green, red } from "@mui/material/colors"
import { UserList } from "./UserList"
import moment from 'moment'
import { TextWithIcon } from "../../components/TextWithIcon"

interface SignalStatDetailsProps
{
  investmentItem: SignalInvestment | undefined
}

export const SignalStatDetails = ({ investmentItem }: SignalStatDetailsProps) =>
{
  return(<Grid item>

    <Typography style={styles.title} fontWeight={700} variant="h6" color="textPrimary">Signal Pair: {investmentItem?.pair}</Typography>
    <TextWithIcon icon={<ClockIcon sx={{ mr: 1, color: "grey" }} />} style={{ marginBottom: 12 }} text={`Signal landed ${moment(investmentItem?.createdAt).fromNow()} | expiry: ${moment(investmentItem?.timeout).fromNow()}`} />
    <Grid container spacing={3}>

     <UserList color={blue[500]} title="Invested Users (Bought in)" usersList={investmentItem?.usersInvested} showProfit={false} />
     <UserList color={green[500]} title="Users Sold (Users that took profit or stop loss)" usersList={investmentItem?.usersSold} showProfit />
     <UserList color={red[500]} title="Buy Errors (Users that had an error placing the OCO)" usersList={investmentItem?.usersError} showProfit={false} />

    </Grid>
      

  </Grid>)
}

const styles = {
  title: {
    marginBottom: 1,
  },
  subText: {
    marginBottom: 6,
  },
  avatar: {
    backgroundColor: blue[400]
  }
}