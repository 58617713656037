import Grid from "@mui/material/Grid"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../interface/ApplicationState"
import Select from "@mui/material/Select"
import { dayTrader, positionTrader, swingTrader } from "../const"
import MenuItem from "@mui/material/MenuItem"
import { updateLocalUser } from "../redux/actions/userActions"
import { Typography } from "@mui/material"



export const TripleRsiCandles = () =>
{
  const dispatch = useDispatch();
  const tripleRsi1 = useSelector((state: ApplicationState) => state.userStore.user.tripleRsi1)
  const tripleRsi2 = useSelector((state: ApplicationState) => state.userStore.user.tripleRsi2)
  const tripleRsi3 = useSelector((state: ApplicationState) => state.userStore.user.tripleRsi3)

  const items = [ ...dayTrader, ...positionTrader, ...swingTrader ]

  return (
    <Grid container flexDirection="row" spacing={2} sx={{ mb: 3 }}>

      <Grid item>
        <Typography textAlign="center" fontWeight="700">RSI 1</Typography>
        <Select fullWidth value={tripleRsi1} onChange={(e: any ) => dispatch(updateLocalUser({ tripleRsi1: e.target.value }))}>
          {items.map((item, key) => <MenuItem key={key} value={item.name}>{item.label}</MenuItem>)}
        </Select>

      </Grid>

      <Grid item>
      <Typography textAlign="center" fontWeight="700">RSI 2</Typography>
        <Select fullWidth value={tripleRsi2} onChange={(e: any ) => dispatch(updateLocalUser({ tripleRsi2: e.target.value }))}>
          {items.map((item, key) => <MenuItem key={key} value={item.name}>{item.label}</MenuItem>)}
        </Select>
      </Grid>

      <Grid item>
      <Typography textAlign="center" fontWeight="700">RSI 3</Typography>
        <Select fullWidth value={tripleRsi3} onChange={(e: any ) => dispatch(updateLocalUser({ tripleRsi3: e.target.value }))}>
          {items.map((item, key) => <MenuItem key={key} value={item.name}>{item.label}</MenuItem>)}
        </Select>
      </Grid>
      
    </Grid>
  )
}