import { ChatStore } from "../../../interface/ChatStore"
import { ChatActions } from "../../actions/chatActions"
import { addMessageToConversation } from "./reducerFunctions/addMessageToConversation"
import { removeConversation } from "./reducerFunctions/removeConversation"


const initialState: ChatStore = {
  isCreatingChat: false,
  isSendingMessage: false,
  isGettingMessage: false,
  isGettingMyChats: false,
  isRemovingMyChat: false,
  error: "",
  conversations: [],
  currentChat: "",
  unseenMessageCount: 0,
  showModal: false,
  // Admin
  isGettingAll: false,
  isDeletingConversation: false,

}

export const chatReducer = (state = initialState, action: any) =>
{
  switch(action.type)
  {
    case ChatActions.TOGGLE_CHAT_MODAL:
      return {
        ...state,
        showModal: action.data.show,
      }
    case ChatActions.SET_CURRENT_CHAT:
      return {
        ...state,
        currentChat: action.data.conversationId,
      }
    case ChatActions.CHAT_WITH_USER_START:
      return {
        ...state,
        isCreatingChat: true,
      }
    case ChatActions.CREATE_CHAT_START:
      return {
        ...state,
        isCreatingChat: true,
      }
    case ChatActions.CREATE_CHAT_SUCCESS:
      return {
        ...state,
        isCreatingChat: false,
        conversations: state.conversations.concat(action.data.chat)
      }
    case ChatActions.READ_CONVERSATION_START:
      return {
        ...state,
        isGettingMessage: true,
      }
    case ChatActions.READ_CONVERSATION_SUCCESS:
      return {
        ...state,
        isGettingMessage: false,
        conversations: addMessageToConversation(state.conversations, action.data.chat)
      }
    case ChatActions.SEND_MESSAGE_START:
      return {
        ...state,
        isSendingMessage: true
      }
    case ChatActions.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        isSendingMessage: false,
        conversations: addMessageToConversation(state.conversations, action.data.chat)
      }
    case ChatActions.GET_ALL_MY_CHATS_START:
      return {
        ...state,
        isGettingMyChats: true
      }
    case ChatActions.GET_ALL_MY_CHATS_SUCCESS:
      return {
        ...state,
        isGettingMyChats: false,
        conversations: action.data.conversations
      }
    case ChatActions.REMOVE_MY_CHAT_START:
      return {
        ...state,
        isRemovingMyChat: true,
      }
    case ChatActions.REMOVE_MY_CHAT_SUCCESS:
      return {
        ...state,
        isRemovingMyChat: false,
        conversations: removeConversation(state.conversations, action.data.conversationId)
      }
    case ChatActions.MARK_READ_SUCCESS:
      return {
        ...state,
        conversations: addMessageToConversation(state.conversations, action.data.chat)
      }
    case ChatActions.REMOVE_CONVERSATION_START:
      return {
        ...state,
        isDeletingConversation: true
      }
    case ChatActions.REMOVE_CONVERSATION_SUCCESS:
      return {
        ...state,
        isDeletingConversation: false,
        conversations: removeConversation(state.conversations, action.data.conversationId)
      }
    case ChatActions.GET_ALL_CHATS_START:
      return {
        ...state,
        isGettingAll: true
      }
    case ChatActions.GET_ALL_CHATS_SUCCESS:
      return {
        ...state,
        isGettingAll: false,
        conversations: action.data.conversations
      }
    case ChatActions.ON_CHAT_ERROR:
      return {
        ...state,
        error: action.error,
        isCreatingChat: false,
        isSendingMessage: false,
        isGettingMessage: false,
        isGettingMyChats: false,
        isGettingAll: false,
        isDeletingConversation: false,
      }
    case ChatActions.GET_UNSEEN_MESSAGE_COUNT_SUCCESS:
      return {
        ...state,
        unseenMessageCount: action.data.count
      }
    case ChatActions.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      }
    default:
      return state
  }

}