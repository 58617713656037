

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import DialogTitle from '@mui/material/DialogTitle';


// Custom
import { Transition } from '../SellModal/Transition';
interface ShowConfirmModalProps
{
  open: boolean
  handleClose: () => void
  onConfirm: (id: string) => void
  id: string
  title: string
  description?: string
}


export const ConfirmModal = ({ open, handleClose, onConfirm, id, title, description }: ShowConfirmModalProps) =>
{

  return(
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>

      <DialogContentText>{description}</DialogContentText>


    </DialogContent>
    <DialogActions style={{ display: 'flex', justifyContent: 'space-between'}}>
      <Button onClick={handleClose}>Close</Button>
      <Button onClick={() => onConfirm(id)}>Yes</Button>

    </DialogActions>


  </Dialog>
  )
}