import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"

import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import { Transition } from "../SellModal/Transition"
import { User } from "../../../interface/User"
import Avatar from "@mui/material/Avatar"
import { blue } from "@mui/material/colors"


interface UserProfileModalProps
{
  open: boolean
  handleClose: () => void
  user: User
}

export const UserProfileModal = ({ open, handleClose, user }: UserProfileModalProps) =>
{


  return(
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
    <DialogTitle>User Profile</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
       
      </DialogContentText>

      <Grid container direction="row" spacing={1} alignItems="center">

        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <Avatar style={styles.avatar}>{user && user.email ? user.email[0].toUpperCase() : "TB"}</Avatar>
        </Grid>

        <Grid item container direction="column" xs={9} sm={12} md={9} lg={9} xl={9} spacing={1}>

          <Grid item>
            <Typography variant="caption" color="textSecondary">Email</Typography>
            <TextField 
              fullWidth 
              placeholder="email" 
              variant="outlined" 
              value={user.email} />
          </Grid>

          <Grid item>
            <Typography variant="caption" color="textSecondary">Username</Typography>

            <TextField 
              fullWidth 
              placeholder="username" 
              variant="outlined" 
              value={user.username} />
          </Grid>

        </Grid>

      </Grid>


    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
    </DialogActions>

   




  </Dialog>
  )
}

const styles = {
  avatar: {
    backgroundColor: blue[500],
    width: 125,
    height: 125,
    fontWeight: "700",
    color: "white",
    fontSize: 40
  }
}