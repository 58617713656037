import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

import Avatar from '@mui/material/Avatar';

import List from '@mui/material/List';

import { styles } from './ChatWindow';
import { Conversation } from '../../interface/Conversation';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { blue, green } from "@mui/material/colors"
import { NoMessagesPlaceholder } from './NoMessagesPlaceholder';

interface MessagesViewProps
{
  messageThread: Conversation[] | undefined
}

export const MessagesView = ({ messageThread }: MessagesViewProps) =>
{
  const listRef = useRef(null);

  useEffect(() => {

      // Scroll to the bottom of the list whenever items change
      // @ts-ignore
      if(listRef && listRef.current) listRef.current.scrollTop = listRef.current.scrollHeight;

  }, [messageThread])

  return(
    <List style={styles.list} ref={listRef}>

     { !messageThread || messageThread.length === 0 && <NoMessagesPlaceholder  animation={require("../../animations/chat-lottie.json")} height={200} width={200} loop={false} text={`It's pretty quite here`} />} 

    { messageThread?.map((message, key) => (
      <Card style={{ ...localStyles.card, float: message.isAdmin ? "right" : "left" }} key={key}>
        <ListItem >

        <ListItemAvatar>
        <Avatar style={{ backgroundColor: message.isAdmin ? blue[500] : green[500] }} >
          {message.from[0].toUpperCase() + message.from[1].toUpperCase()}
        </Avatar>
        </ListItemAvatar>
          <ListItemText primary={message.from} secondary={message.message} />
        </ListItem>

        <div style={localStyles.footer}>
        <Typography variant="caption" color="textSecondary" >{moment(message.timestamp).fromNow()}</Typography>
        </div> 
        </Card>
    ))}

    </List>
  )
}

const localStyles = {
  card: {
    marginTop: 8,
    marginBottom: 8,
    padding: 6,
    width: 500,
    borderRadius: 12,
  },
  footer: {
    justifyContent: "flex-end",
    display: 'flex' as 'flex'
  }
}