
import Tooltip from '@mui/material/Tooltip';
import WarningIcon from '@mui/icons-material/Warning';
import CheckIcon from '@mui/icons-material/Check';

interface ApiKeyCheckAdornmentProps
{
  isValid: boolean
}

export const ApiKeyCheckAdornment = ({ isValid }: ApiKeyCheckAdornmentProps) =>
{
  return(
    isValid ? 
    <Tooltip title="Your Binance API key and secret are valid">
      <CheckIcon htmlColor='#4CAF50' />
    </Tooltip> 
    : 
    <Tooltip title="Looks like your Binance API is invalid. Check that you have granted spot and margin trading in the Binance API settings.">
    <WarningIcon htmlColor='#EF5350' />
  </Tooltip>
  )
}