import { Line, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart, XAxis} from 'recharts';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { LogApi } from '../../api/LogApi';
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { VWAPItem } from '../../interface/VWAPItem';

interface VWAPChartProps
{
  chartInterval: string;
  tradingPair: string;
}

export const VWAPChart = ({ chartInterval, tradingPair }: VWAPChartProps) =>
{

  const [ vwap, setVWAP ] = useState<VWAPItem[] | undefined>(undefined)
  const [ loading, setLoading ] = useState(false)

  useEffect(() =>
  {
    getVWAP(true)
  }, [])

  useEffect(() =>
  {
    getVWAP(false)
  }, [chartInterval])

  const getVWAP = async (load: boolean) =>
  {
    if(tradingPair && chartInterval)
    {
      const logApi = new LogApi()
      load && setLoading(true)
      const vwap = await logApi.getVWAP(tradingPair, chartInterval)
      setVWAP(vwap)
      load && setLoading(false)
    }

  }

  return(
  !loading ? <Card style={styles.container}>
    <Typography color="textSecondary">[{tradingPair}] VWAP Chart Interval: {chartInterval}</Typography>
      <ResponsiveContainer width="100%" height={324}>
      <ComposedChart
        width={100}
        height={500}
        data={vwap || []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <YAxis tickFormatter={(tick) => `$${Number(tick).toFixed(2)}`} domain={['dataMin', 'dataMax']} />
        <XAxis dataKey="timestamp" tickFormatter={(ts: number) => moment(ts).format("DD-MMM hh:mmA")} minTickGap={100} />
        
        <Legend formatter={(e) => {
          if(e === "vwap") return "VWAP"
          if(e === "close") return "Close Price"
        }} />
        <Line type="monotone" dataKey="vwap" stroke="#2196F3" dot={false} strokeWidth={3} />
        <Line type="monotone" dataKey="close" stroke="#FFB300" dot={false} strokeWidth={1} />

        <Tooltip formatter={(tick) => `$${Number(tick).toFixed(2)} USD`} labelStyle={{ color: "grey"}} labelFormatter={(ts) => `${moment(ts).format("DD-MMM hh:mmA")} - ${moment(ts).fromNow()}`} />

      </ComposedChart>
      </ResponsiveContainer>
    </Card> : <div style={styles.loader}><CircularProgress /></div>
  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 10,
    padding: 12,
    margin: 8,
    textAlign: 'center' as 'center'
  },
  loader: {
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}