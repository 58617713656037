
// @ts-ignore
import CreditCardInput from 'react-credit-card-input';
import Grid from '@mui/material/Grid';

interface AddCreditCardProps
{
  cardNumber: string;
  setCardNumber: (cardNumber: string) => void
  setCVC: (cvc: string) => void
  expiry: string;
  setExpiry: (v: string) => void
  cvc: string
}

export const AddCreditCard = ({ cardNumber, expiry, setExpiry, cvc, setCardNumber, setCVC }: AddCreditCardProps) =>
{

  return(<div style={styles.container}>

      <Grid container justifyContent="center" spacing={2}>

        <Grid item>

        <CreditCardInput
          cardNumberInputProps={{ value: cardNumber, onChange: (e: any) => setCardNumber(e.target.value) }}
          cardExpiryInputProps={{ value: expiry, onChange: (e: any) => setExpiry(e.target.value) }}
          cardCVCInputProps={{ value: cvc, onChange: (e: any) => setCVC(e.target.value) }}
          fieldClassName="input"
      
    />

        </Grid>

      </Grid>

 
      
      
  </div>)
}


const styles = {
  container: {
    flex: 1,
    display: 'flex' as 'flex',
    padding: 24,
    justifyContent: 'center' as 'center'
  }
}