import { Summary } from "../interface/Summary"

export const getBalanceForAsset = (summary: Summary | undefined,asset: string) =>
{
  if(summary)
  {
    const assetItem = summary?.account.balances.find(account => account.asset === asset)
    return assetItem
  }

  return { free: "0" }

}