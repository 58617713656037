import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export const Copyright = (props: any) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      {new Date().getFullYear()}
      {' '}
      <Typography variant="caption">
        All Rights Reserved - AlgoTrader Trading Bot
      </Typography>
      { " " }
      <Link href="mailto:support@algo-trader.tech">
      support@algo-trader.tech
      </Link>
    </Typography>
  );
}