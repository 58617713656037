
import List from '@mui/material/List'
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Card from "@mui/material/Card";

// Icons
import UserIcon from '@mui/icons-material/Groups'

import { teal } from '@mui/material/colors';
import { TextWithIcon } from './TextWithIcon';
import { UserStatsResponse } from '../interface/UserStatsResponse';
import Divider from '@mui/material/Divider';
import { getIconForTitle } from '../utils/getIconForType';

interface UserStatsSummaryProps
{
  summary: UserStatsResponse | undefined
}

export const UserStatsSummary = ({ summary }: UserStatsSummaryProps) =>
{

  return(
    <Card style={styles.cardContainer}>

      <TextWithIcon text='User Statistics' icon={<UserIcon htmlColor={teal[400]} fontSize='large' sx={{ mr: 1 }} />} style={{}}/>
      <Divider />

      <List>
        {
          summary && summary.userStatusStats.map((summaryItem, key) => (
          <div key={key} style={styles.profitItem}>
            <Avatar style={styles.avatarProfit}>{getIconForTitle(summaryItem.title, "white")}</Avatar>
            <Typography fontSize={14} color="textSecondary"  style={styles.profitTime}>{summaryItem.title}: </Typography>
            <Typography fontSize={14} >{summaryItem.value} </Typography>
          </div>
          ))
        }

      </List>




    </Card>
  )
}

const styles = {
  cardContainer: {
    padding: 12,
  },
  avatarProfit:{
    backgroundColor: teal[700],
    marginRight: 12,
    width: 28,
    height: 28,
  },
  profitTime: {
    marginRight: 6,
  },
  profitItem: {
    flexDirection: "row" as 'row',
    display: "flex" as 'flex',
    alignItems: 'center' as 'center',
    margin: 6
  }
}