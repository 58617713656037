import Grid from "@mui/material/Grid"
import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import Typography from "@mui/material/Typography"
import { UserSignalProfit } from "../../interface/SignalInvestmentsResponse"
import { blue, yellow } from "@mui/material/colors"
import Avatar from "@mui/material/Avatar"
import ListItemText from "@mui/material/ListItemText"
import ListItemIcon from "@mui/material/ListItemIcon"
import Card from "@mui/material/Card"
import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import Badge from "@mui/material/Badge"
import CardHeader from "@mui/material/CardHeader"
import { GREEN, RED } from "../../Theme/theme"
import { ProfitPill } from "./ProfitPill"

interface UserListProps
{
  usersList: UserSignalProfit[] | undefined
  title: string
  color: string
  showProfit: boolean
}

export const UserList = ({ title, usersList, color, showProfit }: UserListProps) =>
{
  return(
    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>

    <Card style={styles.card}>
      <CardHeader title={title} avatar={<Avatar style={{ backgroundColor: color }}>{usersList?.length}</Avatar>} titleTypographyProps={{ variant: "subtitle1" }}>
      <Typography style={styles.title}>{title}</Typography>

      </CardHeader>
    <Divider />

    <List style={styles.list}>
    { usersList?.map((item, key) => (
      <ListItem key={key} secondaryAction={showProfit ? <ProfitPill item={item} /> : <></>}>
        <ListItemAvatar>
          <Avatar style={styles.avatar}>{item?.email[0].toUpperCase() + item?.email[1].toUpperCase()}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={item.email} secondary={item.description} />
        <ListItemIcon />
      </ListItem>
    ))}
    </List>
    </Card>


  </Grid>
  )
}

const styles = {
  avatar: {
    backgroundColor: yellow[400]
  },
  title: {
    marginLeft: 12,
    marginTop: 12,
    marginBottom: 12,
  },
  card: {
    height: 300
  },
  list: {
    height: 300,
    overflow: 'auto'
  }
}