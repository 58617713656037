import { useEffect } from "react"
import { getSubscriptionStatusBegin } from "../redux/actions/billingActions"
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from "../interface/ApplicationState"
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';


import SubscriptionsIcon from '@mui/icons-material/Subscriptions'
import SettingsIcon from '@mui/icons-material/Settings'
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import moment from 'moment'


export const SubscriptionStatusBadge = () =>
{
  const dispatch = useDispatch()
  const subscriptionStatus = useSelector((state: ApplicationState) => state.billing.subscriptionStatus);
  const isGettingSubscriptions = useSelector((state: ApplicationState) => state.billing.isGettingSubscriptions);
  const user = useSelector((state: ApplicationState) => state.userStore.user);

  
  useEffect(() => {

    dispatch(getSubscriptionStatusBegin())

  }, [])


  return(<div>

    { subscriptionStatus?.mode === "trial" ? 
    <Link to="/settings">
     <Button startIcon={<SubscriptionsIcon />}>Free Trial {subscriptionStatus.daysRemaining} days left</Button>
    </Link> : 

    <Tooltip title={subscriptionStatus?.mode === "paid" ? `Trading enabled and working - thanks for your payment!` : `Free mode (trial expired ${moment(user.createdAt).add(1, 'month').fromNow()})`}>
      <Link to="/settings">
      { isGettingSubscriptions ? 

        <Button  
        size="small"
        style={styles.button} 
        startIcon={<CircularProgress size={16} />}>Checking subscription</Button> 
        
        : 

        <Button
          style={styles.button} 
          size="small"
          startIcon={subscriptionStatus?.isActive ? <SubscriptionsIcon /> : <SettingsIcon />}>
            Subscription {subscriptionStatus?.isActive ? "Active" : "(Free)"}
          </Button>} 

        </Link>
        </Tooltip> }
  </div>)
}

const styles = {
  button: {
    color: "#EEEEEE"
  }
}