
import List from '@mui/material/List'
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Card from "@mui/material/Card";

// Icons
import MonetizationOn from '@mui/icons-material/PaymentRounded'

import { green } from '@mui/material/colors';
import { TextWithIcon } from './TextWithIcon';
import { ProfitSummary } from '../interface/ProfitSummary';
import { getIconForTitle } from '../utils/getIconForType';
import Divider from '@mui/material/Divider';

interface StripeProfitSummaryProps
{
  summary: ProfitSummary[] | undefined
}

export const StripeProfitSummary = ({ summary }: StripeProfitSummaryProps) =>
{

  return(
    <Card style={styles.cardContainer}>

      <TextWithIcon text='Incoming Payments Summary' icon={<MonetizationOn htmlColor={green[400]} fontSize='large' sx={{ mr: 1 }} />} style={{}}/>
      <Divider />
      <List>
        {
          summary && summary.map((summaryItem, key) => (
           <div key={key} style={styles.profitItem}>
            <Avatar style={styles.avatarProfit}>{getIconForTitle(summaryItem.time, "white")}</Avatar>
            <Typography fontSize={14} color="textSecondary" style={styles.profitTime}>{summaryItem.time}: </Typography>
            <Typography fontSize={14}>${summaryItem.profit} (AUD)</Typography>

           </div>
          ))
        }
      
      </List>

    </Card>
  )
}

const styles = {
  cardContainer: {
    padding: 12,
  },
  avatarProfit:{
    backgroundColor: green[700],
    marginRight: 12,
    width: 28,
    height: 28,
  },
  profitTime: {
    marginRight: 6,
  },
  profitItem: {
    flexDirection: "row" as 'row',
    display: "flex" as 'flex',
    alignItems: 'center' as 'center',
    margin: 6
  }
}