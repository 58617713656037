import { Line, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart, XAxis } from 'recharts';
import CircularProgress from '@mui/material/CircularProgress';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { LogApi } from '../../api/LogApi';
import { useState } from 'react';
import { useEffect } from 'react';
import { MFIItem } from '../../interface/MFIItem';
import moment from 'moment';
import { useLogs } from '../../hooks/useLogs';
import { CustomizedDotSell, CustomizedDotBuy } from '../CustomDot';

interface MFIChartProps
{
  chartInterval: string;
  tradingPair: string;
  mfiThresholdBuy: number | undefined;
  mfiThresholdSell: number | undefined;
}


export const MFIChart = ({ chartInterval, tradingPair, mfiThresholdBuy, mfiThresholdSell }: MFIChartProps) =>
{

  const [ mfi, setMfi ] = useState<MFIItem[] | undefined>(undefined)
  const [ loading, setLoading ] = useState(false)
  const { getLogs, findLogAtCandle } = useLogs()

  useEffect(() =>
  {
    combineLogsAndChart(true)
  }, [])

  useEffect(() =>
  {
    combineLogsAndChart(false)
  }, [chartInterval])

  const getMfi = async () =>
  {
    try
    {
      if(tradingPair && chartInterval) return new LogApi().getMfi(tradingPair, chartInterval)
      return []
    }
    catch(error)
    {
      return []
    }

  }

  const combineLogsAndChart = async (load: boolean) =>
  {
    try
    {
      load && setLoading(true)
      const combinedChart: MFIItem[] = []
      let fromTime = new Date().toISOString();
      const chartData = await getMfi()
      if(chartData)
      {
        fromTime = chartData[0].timestamp;
        await getLogs(fromTime)

        for(const candle of chartData)
        {
          const foundLog = findLogAtCandle(candle)
          if(foundLog && foundLog.didBuy) candle.didBuy = foundLog.lastPrice
          if(foundLog && foundLog.didSell) candle.didSell = foundLog.lastPrice
          combinedChart.push(candle)
        }
      }

      load && setLoading(false)
      setMfi(combinedChart)
    }
    catch(error)
    {
      load && setLoading(false)
    }

  }

  const getInfoForIndex = (index: number) =>
  {
    if(mfiThresholdSell && mfiThresholdBuy)
    {
      if(index > mfiThresholdSell) return `${index} is overbought (Should sell)`
      if(index < mfiThresholdBuy) return `${index} is oversold (Should buy)`
      return `${index} is within ${mfiThresholdBuy} ${mfiThresholdSell} range [HOLD]`
    }

    return ""

  }

  return(
   !loading ? <Card style={styles.container}>
    <Typography color="textSecondary">[{tradingPair}] Money Flow Index Chart Interval: {chartInterval}</Typography>
      <ResponsiveContainer width="100%" height={324}>
      <ComposedChart
        width={100}
        height={500}
        data={mfi || []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <YAxis yAxisId="left"  />
        <YAxis yAxisId="right" domain={['dataMin', 'dataMax']} orientation="right" />
        <XAxis dataKey="timestamp" tickFormatter={(ts: string) => moment(ts).format("DD-MMM hh:mmA")} minTickGap={100}  />
        
        <Legend formatter={(e) => {
          if(e === "close") return "Close Price"
          if(e === "mfi") return "Money Flow Index"
          if(e === "didSell") return "Sell"
          if(e === "didBuy") return "Buy"

        }} />
        <Line yAxisId="left" type="monotone" dataKey="mfi" stroke="#03A9F4" dot={false} strokeWidth={3} />
        <Line yAxisId="right" type="monotone" dataKey="close" stroke="#66BB6A" dot={false} strokeWidth={2} />
        <Line yAxisId="right" type="monotone" dataKey="didSell" dot={<CustomizedDotSell />}  />
        <Line yAxisId="right" type="monotone" dataKey="didBuy" dot={<CustomizedDotBuy />}  />

        <Tooltip 
        labelStyle={{ color: "grey"}}
        labelFormatter={(ts) => `${moment(ts).format("DD-MMM hh:mmA")} - ${moment(ts).fromNow()}`}
        formatter={(tick, itemName) => {

          if(itemName === "didSell" ) return `Sold at price $${Number(tick).toFixed(2)} USD`
          if(itemName === "didBuy" ) return `Bought at price $${Number(tick).toFixed(2)} USD`
          if(itemName === 'close') return `$${Number(tick).toFixed(2)} USD`
          if(itemName === 'mfi') return getInfoForIndex(Number(tick))
          return tick
          
          }}  />

      </ComposedChart>
      </ResponsiveContainer>
    </Card> : <div style={styles.loader}><CircularProgress /></div>

  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    padding: 12,
    fontSize: 10,
    margin: 8,
    textAlign: 'center' as 'center'
  },
  loader: {
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}