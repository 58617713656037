import { useEffect, useState } from "react"
import { SignalsInvestmentsResponse } from "../../interface/SignalInvestmentsResponse"
import { AdminApi } from "../../api/AdminApi"
import Grid from "@mui/material/Grid"
import { HeaderCard } from "./HeaderCard"
import { SignalStatDetails } from "./SignalStatDetails"
import { blue, green, purple } from "@mui/material/colors"
import { LoadingView } from "../../components/placeholder/LoadingView"
import { LottieLoading } from "../../components/placeholder/LottieLoading"




export const SignalsAdmin = () =>
{

  const [ loading, setLoading ] = useState(false)
  const [ signalStats, setSignalStats ] = useState<SignalsInvestmentsResponse | undefined>()

  useEffect(() => {

    getStats()

  }, [])

  const getStats = async () =>
  {
    try
    {
      setLoading(true)
      const response = await new AdminApi().getSignalInvestmentStats()
      setSignalStats(response)
      setLoading(false)
    }
    catch(error)
    {
      setLoading(false)
    }
  }

  return (<div style={styles.container}>

    { loading ? <LottieLoading animation={require("../../animations/dashboard-lottie.json")} height={300} width={300} loop text="Getting Signal Stats" /> : 
    <>
    <Grid container spacing={3} sx={{ mb: 3 }}>
      <HeaderCard color={purple[400]} title="Enabled users" subText="Users with signals enabled" value={signalStats?.usersWithSignalsEnabled}/>
      <HeaderCard color={green[400]} title="User investments" subText="Investments of active signals in all states" value={signalStats?.totalUserInvestments}/>
      <HeaderCard color={blue[600]} title="Total signals with users" subText="All signals that have users invested or sold" value={signalStats?.signalInvestment.length}/>
    </Grid>

    <Grid container spacing={3} direction="column">

    {/* SORT BY NEWEST FIRST */}
    { signalStats?.signalInvestment.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1).map((item, key) => <SignalStatDetails key={key} investmentItem={item}/>)}

    </Grid> </>}

  </div>)
}

const styles = {
  container: {
    height: "100%",
    marginBottom: 32,
  }
}