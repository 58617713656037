
import { Typography } from '@mui/material'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

export const Privacy = () =>
{

  return(
        <Container >
          <CssBaseline />
          <Card sx={{ p: 1, m: 1, borderRadius: 2 }}>
          <Typography variant="h4" sx={{ marginBottom: 2 }} textAlign='center'>
            Trading Bot Privacy Policy
          </Typography>
          <div style={{ marginBottom: 16 }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              1. Information We Collect
            </Typography>
            <Typography variant="body1">
              We collect the following information when you use the App:
            </Typography>
            <ul >
              <Typography variant="body2">- Email address</Typography>
              <Typography variant="body2">- Username</Typography>
              <Typography variant="body2">- Trading activity and history</Typography>
            </ul>
            <Typography variant="body1">
              We do not collect or store any credit card information.
            </Typography>
          </div>
          <div style={{ marginBottom: 16 }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              2. How We Use Your Information
            </Typography>
            <Typography variant="body1">
              We use your information to:
            </Typography>
            <ul>
              <Typography variant="subtitle2">- Provide the App's services to you</Typography>
              <Typography variant="subtitle2">- Analyze and improve the App's performance and features</Typography>
              <Typography variant="subtitle2">- Communicate with you regarding your use of the App and any updates or changes to the App</Typography>
              <Typography variant="subtitle2">- Enforce the App's terms and conditions and other legal policies</Typography>
              <Typography variant="subtitle2">- Respond to inquiries and support requests</Typography>
            </ul>
          </div>
          <div style={{ marginBottom: 16 }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              3. How We Protect Your Information
            </Typography>
            <Typography variant="body1">
              We take reasonable measures to protect your information from
              unauthorized access, use, disclosure, and alteration. We use
              industry-standard security measures to safeguard your information.
            </Typography>
          </div>
          <div style={{ marginBottom: 16 }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              4. Sharing Your Information
            </Typography>
            <Typography variant="body1">
              We do not sell or rent your personal information to third parties. We
              may share your information with third-party service providers who help
              us operate the App, such as hosting and payment processing providers.
              These service providers are bound by confidentiality agreements and
              are prohibited from using your information for any other purpose.
            </Typography>
            <Typography variant="body1">
              We may also share your information as required by law or in response
              to legal process, such as a subpoena or court order.
            </Typography>
          </div>
          <div style={{ marginBottom: 16 }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              5. Data Retention and Deletion
            </Typography>
            <Typography variant="body1">
              We retain your information only for as long as necessary to provide
              you with the App's services and for as long as required by law. When
              you delete your account, we delete all information associated with
              your account, including your email address, username, and trading
              activity and history.
            </Typography>
          </div>

          <div style={{ marginBottom: 4 }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              6. Changes to This Privacy Policy
            </Typography>
            <Typography variant="body1">
            We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes.
            </Typography>
          </div>

          <div style={{ marginBottom: 4 }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              7. Contact Us
            </Typography>
            <Typography variant="body1">
            If you have any questions or concerns about this privacy policy, please contact us at support@algo-trader.tech
            </Typography>
          </div>
          </Card>
         </Container>
  
  
      )
}
