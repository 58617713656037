import Card from '@mui/material/Card'
import { AVATAR_SIZE, styles } from '../../Pages/Dashboard'
import Typography from '@mui/material/Typography'
import { Skeleton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import Avatar from '@mui/material/Avatar'
import { Summary } from '../../interface/Summary'
import { ProfitSummary } from '../../interface/ProfitSummary'


interface ProfitAndLossProps
{
  summary: Summary | undefined
  loading: boolean
  profitSummary: ProfitSummary[] | undefined
}

export const ProfitAndLoss = ({ summary, loading, profitSummary }: ProfitAndLossProps) =>
{
  return(
    <Card style={styles.card}>

      { !loading && <Typography fontWeight={700}>Profit and Loss</Typography> } 

      <div style={localStyles.container}>

        <div style={localStyles.column1}>

        { !loading ? <Typography color="textSecondary" variant="caption">Total Sales: ${summary && summary.totalAmountSold.toFixed(2)}</Typography> : <Skeleton width={200} /> }
        { !loading ? <Typography color="textSecondary" variant="caption">Total Buys: ${summary && summary.totalAmountPurchased.toFixed(2)}</Typography> : <Skeleton width={200} /> }

        { loading && [0, 0, 0, 0, 0, 0, 0, 0].map((i, key) => <Skeleton key={key} width={150} />)}
        {
          !loading && profitSummary && profitSummary.map((item, key) => {
            return(
              <Typography key={key} color="textSecondary" variant="caption">{`${item.time}: $${item.profit} (USD)`}</Typography>
            )
          })
        }
        </div>


        <div style={localStyles.column2}>
          {loading ? <Skeleton variant="circular" width={AVATAR_SIZE} height={AVATAR_SIZE} /> : 
          <Tooltip title="365 day profit">
            <Avatar style={styles.avatar4}>${Number(profitSummary ? profitSummary[4].profit : 0).toFixed(2)}</Avatar>
          </Tooltip> }
        </div> 

     
      </div>

    </Card>
  )
}

const localStyles = {
  container: {
    display: 'flex' as "flex",
  },

  column1: {
    display: 'flex' as 'flex',
    flexDirection: "column" as "column",
    overflow: 'auto',
    height: 160
  },
  column2: {
    display: 'flex' as 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end'
  }

}