import ListItem from "@mui/material/ListItem"
import List from "@mui/material/List"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemText from "@mui/material/ListItemText"
import ListIcon from '@mui/icons-material/CurrencyBitcoin';
import { AccountInfo } from "../../../interface/AccountInfo"
import Alert from "@mui/material/Alert";
import { useState } from "react";
import { CardActionArea } from "@mui/material";
import { UserSellModal } from "../SellModal/UserSellModal";
import { BalanceItem } from "../../../interface/Summary";
import { SearchBar } from "../../SearchBar";


interface AccountInfoProps
{
  accountInfo: AccountInfo | undefined
  userId?: string
}

export const AccountInfoSection = ({ accountInfo, userId }: AccountInfoProps) =>
{

  const [ showSellModal, setShowSellModal ] = useState<BalanceItem | undefined>()
  const [ query, setQuery ] = useState("")


  const areAllZero = () =>
  {
    let allZero = true
    if(accountInfo)
    {
      for(const balance of accountInfo.balances) if(Number(balance.free) > 0) allZero = false
    }

    return allZero
  }

  const useFilteredPairs = (balances: BalanceItem[]) => {
    if(query === "") return balances
    
    return balances.filter(balance => {
      if(balance?.asset?.toLowerCase().includes(query?.toLowerCase())) return true
    })
  }

  return(
    <>
    <SearchBar placeholder="Search user trading pairs" setQuery={setQuery}/>
      <List>
            { !areAllZero() ? accountInfo && accountInfo && useFilteredPairs(accountInfo.balances)
              .sort((balance1, balance2) => Number(balance2.free) - Number(balance1.free))
              .map((balanceItem, key) => {
              if(Number(balanceItem.free) > 0)
              {
                return(
                  <CardActionArea key={key} onClick={() => setShowSellModal(balanceItem)}>
                  <ListItem >
                  <ListItemAvatar>
                    <ListIcon style={styles.avatar} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={balanceItem.asset}
                    secondary={`Free: ${balanceItem.free}`}
                  />
                </ListItem>
                </CardActionArea>
                )
              }
            }) : <Alert color="info">All balances are 0</Alert>}
        </List>

        { showSellModal && userId && <UserSellModal open={!!showSellModal} handleClose={() => setShowSellModal(undefined)}  userId={userId} tradingPair={showSellModal.asset + "USDT"} freeAmount={Number(showSellModal.free)} /> }
        </>)
}

const styles = {
  avatar: {
    backgroundColor: "#3F51B5",
    borderRadius: 50,
    width: 25,
    height: 25
  }
}