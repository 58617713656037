import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../interface/ApplicationState';
import { updateLocalUser } from '../../redux/actions/userActions';
import { useTheme } from '@mui/material';
import { CandleStickIntervalChooser } from '../../components/CandleStickIntervalChooser';
import { dayTrader, swingTrader, positionTrader } from '../../const';


export const CandleStickSettings = () =>
{
  const dispatch = useDispatch();
  const user = useSelector((state: ApplicationState) => state.userStore.user)
  const theme = useTheme()

  const cardBackGroundColor = user.isDarkModeOn ? theme.palette.grey[900] : theme.palette.background.paper

  return(
    <Card style={{ backgroundColor: cardBackGroundColor, borderRadius: 12, margin: 12, padding: 16 }}>


    <Typography variant="subtitle2" color="textSecondary">Default 1 minute.
      This is used to summarise the current order closes by time buckets. 
      Smaller buckets means the bot will be more sensitive to changes in the market.</Typography>

    
    <Grid item container>
      <CandleStickIntervalChooser title="Day trader (Dozens of trades per day)" items={dayTrader} currentCheckedItem={user.chartInterval || ""} onItemChanged={(interval) => dispatch(updateLocalUser({ chartInterval: interval }))} />
      <CandleStickIntervalChooser title="Swing Trader (Fewer trades (1-10 a day)" items={swingTrader} currentCheckedItem={user.chartInterval || ""} onItemChanged={(interval) => dispatch(updateLocalUser({ chartInterval: interval }))} />
      <CandleStickIntervalChooser title="Position trader (Few trades)" items={positionTrader} currentCheckedItem={user.chartInterval || ""} onItemChanged={(interval) => dispatch(updateLocalUser({ chartInterval: interval }))} />
    </Grid>

    </Card>
  )
}