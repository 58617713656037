
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stripe } from 'stripe'

interface CardsListProps
{
  paymentMethods: Stripe.Response<Stripe.ApiList<Stripe.PaymentMethod>> | undefined
  setShowConfirmDeleteCardModal: (id: string) => void
}

export const CardsList = ({ paymentMethods, setShowConfirmDeleteCardModal }: CardsListProps) =>
{
  return(
    <>
    <Typography variant="subtitle2" color="textSecondary">Cards</Typography>
    <List dense>
      { paymentMethods && paymentMethods.data.map((paymentMethod, key) => (
         <ListItem
        key={key}
         secondaryAction={
           <IconButton edge="end" aria-label="delete" onClick={() => setShowConfirmDeleteCardModal(paymentMethod.id)}>
             <DeleteIcon />
           </IconButton>
         }
       >
         <ListItemAvatar>
           <Avatar style={styles.avatar}>
            {paymentMethod.card?.brand[0].toUpperCase()}
           </Avatar>
         </ListItemAvatar>
         <ListItemText
           primary={`Card Type: ${paymentMethod.card?.brand}`}
           secondary={`Last 4 Digits: ${paymentMethod.card?.last4} - Expiry: ${paymentMethod.card?.exp_month}/${paymentMethod.card?.exp_year}`}
         />
       </ListItem>
      ))}
     </List>
     </>
  )
}



const styles = 
{
  avatar: {
    backgroundColor: "#4CAF50",
    fontWeight: "700"
  }
}