import { Typography } from "@mui/material";
import { IRunEvent } from "../../interface/IRunEvent"
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from 'moment'
import { GREEN, RED } from "../../Theme/theme";

interface LogItemProps
{
  row: IRunEvent
  setShowOrderDetails: (runEvent: IRunEvent | undefined) => void
}

export const LogItem = ({ row, setShowOrderDetails }: LogItemProps) =>
{
  const formatNumber = (number: number) =>
  {
    try
    {
      return number.toFixed(2)
    }
    catch(error)
    {
      return "unknown"
    }
  }

  const isError = () =>
  {
    if(!row.didBuy && !row.didSell) return true
    return false
  }

  return(
    <TableRow key={row._id} hover style={{border: row.didSell ? "2px solid #66BB6A" : row.didBuy ? "2px solid #64B5F6" : "2px solid #EF5350"}} onClick={() => setShowOrderDetails(row)}>
    <TableCell component="th" scope="row">
      {moment(row.timestamp).format("lll")}
    </TableCell>
    <TableCell align="right">{row.marketId}</TableCell>
    <TableCell align="right">{row.didSell ? row.sellResult : row.buyResult}</TableCell>
    <TableCell align="right">${formatNumber(row.lastPrice)}</TableCell>
    <TableCell align="right">{row.cryptoAmount || "-"}</TableCell>
    <TableCell align="right">${row.amount}</TableCell>

    <TableCell align="center">
      
      { isError() ? "-" : <Typography fontWeight={700} variant="body2" style={{ color: 'white', backgroundColor: row.profit > 0 ? GREEN : RED, borderRadius: 12, padding: 2 }}>
      {`$${row.profit} ${row && row.percentProfit ? `(${row.percentProfit}%)` : ""} `}
      </Typography> }

    </TableCell>
    
    <TableCell align="right">${row.tradeFee ? `${row.tradeFee}`: 0}</TableCell>
    <TableCell align="right">{row.rsi || "-"}</TableCell>
    <TableCell align="right">{row.mfi || "-"}</TableCell>

    <TableCell align="right">${row.totalAssets ? formatNumber(row.totalAssets) : 0}</TableCell>

  </TableRow>
  )
}