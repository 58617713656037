import { AxiosError } from "axios"
import { put, takeLatest } from "redux-saga/effects"
import { BillingApi } from "../../api/BillingApi"
import { 
  addCardBegin,
  addCardError, 
  addCardSuccess, 
  addSubscriptionBegin, 
  addSubscriptionError, 
  addSubscriptionSuccess, 
  BillingActions, 
  createUserBegin, 
  createUserError, 
  createUserSuccess, 
  getSubscriptionError, 
  getSubscriptionStatusError, 
  getSubscriptionStatusSuccess, 
  getSubscriptionSuccess } from "../actions/billingActions"

function* createCustomer(action: any): any
{
  try
  { 
    yield put(createUserBegin())
    const billingApi = new BillingApi()
    const customerResponse = yield billingApi.createCustomer(action.data.email, action.data.name)
    yield put(createUserSuccess(customerResponse))
    yield addCreditCard(action)
  }
  catch(error)
  {
    console.error(error)
    yield put(createUserError(error as AxiosError))
  }

}

function* getActiveSubscriptions(): any
{
  try
  {
    const billingApi = new BillingApi()
    const subscriptions = yield billingApi.getSubscriptions()
    yield put(getSubscriptionSuccess(subscriptions))
  }
  catch(error)
  {
    yield put(getSubscriptionError(error as AxiosError))
  }

}

function* getSubscriptionStatus(): any
{
  try
  {
    const status = yield new BillingApi().getSubscriptionStatus()
    yield put(getSubscriptionStatusSuccess(status))
  }
  catch(error)
  {
    yield put(getSubscriptionStatusError(error as AxiosError))
  }

}

function* addCreditCard(action: any): any
{
  try
  {
    yield put(addCardBegin())
    const billingApi = new BillingApi()
    const response = yield billingApi.addCreditCard(action.data.cvc, action.data.expMonth, action.data.expYear, action.data.cardNumber)
    yield put(addCardSuccess(response))
    yield addSubscription(action)
  }
  catch(error)
  {
    yield put(addCardError(error as AxiosError))
  }

}

function* addSubscription(action: any): any
{
  try
  {
    yield put(addSubscriptionBegin())
    const billingApi = new BillingApi()
    const response = yield billingApi.addSubscription(action.data.productId)
    yield put(addSubscriptionSuccess(response))
  }
  catch(error)
  {
    yield put(addSubscriptionError(error as AxiosError))
  }

}

function* startSetup(action: any)
{
  yield createCustomer(action)
}

function* resumeSetup(action: any)
{
  yield addCreditCard(action)
}



export function* billingSaga()
{
  yield takeLatest(BillingActions.GET_SUBSCRIPTION_BEGIN, getActiveSubscriptions)
  yield takeLatest(BillingActions.GET_SUBSCRIPTION_STATUS_BEGIN, getSubscriptionStatus)
  yield takeLatest(BillingActions.START_NEW_SETUP, startSetup)
  yield takeLatest(BillingActions.RESUME_SETUP, resumeSetup)
}