import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import CssBaseline from '@mui/material/CssBaseline';

import Typography from "@mui/material/Typography"
import PasswordIcon from "@mui/icons-material/Password"

import SecurityOutlined from "@mui/icons-material/SecurityOutlined"

import { useState } from "react"
import { useEffect } from "react"
import { LoginApi } from "../api/LoginApi"
import { AxiosError } from "axios"
import { useNavigate } from "react-router-dom"

export const ResetPassword = () =>
{

  const [ isLoading, setLoading ] = useState(false)
  const [ password, setPassword ] = useState("")
  const [ error, setError ] = useState("")
  const [ success, setSuccess ] = useState<{ result: string} | undefined>()
  const [ confirmPassword, setConfirmPassword ] = useState("")
  const navigate = useNavigate()
 
  useEffect(() => {

    getAuthKey()

  }, [])

  const performReset = async () =>
  {
    try
    {
      if(!getAuthKey()) return setError("Missing key")
      setLoading(true)
      const response = await new LoginApi().changePassword(getAuthKey() as string, password, confirmPassword)
      setSuccess(response)
      setLoading(false)

      setTimeout(() => {
        navigate("/")
      }, 4000)
    }
    catch(error)
    {
      setError(getResetError(error as AxiosError))
      setLoading(false)
    }
  }

  const getResetError = (error: AxiosError) =>
  {
    try
    {
      console.log(error)
      // @ts-ignore
      if(error.message.response.data.error) return error.message.response.data.error
      // @ts-ignore
      if(error.message.response.data.error.message) return error.message.response.data.error.message
      return "Looks like somethign went wrong"
    }
    catch(error)
    {
      return ""
    }
  }

  const getAuthKey = () =>
  {
    try
    {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const resetKey = urlParams.get('resetKey')
      return resetKey
    }
    catch(error)
    {
      console.error(error)
      return ""
    }
  }


  return(
    <div style={styles.container}>
      <CssBaseline />
      <Card style={styles.card}>

        <Grid container flexDirection="column" spacing={3}>

        <Grid item xs={12} textAlign="center">
            <SecurityOutlined fontSize="large" />
          </Grid>

          <Grid item xs={12}>
            <Typography color="textSecondary" textAlign="center">Reset your password</Typography>
          </Grid>

          <Grid item>
            <TextField value={password} onChange={(e: any) => setPassword(e.target.value)} type="password" autoFocus placeholder="password" fullWidth variant="outlined"  />
          </Grid>

          <Grid item>
          <TextField value={confirmPassword} onChange={(e: any) => setConfirmPassword(e.target.value)} type="password" placeholder="confirmPassword" fullWidth variant="outlined" />
          </Grid>

          <Grid item alignSelf="center">
            <Button disabled={isLoading} onClick={performReset} startIcon={<PasswordIcon />} variant="outlined">{isLoading ? "Resetting" : "Reset"}</Button>
          </Grid>
        </Grid>

      </Card>

      <Snackbar open={!!success} message={success?.result} autoHideDuration={6000} onClose={() => setSuccess(undefined)}>
        <Alert severity="success">{success?.result}</Alert>
      </Snackbar>

      <Snackbar open={!!error} message={error} autoHideDuration={6000} onClose={() => setError("")}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>


    </div>
  )
}

const styles = {
  container: {
    height: "90vh",
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
  },
  card: {
    margin: 10,
    padding: 24,
    width: 400,
    borderRadius: 12,
  }
}