import Button from "@mui/material/Button"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"
import LinkIcon from "@mui/icons-material/Link"

import Lottie from "lottie-react"
import { PageSectionItem } from "../../../interface/PageSectionItem"

interface PageSectionProps
{
  pageSection: PageSectionItem
}

export const PageSection = ({ pageSection }: PageSectionProps) => {

    return(
      <DialogContent style={styles.content} >
        <Typography variant="h6" fontSize={32} fontWeight={700}>{pageSection.title}</Typography>
        <Typography variant="subtitle1" color="textSecondary">{pageSection.description}</Typography>
        <Lottie animationData={pageSection.animation} loop={false} autoPlay style={styles.lottie} />

        <div style={styles.row}>
        { pageSection.linkTitle && pageSection.linkUrl && 
          <Button sx={{ mr: 1 }} onClick={() => window.open(pageSection.linkUrl, "_blank")} startIcon={<LinkIcon />} variant="outlined">{pageSection.linkTitle}</Button> }

        { pageSection.linkUrlAlt && pageSection.linkUrlAlt && 
          <Button onClick={() => window.open(pageSection.linkUrlAlt, "_blank")} startIcon={<LinkIcon />} variant="outlined">{pageSection.linkTitleAlt}</Button> }
        </div>
     

      </DialogContent>
    )

}

const styles = {
  row: {
    display: 'flex' as 'flex',
    flexDirection: 'row' as 'row',
  },
  content: {
    flexDirection: "column" as 'column',
    justifyContent: "center" as 'center',
    alignItems: 'center' as 'center',
    textAlign: 'center'as 'center',
    flex: 1,
    display: 'flex' as 'flex'
  },
  lottie: {
    width: 300,
    height: 300, 
  }
}