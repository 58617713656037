import { IChat } from "../interface/Chat";
import { HttpService } from "./HttpService";



export class ChatApi extends HttpService
{
  createChat = async (subject: string): Promise<IChat | undefined> =>
  {
    const response = await this.request<IChat>("POST", `chat/create?subject=${subject}`)
    if(response) return response.data
  }

  startChatWithUser = async (subject: string, destinationUserId: string): Promise<IChat | undefined> =>
  {
    const response = await this.request<IChat>("POST", `chat/startChatWithUser?subject=${subject}&destinationUserId=${destinationUserId}`)
    if(response) return response.data
  }

  readConversation = async (conversationId: string): Promise<IChat | undefined> =>
  {
    const response = await this.request<IChat>("GET", `chat/read?conversationId=${conversationId}`)
    if(response) return response.data
  }

  sendMessage = async (conversationId: string, from: string, message: string): Promise<IChat | undefined> =>
  {
    const response = await this.request<IChat>("POST", `chat/send?conversationId=${conversationId}`, { from, message })
    if(response) return response.data
  }

  getMyChats = async (): Promise<IChat[] | undefined> =>
  {
    const response = await this.request<IChat[]>("GET", `chat/myChats`)
    if(response) return response.data
  }

  removeMyChat = async (conversationId: string): Promise<{ result: string, _id: string } | undefined> =>
  {
    const response = await this.request<{ result: string, _id: string }>("DELETE", `chat/removeMyChat?conversationId=${conversationId}`)
    if(response) return response.data
  }

  getNotificationCount = async (): Promise<{ messages: number } | undefined> =>
  {
    const response = await this.request<{ messages: number }>("GET", `chat/notificationCount`)
    if(response) return response.data
  }

  /**
   * @description mark read
   * @returns 
   */
     markRead = async (conversationId: string): Promise<IChat | undefined> =>
     {
       const response = await this.request<IChat>("POST", `chat/markRead?conversationId=${conversationId}`)
       if(response) return response.data
     }

  /**
   * @description Admin call only
   * @returns 
   */
  getAll = async (): Promise<IChat[] | undefined> =>
  {
    const response = await this.request<IChat[]>("GET", `chat/getAll`)
    if(response) return response.data
  }

     /**
   * @description Admin call only
   * @returns 
   */
  removeConversation = async (conversationId: string): Promise<{ result: string, _id: string } | undefined> =>
  {
    const response = await this.request<{ result: string, _id: string }>("DELETE", `chat/remove?conversationId=${conversationId}`)
    if(response) return response.data
  }
}