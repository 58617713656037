import Card from "@mui/material/Card"
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from "../../interface/ApplicationState";
import { updateLocalUser } from "../../redux/actions/userActions";
import { Typography, useTheme } from '@mui/material';
import Slider from "@mui/material/Slider";
import { SignalSourceSection } from "./SignalSourceSection";



export const SignalsSection = () =>
{
  const user = useSelector((state: ApplicationState) => state.userStore.user)

  const dispatch = useDispatch()
  const theme = useTheme()

  const cardBackGroundColor = user.isDarkModeOn ? theme.palette.grey[900] : theme.palette.background.paper


  return(
    <Card style={{ backgroundColor: cardBackGroundColor, borderRadius: 12, margin: 12, padding: 16 }}>

    <FormControlLabel
      control={<Switch checked={user.isSignalsEnabled} onChange={(e) => dispatch(updateLocalUser({ isSignalsEnabled: e.target.checked }))} name="Enable Signals" />}
      label="Enable Signals" />

    <Typography color="textSecondary">Signals Max Buy in USDT</Typography>

    <Slider
      style={styles.signalsSlider}
      max={5000}
      value={user.signalsMaxBuy}
      onChange={(e: any) => {
        dispatch(updateLocalUser({ signalsMaxBuy: e.target.value }))
      }}
      disableSwap
      valueLabelDisplay="on"
    />

    <SignalSourceSection />

    </Card>
  )
}

const styles = {
  signalsSlider: {
    marginTop: 34,
    marginLeft: 12,
    marginRight: 12

  }
}
