import { LoginResponse, SignUpResponse } from "../interface/LoginResponse";
import { HttpService } from "./HttpService";

export class LoginApi extends HttpService
{
  login = async (username: string, password: string): Promise<LoginResponse | undefined> =>
  {
      const response = await this.request<LoginResponse>("POST", "login", { username, password })
      if(response) return response.data
  }

  signup = async (email: string, password: string, confirmPassword: string): Promise<SignUpResponse | undefined> =>
  {
      const response = await this.request<SignUpResponse>("POST", "signup", { email, password, confirmPassword })
      if(response) return response.data
  }

  resetPassword = async (email: string): Promise<{ result: string} | undefined> =>
  {
    const response = await this.request<Promise<{ result: string} | undefined>>("POST", "resetpassword", { email })
    if(response) return response.data
  }

  changePassword = async (token: string, password: string, confirmPassword: string): Promise<{ result: string} | undefined> =>
  {
    const response = await this.requestWithToken<{ result: string }>(token, "POST", "resetpassword/confirm", { password, confirmPassword })
    if(response) return response.data
  }
}