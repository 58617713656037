// MUI
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import SupportAgent from '@mui/icons-material/SupportAgent';
import BackIcon from '@mui/icons-material/ArrowBackIos';

// Redux
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createChatStart, getAllChatsStart, getMyChatsStart, setCurrentChat } from '../../redux/actions/chatActions';
import { ApplicationState } from '../../interface/ApplicationState';

// Custom
import { PastHelpRequests } from './PastHelpRequests';
import { ConversationView } from './ConversationView';
import { MESSAGE_POLL_INTERVAL } from '../../const';
import Dialog from '@mui/material/Dialog';
import { Transition } from '../../animations/Transition';
import Box from '@mui/material/Box';

interface ChatWindowProps
{
  onClose: () => void
}

let pollId: any

export const ChatWindow = ({ onClose }: ChatWindowProps) =>
{

  const dispatch = useDispatch()
  const conversations = useSelector((state: ApplicationState) => state.chat.conversations)
  const currentChat = useSelector((state: ApplicationState) => state.chat.currentChat)
  const isCreatingChat = useSelector((state: ApplicationState) => state.chat.isCreatingChat)

  const user = useSelector((state: ApplicationState) => state.userStore.user)

  const [ subject, setSubject ] = useState("")

  useEffect(() => {

    getMessages()
    pollForMessages()

    return function()
    {
      clearInterval(pollId)
    }

  }, [])

  const getMessages = () =>
  {
    if(!user.isAdmin) dispatch(getMyChatsStart())
    if(user.isAdmin) dispatch(getAllChatsStart())
  }

  const pollForMessages = () =>
  {
    pollId = setInterval(() => {

      getMessages()

    }, MESSAGE_POLL_INTERVAL)

  }

  const getCurrentChat = () =>
  {
    if(currentChat) return conversations.find(c => c._id === currentChat)?.subject
    return "AlgoTrader Support Chat"
  }

  const getUser = () =>
  {
    if(currentChat) return conversations.find(c => c._id === currentChat)?.email
    return ""
  }

  const createChat = () =>
  {
    if(subject)
    {
      dispatch(createChatStart(subject))
      setSubject("")
    }
  }

  return(<Dialog open onClose={onClose} TransitionComponent={Transition} maxWidth="lg" PaperProps={{ style: { position: 'fixed', bottom: 0, left: 0 } }}>


    {/* Header View */}
    <div style={styles.header}>
      { currentChat && <IconButton onClick={() => dispatch(setCurrentChat(""))}><BackIcon /></IconButton> }

      <Box flex={1}>
        <Typography flex={1} textAlign='center' alignSelf="center" fontWeight="700" variant="subtitle1" >{getCurrentChat()}</Typography>
        <Typography flex={1} textAlign='center' alignSelf="center" fontWeight="600" variant="body2" color="textSecondary">{getUser()}</Typography>
      </Box>


      <IconButton onClick={onClose}><CloseIcon /></IconButton>
      </div>

    {/* Body */}
    <div style={styles.container}>
      { !currentChat && <PastHelpRequests conversations={conversations} isAdmin={user.isAdmin} /> }
      { currentChat && <ConversationView conversationId={currentChat} /> }
    </div>

    {/* Init chat section - only for normal users  */}
     { !currentChat && !user.isAdmin && <div style={styles.startConvoSection}>
        <TextField autoFocus value={subject} placeholder="Subject or problem description (E.G) API key issues" style={styles.subject} onChange={(e: any) => setSubject(e.target.value)}/>
        <Button variant="contained" startIcon={isCreatingChat ? <CircularProgress size={16} /> : <SupportAgent />} onClick={createChat} disabled={!subject || isCreatingChat}>Start new help request</Button>
      </div> }
        
   </Dialog>)
}

export const styles = {
  header: {
    display: 'flex' as 'flex',
    flexDirection: "row" as "row",
    aligItems: 'center' as 'center',
    marginTop: 12,
    marginLeft: 12,
    marginRight: 12,
  },
  container: {
    zIndex: 1,
    height: 600,
    width: 600,
    margin: 6,
    padding: 6,
  },
  subject: {
    marginBottom: 12,
  },
  startConvoSection: {
    margin: 12,
    display: 'flex' as 'flex',
    justifyContent: 'center' as 'center',
    flexDirection: "column" as "column",
  },
  list: {
    overflow: "auto",
    height: 530
  },
  textInput: {
  }
}