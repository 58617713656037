import { useDispatch, useSelector } from "react-redux"
import Button from '@mui/material/Button';
import { ApplicationState } from "../../interface/ApplicationState";
import { updateLocalUser } from "../../redux/actions/userActions";
import { BINANCE_COM_ENDPOINT, BINANCE_TESTNET_ENDPOINT, BINANCE_US_ENDPOINT } from "../../const";
import Grid from "@mui/material/Grid";

export const EndpointSection = () =>
{
  const dispatch = useDispatch();
  const endpoint = useSelector((state: ApplicationState) => state.userStore.user.binanceApiEndpoint)

  return(
    <Grid container spacing={2}>
      <Grid item>
      <Button variant={endpoint === BINANCE_TESTNET_ENDPOINT ? "contained" : "outlined"} value={BINANCE_TESTNET_ENDPOINT} key={1} onClick={() => dispatch(updateLocalUser({ binanceApiEndpoint: BINANCE_TESTNET_ENDPOINT }))}>TestNet</Button>
      </Grid>

      <Grid item>
      <Button variant={endpoint === BINANCE_COM_ENDPOINT ? "contained" : "outlined"} value={BINANCE_COM_ENDPOINT} key={2} onClick={() => dispatch(updateLocalUser({ binanceApiEndpoint: BINANCE_COM_ENDPOINT }))}>Binance.com</Button>
      </Grid>

      <Grid item>
      <Button variant={endpoint === BINANCE_US_ENDPOINT ? "contained" : "outlined"} value={BINANCE_US_ENDPOINT} key={3} onClick={() => dispatch(updateLocalUser({ binanceApiEndpoint: BINANCE_US_ENDPOINT }))}>Binance.US</Button>
      </Grid>
    </Grid>
  )
}

const styles = {
  container: {
    display: 'flex' as 'flex',
    flex: 1,

  }
}