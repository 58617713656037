import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormLabel from '@mui/material/FormLabel';
import { styles } from '../Pages/Settings';

interface DropdownSelectorProps
{
  items: string[];
  currentItem: string | undefined
  onChangeItem: (item: string) => void
  title: string
}

export const DropdownSelector = ({ currentItem, onChangeItem, title, items }: DropdownSelectorProps) =>
{
  return(
    <>
      <FormLabel component="legend" style={styles.formLabel}>{title}</FormLabel>
      <FormControl fullWidth>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={currentItem}
        onChange={(e) => onChangeItem(e.target.value)}>
        { items.map((i: string, key) => <MenuItem key={key} value={i}>{i}</MenuItem>)}
      </Select>
    </FormControl>
  </>
  )
}