import { BackTest } from "../interface/BackTest"

type Filter = [ BackTest[] | undefined ]

export const useFilterResults = (query: string, results: BackTest[] | undefined): Filter =>
{
  if(!query) return [ results ]

  const filteredResults = results?.filter(result => {
    if(result.indicator.toLowerCase().includes(query.toLowerCase())) return true
    if(result.marketId.toLowerCase().includes(query.toLowerCase())) return true
    if(result.candle.toLowerCase().includes(query.toLowerCase())) return true
    if(`${String(result?.rsiThresholdBuy)}/${String(result?.rsiThresholdSell)}` === query) return true

  })

  return [ filteredResults ]
}