import { useEffect, useRef, useState } from 'react';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Alert from '@mui/material/Alert';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';

// Icons
import SellIcon from '@mui/icons-material/Sell';

// API
import { SellApi } from '../../../api/SellApi';

// Interface
import { MarketSellResponse } from '../../../interface/MarketSellResponse';
import { CheckProfitResponse } from '../../../interface/CheckProfitResponse';

// Custom
import { Transition } from './Transition';
import { LinearProgress } from '@mui/material';
import { CheckProfitSection } from './CheckProfitSection';
import { SellResultSection } from './SellResultSection';

const POLL_PROFIT = 5000

interface SellModalProps
{
  open: boolean
  handleClose: () => void
  tradingPair: string
  maxSell: number
  waitForBearSignal: boolean
}

export const SellModal = ({ open, handleClose, tradingPair, maxSell, waitForBearSignal }: SellModalProps) =>
{

  const [ amountToSell, setAmountToSell ] = useState(maxSell || 0)
  const [ waitForBears, setWaitForBears ] = useState(waitForBearSignal)
  
  const [ sellLoading, setSellLoading ] = useState(false)
  const [ checkProfitLoading, setCheckProfitLoading ] = useState(false)

  const [ checkProfitResponse, setCheckProfit ] = useState<CheckProfitResponse | undefined>(undefined)
  const [ sellResponse, setSellResponse ] = useState<MarketSellResponse | undefined>(undefined)

  const [ sellError, setSellError ] = useState("")

  const pollRef = useRef<NodeJS.Timeout | undefined>()

  useEffect(() => {

    checkProfit(true)
    
    pollProfit()

    return function()
    {
      clearInterval(pollRef.current)
    }

  }, [ amountToSell ])

  const pollProfit = () =>
  {
    pollRef.current = setInterval(() => {

      checkProfit(false)

    }, POLL_PROFIT)
  }

  const checkProfit = async (refresh: boolean) =>
  {
    try
    {
      if(!amountToSell) return
      refresh && setCheckProfitLoading(true)
      const sellApi = new SellApi()
      const response = await sellApi.checkProfit(tradingPair, amountToSell)
      if(response) setCheckProfit(response)
      refresh && setCheckProfitLoading(false)
    }
    catch(error)
    {
      refresh && setCheckProfitLoading(false)
    }

  }

  const doSell = async () =>
  {
    try
    {
      setSellLoading(true)
      const sellApi = new SellApi()
      const response = await sellApi.makeMarketSell(tradingPair, amountToSell, waitForBears)
      if(response) setSellResponse(response)
      setSellLoading(false)
      setSellError("")
    }
    catch(error)
    {
      const errorCast = error as any
      setSellLoading(false)
      setSellError(getParsedError(errorCast))
    }

  }

  const getParsedError = (error: any) =>
  {
    try
    {
      if(error.message.response.data.error.code === -2010) return "Account has insufficient balance for requested action."
      if(error?.message?.response?.data?.error && !error.message.response.data.error.code) return error.message.response.data.error
      return "Looks like there was an issue executing the sale. Maybe try reduce your sale amount to ensure a successful sale."
    }
    catch(error)
    {
      return "Looks like there was an issue executing the sale. Maybe try reduce your sale amount to ensure a successful sale."
    }
  }

  return(
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
    <DialogTitle>Confirm sale ({tradingPair})</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        Are you sure you want to sell?
      </DialogContentText>

      <FormControlLabel
          style={styles.checkbox}
          control={<Checkbox checked={waitForBears} onChange={(e: any) => setWaitForBears(e.target.checked)} name="Wait for bears" />}
          label="Don't buy again until indicators see a bear signal - only check this if this is your trading pair"
          />

      { checkProfitLoading && <LinearProgress variant="indeterminate" title="checking sell profit" style={styles.loading} /> }
      { checkProfitResponse && <CheckProfitSection checkProfitResponse={checkProfitResponse} />}

      <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
               $
              </InputAdornment> )
          }}
          value={amountToSell}
          onChange={(e: any) => setAmountToSell(e.target.value)}
          inputMode="decimal"
          autoFocus
          margin="dense"
          id="amountToSell"
          label="Amount to sell (USD)"
          type="number"
          fullWidth
          variant="outlined" />

    { sellResponse && <SellResultSection sellResponse={sellResponse} />}

    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>{sellResponse ? "Close" : "Cancel"}</Button>
      <Button disabled={!!sellResponse} startIcon={sellLoading ? <CircularProgress size={24} /> : <SellIcon />} onClick={doSell}>{sellLoading ? "Selling" : "Sell"}</Button>
    </DialogActions>

    <Snackbar open={!!sellError} message={sellError} autoHideDuration={6000} onClose={() => setSellError("")}>
      <Alert severity="error" > {sellError} </Alert>
    </Snackbar>

  </Dialog>
  )
}

const styles = {
  loading: {
    marginTop: 16,
    borderRadius: 12,
  },
  checkbox: {
    marginTop: 12
  }
}