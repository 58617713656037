import { put, takeLatest } from "redux-saga/effects";
import { ChatActions, createChatSuccess, getAllChatsSuccess, getMyChatsSuccess, getUnseenMessageCountSuccess, markReadSuccess, onChatError, readConversationSuccess, removeConversationSuccess, removeMyChatSuccess, sendMessageSuccess, setCurrentChat } from "../actions/chatActions";
import { AxiosError } from "axios";
import { ChatApi } from "../../api/ChatApi";


function* createChat(action: any): any
{
  try
  {
    const response = yield new ChatApi().createChat(action.data.subject)
    yield put(createChatSuccess(response))
    yield put(setCurrentChat(response._id))
  }
  catch(error)
  {
    yield put(onChatError(error as AxiosError))
  }
}

function* startChatWithUser(action: any): any
{
  try
  {
    const response = yield new ChatApi().startChatWithUser(action.data.subject, action.data.destinationUserId)
    yield put(createChatSuccess(response))
    yield put(setCurrentChat(response._id))
  }
  catch(error)
  {
    yield put(onChatError(error as AxiosError))
  }
}

function* readConversation(action: any): any
{
  try
  {
    const response = yield new ChatApi().readConversation(action.data.conversationId)
    yield put(readConversationSuccess(response))
  }
  catch(error)
  {
    yield put(onChatError(error as AxiosError))
  }
}

function* sendMessage(action: any): any
{
  try
  {
    const response = yield new ChatApi().sendMessage(action.data.conversationId, action.data.from, action.data.message)
    yield put(sendMessageSuccess(response))
  }
  catch(error)
  {
    yield put(onChatError(error as AxiosError))
  }
}

function* getMyChats(): any
{
  try
  {
    const response = yield new ChatApi().getMyChats()
    yield put(getMyChatsSuccess(response))
  }
  catch(error)
  {
    yield put(onChatError(error as AxiosError))
  }
}

function* markRead(action: any): any
{
  try
  {
    const response = yield new ChatApi().markRead(action.data.conversationId)
    yield put(markReadSuccess(response))
  }
  catch(error)
  {
    yield put(onChatError(error as AxiosError))
  }
}

function* getNotificationCount(): any
{
  try
  {
    const response = yield new ChatApi().getNotificationCount()
    yield put(getUnseenMessageCountSuccess(response.messages))
  }
  catch(error)
  {
    yield put(onChatError(error as AxiosError))
  }
}

function* removeConversation(action: any): any
{
  try
  {
    const response = yield new ChatApi().removeConversation(action.data.conversationId)
    yield put(removeConversationSuccess(response._id))
  }
  catch(error)
  {
    yield put(onChatError(error as AxiosError))
  }
}

function* removeMyChat(action: any): any
{
  try
  {
    const response = yield new ChatApi().removeMyChat(action.data.conversationId)
    yield put(removeMyChatSuccess(response._id))
  }
  catch(error)
  {
    yield put(onChatError(error as AxiosError))
  }
}

function* getAllChats(): any
{
  try
  {
    const allChats = yield new ChatApi().getAll()
    yield put(getAllChatsSuccess(allChats))
  }
  catch(error)
  {
    yield put(onChatError(error as AxiosError))
  }
}

export function* chatSaga()
{
  // User
  yield takeLatest(ChatActions.CREATE_CHAT_START, createChat)
  yield takeLatest(ChatActions.CHAT_WITH_USER_START, startChatWithUser)

  yield takeLatest(ChatActions.READ_CONVERSATION_START, readConversation)
  yield takeLatest(ChatActions.SEND_MESSAGE_START, sendMessage)
  yield takeLatest(ChatActions.GET_ALL_MY_CHATS_START, getMyChats)
  yield takeLatest(ChatActions.REMOVE_MY_CHAT_START, removeMyChat)
  yield takeLatest(ChatActions.GET_UNSEEN_MESSAGE_COUNT_START, getNotificationCount)
  yield takeLatest(ChatActions.MARK_READ_SUCCESS, getNotificationCount)

  // Admin
  yield takeLatest(ChatActions.MARK_READ_BEGIN, markRead)
  yield takeLatest(ChatActions.REMOVE_CONVERSATION_START, removeConversation)
  yield takeLatest(ChatActions.GET_ALL_CHATS_START, getAllChats)

}