import { AxiosError } from "axios"
import { Stripe } from "stripe"
import { SubscriptionStatus } from "../../interface/SubscriptionStatus"

export enum BillingActions
{
  CREATE_USER_BEGIN = "CREATE_USER_BEGIN",
  CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS",
  CREATE_USER_ERROR = "CREATE_USER_ERROR",
  CREATE_USER_CLEAR = "CREATE_USER_CLEAR",

  GET_SUBSCRIPTION_BEGIN = "GET_SUBSCRIPTION_BEGIN",
  GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS",
  GET_SUBSCRIPTION_ERROR = "GET_SUBSCRIPTION_ERROR",

  GET_SUBSCRIPTION_STATUS_BEGIN = "GET_SUBSCRIPTION_STATUS_BEGIN",
  GET_SUBSCRIPTION_STATUS_SUCCESS = "GET_SUBSCRIPTION_STATUS_SUCCESS",
  GET_SUBSCRIPTION_STATUS_ERROR = "GET_SUBSCRIPTION_STATUS_ERROR",

  ADD_CARD_BEGIN = "ADD_CARD_BEGIN",
  ADD_CARD_SUCCESS = "ADD_CARD_SUCCESS",
  ADD_CARD_ERROR = "ADD_CARD_ERROR",
  ADD_CARD_CLEAR = "ADD_CARD_CLEAR",

  ADD_SUBSCRIPTION_BEGIN = "ADD_SUBSCRIPTION_BEGIN",
  ADD_SUBSCRIPTION_SUCCESS = "ADD_SUBSCRIPTION_SUCCESS",
  ADD_SUBSCRIPTION_ERROR = "ADD_SUBSCRIPTION_ERROR",
  ADD_SUBSCRIPTION_CLEAR = "ADD_SUBSCRIPTION_CLEAR",

  START_NEW_SETUP = "START_NEW_SETUP",
  RESUME_SETUP = "RESUME_SETUP"

}

export const createUserBegin = () =>
{
  return {
    type: BillingActions.CREATE_USER_BEGIN,
  }
}

export const createUserSuccess = (response: Stripe.Response<Stripe.Customer>) =>
{
  return {
    type: BillingActions.CREATE_USER_SUCCESS,
    data: response,
  }
}

export const createUserError = (error: AxiosError) =>
{
  return {
    type: BillingActions.CREATE_USER_ERROR,
    error,
  }
}

export const createUserClear = () =>
{
  return {
    type: BillingActions.CREATE_USER_CLEAR,
  }
}

export const getSubscriptionStatusBegin = () =>
{
  return {
    type: BillingActions.GET_SUBSCRIPTION_STATUS_BEGIN,
  }
}

export const getSubscriptionStatusSuccess = (response: SubscriptionStatus) =>
{
  return {
    type: BillingActions.GET_SUBSCRIPTION_STATUS_SUCCESS,
    data: response
  }
}

export const getSubscriptionStatusError = (error: AxiosError) =>
{
  return {
    type: BillingActions.GET_SUBSCRIPTION_STATUS_ERROR,
    error,
  }
}


export const getSubscriptionBegin = () =>
{
  return {
    type: BillingActions.GET_SUBSCRIPTION_BEGIN,
  }
}

export const getSubscriptionSuccess = (response: Stripe.Response<Stripe.ApiList<Stripe.Subscription>>) =>
{
  return {
    type: BillingActions.GET_SUBSCRIPTION_SUCCESS,
    data: response
  }
}

export const getSubscriptionError = (error: AxiosError) =>
{
  return {
    type: BillingActions.GET_SUBSCRIPTION_ERROR,
    error,
  }
}

export const addCardBegin = () =>
{
  return {
    type: BillingActions.ADD_CARD_BEGIN,
  }
}

export const addCardSuccess = (response: Stripe.Response<Stripe.Customer>) =>
{
  return {
    type: BillingActions.ADD_CARD_SUCCESS,
    data: response
  }
}

export const addCardError = (error: AxiosError) =>
{
  return {
    type: BillingActions.ADD_CARD_ERROR,
    error,
  }
}

export const addCardClear = () =>
{
  return {
    type: BillingActions.ADD_CARD_CLEAR,
  }
}

export const addSubscriptionBegin = () =>
{
  return{
    type: BillingActions.ADD_SUBSCRIPTION_BEGIN,
  }
}

export const addSubscriptionSuccess = (response: Stripe.Response<Stripe.Subscription>) =>
{
  return{
    type: BillingActions.ADD_SUBSCRIPTION_SUCCESS,
    data: response
  }
}

export const addSubscriptionError = (error: AxiosError) =>
{
  return{
    type: BillingActions.ADD_SUBSCRIPTION_ERROR,
    error
  }
}

export const addSubscriptionClear = () =>
{
  return {
    type: BillingActions.ADD_SUBSCRIPTION_CLEAR,
  }
}

export const startSetup = (email: string, name: string, productId: string, cvc: string, expMonth: string, expYear: string, cardNumber: string) =>
{
  return {
    type: BillingActions.START_NEW_SETUP,
    data: { email, name, productId, cvc, expMonth, expYear, cardNumber }
  }
}

export const resumeSetup = (productId: string, cvc: string, expMonth: string, expYear: string, cardNumber: string) =>
{
  return {
    type: BillingActions.RESUME_SETUP,
    data: { productId, cvc, expMonth, expYear, cardNumber }
  }
}