import { OcoOrder } from "../interface/OcoOrder";
import { OrderItem } from "../interface/OrderItem";
import { QueryOrderResult } from "../interface/QueryOrderResult";
import { HttpService } from "./HttpService";

export class BtcMarkets extends HttpService
{
  getOrders = async (marketId: string, side: "Bid" | "Ask" | undefined): Promise<OrderItem[] | undefined> =>
  {
    const response = await this.request<OrderItem[]>("GET", `btcMarkets/orders?marketId=${marketId}&side=${side}`)
    if(response) return response.data
  }

  getOrder = async (marketId: string, orderId: number): Promise<QueryOrderResult | undefined> =>
  {
    const response = await this.request<QueryOrderResult>("GET", `btcMarkets/order?marketId=${marketId}&orderId=${orderId}`)
    if(response) return response.data
  }

  getOCOorders = async (): Promise<OcoOrder[] | undefined> =>
  {
    const response = await this.request<OcoOrder[]>("GET", `btcMarkets/ocoorders`)
    if(response) return response.data
  }

  removeOcoOrder = async (marketId: string, orderId: string, ocoOrderId: number): Promise<{ deletedOrder: boolean, deletedOco: boolean } | undefined> =>
  {
    const response = await this.request<{ deletedOrder: boolean, deletedOco: boolean }>("DELETE", `btcMarkets/ocoorder?marketId=${marketId}&orderId=${orderId}&ocoOrderId=${ocoOrderId}`)
    if(response) return response.data
  }
}