import { UserSubscription } from "../../interface/User"
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ReactCountryFlag from "react-country-flag"
import AndroidIcon from '@mui/icons-material/Android'
import AppleIcon from '@mui/icons-material/Apple'

import DesktopIcon from '@mui/icons-material/DesktopMac'


// Theme
import useTheme from "@mui/material/styles/useTheme";

// Util
import moment from 'moment'
import KeyOutlined from "@mui/icons-material/KeyOutlined";
import { useGravatar } from "../../components/modals/UserDetails/hooks/useGravatar";


interface UserRowItemProps
{
  user: UserSubscription
  setOpenModal: (user: UserSubscription) => void
}

export const UserRowItem = ({ user, setOpenModal }: UserRowItemProps) =>
{
  const theme = useTheme()
  const { gravatarImage } = useGravatar(100, user?.username)


  const getDaysLeftOfTrial = (user: UserSubscription) =>
  {
    const daysLeft = moment(user.createdAt).diff(moment().subtract(30, 'days'), "day")
    if(daysLeft > 0) return daysLeft
    return 0
  }

  const getKeyColor = (key1?: string, key2?: string) =>
  {    
    if(!key1 || !key2) return "error" 
    if(key1.length < 64 || key2.length < 64) return "warning" 
    if(key1.length === 64 && key2.length === 64) return "success"
  }

  return(
    <TableRow key={user._id} hover onClick={() => setOpenModal(user)}>
    <TableCell component="th" scope="row">
    {/* Username */}
    <div style={styles.username}>
      <Badge 
        badgeContent={getDaysLeftOfTrial(user)} 
        color="secondary"
        overlap="circular" 
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
        <Avatar src={gravatarImage} style={{ ...styles.avatar, backgroundColor: theme.palette.primary.main }}>{user && user.username && user.username[0].toUpperCase()}</Avatar>
     </Badge>

   <KeyOutlined sx={{ mr: 1 }} color={getKeyColor(user.binanceApiKey, user.binanceApiSecret)} fontSize="small" />
      
  <Typography>{user.username}</Typography>


    </div>
    </TableCell>

    {/* Created At */}
    <TableCell align="right" scope="row">
      { user.createdAt ? <Tooltip title={moment(user.createdAt).format("lll")}>
        <Typography>{moment(user.createdAt).fromNow()}</Typography>
      </Tooltip> : "Never" }
    </TableCell>

    <TableCell align="right" scope="row">
        { user.deviceType && user.deviceType === "android" && <AndroidIcon /> }
        { user.deviceType && user.deviceType === "ios" && <AppleIcon /> }
        { !user.deviceType && <DesktopIcon /> }

    </TableCell>

    {/* Last Login */}
    <TableCell align="right" scope="row">
      { user.lastLogin ? <Tooltip title={moment(user.lastLogin).format("lll")}>
        <Typography >{moment(user.lastLogin).fromNow()}</Typography>
      </Tooltip> : "Never" }
    </TableCell>

    {/* Last Login */}
        <TableCell align="right" scope="row" style={{   textOverflow: "ellipsis" }}>
          <Tooltip title={`${user.lastLoginIP} (${user.lastLoginLocation?.country}, ${user.lastLoginLocation?.city})`}>
            <div>
            { user.lastLoginLocation && user.lastLoginLocation.countryCode ?
            <ReactCountryFlag 
            svg
            style={styles.flag}
            countryCode={user.lastLoginLocation.countryCode} /> : <Typography>Unknown</Typography> }
            </div>
          </Tooltip>
    </TableCell>

    {/* Trading Pair */}
    <TableCell align="right" scope="row">{user.tradingPair}</TableCell>

    {/* Billing CustomerId */}
    <TableCell align="right" scope="row">{user.stripeCustomerId}</TableCell>

    {/* Billing Status */}
    <TableCell align="right" scope="row">
    <div style={{ backgroundColor: user.isSubscriptionActive ? "#42A5F5" : "#9E9E9E", borderRadius: 12, textAlign: 'center', padding: 2 }}>
      {user.isSubscriptionActive ? "Active" : "Inactive"}
    </div>
  </TableCell>
      

  {/* Last Payment */}
    <TableCell align="right" scope="row">{user.lastBill ? moment(user.lastBill * 1000).format("lll") : "-"}</TableCell>

  {/* Next Payment */}
  <TableCell align="right" scope="row">{user.nextBill ? moment(user.nextBill * 1000).format("lll") : "-" }</TableCell>

  {/* Trading Enabled */}
  <TableCell align="right" scope="row">

    <div style={{ backgroundColor: user.tradingEnabled ? "#4CAF50" : "#EF5350", borderRadius: 12, textAlign: 'center', padding: 2 }}>
      {user.tradingEnabled ? "Enabled" : "Disabled"}
    </div>
  
    </TableCell>

  </TableRow>
  )
}


const styles = {
  avatar: {
    marginRight: 12,
  },
  username: {
    display: 'flex' as 'flex',
    flexDirection: "row" as "row",
    alignItems: 'center' as 'center'
  },
  flag: {
    width: 40,
    height: 40 

  }
}