import { Line, YAxis, XAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart } from 'recharts';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment'
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { LogApi } from '../../api/LogApi';
import { useState } from 'react';
import { useEffect } from 'react';
import { VolumeProfileItem } from '../../interface/VolumeProfileItem';

interface VolumeProfileChartProps
{
  tradingPair: string;
  chartInterval: string;
  bars: number;
}


export const VolumeProfileChart = ({ tradingPair, chartInterval, bars }: VolumeProfileChartProps) =>
{

  const [ vp, setVp ] = useState<VolumeProfileItem[] | undefined>(undefined)
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    getVP()
  }, [ chartInterval, tradingPair ])


  const getVP = async () =>
  {
    try
    {
      setLoading(true)
      if(tradingPair)
      {
        const response = await new LogApi().getVolumeProfile(tradingPair, chartInterval, bars)
        setVp(response)
      }
      setLoading(false)
    }
    catch(error)
    {
      setLoading(false)
      return []
    }
    
  }


  return(
   !loading ? <Card style={styles.container}>
    <Typography color="textSecondary">[{tradingPair}] Volume Profile {chartInterval}</Typography>
      <ResponsiveContainer width="100%" height={324}>
      <ComposedChart
        width={100}
        height={500}
        data={vp || []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >

        <YAxis yAxisId="left"  />
        <YAxis yAxisId="right" domain={['dataMin', 'dataMax']} orientation="right" />
        <XAxis dataKey="timestamp" tickFormatter={(ts: string) => moment(ts).format("DD-MMM hh:mmA")} minTickGap={100}  />
        
        <Legend formatter={(e) => {
          if(e === "bearishVolume") return `Bearish Volume`
          if(e === "bullishVolume") return `Bullish Volume`
          if(e === "rangeEnd") return `Range End`
          if(e === "rangeStart") return "Range Start"
          if(e === "totalVolume") return "Total Volume"
          if(e === "poc") return "Point of Control"

        }} />

        <Line yAxisId="left" type="monotone" dataKey="bearishVolume" stroke="#EC407A" dot={false} strokeWidth={3} />
        <Line yAxisId="left" type="monotone" dataKey="bullishVolume" stroke="#4CAF50" dot={false} strokeWidth={3} />
        <Line yAxisId="left" type="monotone" dataKey="totalVolume" stroke="#FF9800" dot={false} strokeWidth={3} />

        <Line yAxisId="right" type="monotone" dataKey="rangeEnd" stroke="#3949AB" dot={false} strokeWidth={3} />
        <Line yAxisId="right" type="monotone" dataKey="rangeStart" stroke="#42A5F5" dot={false} strokeWidth={3} />
        <Line yAxisId="right" type="monotone" dataKey="poc" stroke="#9E9E9E" dot={false} strokeWidth={3} />


        <Tooltip
        labelStyle={{ color: "grey" }} 
        labelFormatter={(ts) => `${moment(ts).format("DD-MMM hh:mmA")} - ${moment(ts).fromNow()}`}
        formatter={(tick, itemName) => {

          if(itemName === "poc") return `Point of Control: $${Number(tick).toFixed(2)}`
          return `$${Number(tick).toFixed(2)} USDT/TUSD`
          
          }}/>

      </ComposedChart>
      </ResponsiveContainer>
    </Card> : <div style={styles.loader}><CircularProgress /></div>

  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    padding: 12,
    fontSize: 10,
    margin: 8,
    textAlign: 'center' as 'center'
  },
  loader: {
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}