import { IRunEvent } from "../../interface/IRunEvent"
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import moment from 'moment'
import { Typography } from "@mui/material";
interface LogStatusItemProps
{
  runEvent: IRunEvent
}

export const LogStatusItem = ({ runEvent }: LogStatusItemProps) =>
{
  return(
    <TableRow key={runEvent._id}>
    <TableCell >{runEvent.didBuy ? "BUY" : "SELL"}</TableCell>
    <TableCell >{runEvent.marketId}</TableCell>
    <TableCell >{runEvent.didSell ? runEvent.sellResult : runEvent.buyResult }</TableCell>
    <TableCell align="right">{moment(runEvent.timestamp).fromNow()} at {moment(runEvent.timestamp).format("h:mmA")} </TableCell>
    <TableCell align="right">{runEvent.amount}</TableCell>
    <TableCell align="right">{runEvent.lastPrice}</TableCell>
    <TableCell align="right">
    {
     runEvent.didSell ? <div style={{ backgroundColor: runEvent.profit > 0 ? "#66BB6A" : "#EF5350", borderRadius: 12, textAlign: 'center' }}>
        <Typography variant="subtitle2">${runEvent.profit} USD</Typography>
      </div> : "-"
    }
    </TableCell>
  </TableRow>
  )
}
