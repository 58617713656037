
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"

interface UserDetailsProps
{
  email: string;
  name: string
  setEmail: (email: string) => void
  setName: (name: string) => void
}

export const UserDetails = ({ setEmail, setName, email, name }: UserDetailsProps) =>
{

  return(<div style={styles.container}>

    <Grid container spacing={2} >

    <Grid item container flexDirection="row" spacing={2}>
        <Grid item flex={1}>
          <TextField fullWidth placeholder="Name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </Grid>

        <Grid item flex={1}>
        <TextField fullWidth placeholder="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      </Grid>

      </Grid>

    

    </Grid>
  </div>)
}

const styles = {
  container: {
    marginBottom: 24,
  }
}