import { AccountInfo } from "../interface/AccountInfo";
import { CheckProfitResponse } from "../interface/CheckProfitResponse";
import { IRunEvent } from "../interface/IRunEvent";
import { MarketSellResponse } from "../interface/MarketSellResponse";
import { ProfitSummary } from "../interface/ProfitSummary";
import { SignalsInvestmentsResponse } from "../interface/SignalInvestmentsResponse";
import { User, UserSubscription } from "../interface/User";
import { UserStatsResponse } from "../interface/UserStatsResponse";
import { HttpService } from "./HttpService";

export class AdminApi extends HttpService
{
  listUsers = async (skip?: number, limit?: number, query?: string, filterType?: string): Promise<UserSubscription[] | undefined> =>
  {
    const response = await this.request<UserSubscription[]>("GET", `admin/users?skip=${skip}&limit=${limit}&search=${query}&filterType=${filterType}`)
    if(response) return response.data
  }

  userStats = async (): Promise<UserStatsResponse | undefined> =>
  {
    const response = await this.request<UserStatsResponse>("GET", `admin/userStats`)
    if(response) return response.data
  }

  userDetails = async (userId: string | undefined): Promise<User | undefined> =>
  {
    const response = await this.request<User>("GET", `admin/userDetails?userId=${userId}`)
    if(response) return response.data
  }

  getRunEventsForUser = async (userId: string | undefined): Promise<IRunEvent[] | undefined> =>
  {
    const response = await this.request<IRunEvent[]>("GET", `admin/userRunEvents?userId=${userId}`)
    if(response) return response.data
  }

  updateUser = async (userId: string, user: User): Promise<User | undefined> =>
  {
    const response = await this.request<User>("POST", `admin/user?userId=${userId}`, user)
    if(response) return response.data
  }

  deleteUser = async (userId: string): Promise<{ result: string } | undefined> =>
  {
    const response = await this.request<{ result: string }>("DELETE", `admin/user?userId=${userId}`)
    if(response) return response.data
  }

  createUser = async (): Promise<User | undefined> =>
  {
    const response = await this.request<User>("POST", `admin/create`)
    if(response) return response.data
  }

  getUserProfit = async (userId: string): Promise<ProfitSummary[] | undefined> =>
  {
    const response = await this.request<ProfitSummary[]>("GET", `admin/profit?userId=${userId}`)
    if(response) return response.data
  }

  sendOnboardEmail = async (email: string): Promise<{result: string} | undefined> =>
  {
    const response = await this.request<{result: string}>("POST", `admin/onboard?email=${email}`)
    if(response) return response.data
  }

  getStripeProfit = async () =>
  {
    const response = await this.request<ProfitSummary[] | undefined>("GET", `admin/stripestats`)
    if(response) return response.data
  }

  getAccountInfo = async (userId: string) =>
  {
    const response = await this.request<AccountInfo | undefined>("GET", `admin/accountinfo?userId=${userId}`)
    if(response) return response.data
  }

  checkProfitForUser = async (userId: string, tradingPair: string, amountToSell: number): Promise<CheckProfitResponse | undefined> =>
  {
    const response = await this.request<CheckProfitResponse>("GET", `admin/checkProfitForUser?userId=${userId}&tradingPair=${tradingPair}&amountToSell=${amountToSell}`)
    if(response) return response.data
    return undefined
  }

  sellAssetForUser = async (userId: string, tradingPair: string, amountToSell: number, sellReason?: string, createRunEvent?: boolean): Promise<MarketSellResponse | undefined> =>
  {
    const response = await this.request<MarketSellResponse>("POST", `admin/sellAssetForUser?userId=${userId}&tradingPair=${tradingPair}&amountToSell=${amountToSell}&sellReason=${sellReason}&createRunEvent=${createRunEvent}`)
    if(response) return response.data
    return undefined
  }

  getSignalInvestmentStats = async (): Promise<SignalsInvestmentsResponse | undefined> =>
  {
    const response = await this.request<SignalsInvestmentsResponse>("GET", `admin/signalStats`)
    if(response) return response.data
    return undefined
  }
}