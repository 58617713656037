import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"

import SendIcon from '@mui/icons-material/Send'
import { styles } from "./ChatWindow"
import { MessagesView } from "./MessagesView"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { markReadBegin, readConversationStart, sendMessageStart } from "../../redux/actions/chatActions"
import { ApplicationState } from "../../interface/ApplicationState"
import CircularProgress from "@mui/material/CircularProgress"

interface ConversationViewProps
{
  conversationId: string
}

export const ConversationView = ({ conversationId }: ConversationViewProps) =>
{
  const dispatch = useDispatch()
  const conversations = useSelector((state: ApplicationState) => state.chat.conversations)
  const isSendingMessage = useSelector((state: ApplicationState) => state.chat.isSendingMessage)
  const user = useSelector((state: ApplicationState) => state.userStore.user)
  const [ message, setMessage ] = useState("")

  const useCurrent = () => conversations.find(c => c._id === conversationId)
  const currentChat = useCurrent()

  const handleSend = () =>
  {
    if(message)
    {
      dispatch(sendMessageStart(conversationId, user.isAdmin ? "Support" : user.username || user.email || user.name || "Unknown user", message))
      setMessage("")
    }
  }

  const handleEnter = (e: any) =>
  {
    if(e.keyCode === 13) handleSend()
  } 

  useEffect(() => {

    dispatch(readConversationStart(conversationId))

  }, [])

  return(
    <div>
      <MessagesView messageThread={currentChat?.conversation} />

      <TextField
        onClick={() => dispatch(markReadBegin(conversationId))} // When we click into the input then mark the convo as read
        autoFocus
        onKeyDown={handleEnter}
        InputProps={{
          endAdornment: <IconButton disabled={isSendingMessage || !message} onClick={handleSend}><SendIcon /></IconButton>,
          startAdornment: isSendingMessage && <CircularProgress size={24} sx={{ m: 1 }} />

        }}
        onChange={(e: any) => setMessage(e.target.value)}
        fullWidth 
        value={message}
        placeholder={isSendingMessage ? "Sending message..." : 'Type a message' }
        style={styles.textInput} />
    </div>
   
  )
}