import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { SignalItem } from '../../interface/SignalItem'
import Card from '@mui/material/Card'

interface BacktestingSectionProps
{
  signal: SignalItem
}

export const BacktestingSection = ({ signal }: BacktestingSectionProps) =>
{

  return(
    <Card style={styles.container}>

    <Typography variant="subtitle2" color="textSecondary"> Backtesting Results</Typography>
    
    <Grid container>

      {/* // Row 1 */}
      <Grid item container spacing={0} alignItems="center">

          <Grid item xs={6}>

            <Typography style={styles.text} color="textSecondary" variant="caption">Amount</Typography>
            <Typography style={styles.text} color="textPrimary" variant="body2">{signal.backtest?.amount ? `$${signal.backtest?.amount?.toFixed(2)}` : "-"}</Typography>

          </Grid>

          <Grid item xs={6}>

            <Typography style={styles.text} color="textSecondary" variant="caption">Win Percent</Typography>
            <Typography  style={styles.text}color="textPrimary" variant="body2">{signal.backtest?.percentage ? `${signal.backtest?.percentage?.toFixed(2)}%` : "-"}</Typography>

          </Grid>

      </Grid>

      <Grid item container>

        <Grid item xs={6}>

          <Typography style={styles.text} color="textSecondary" variant="caption">Result</Typography>
          <Typography style={styles.text} color="textPrimary" variant="body2">{signal.backtest?.result ? `${signal.backtest?.result}` : "-"}</Typography>

        </Grid>

        <Grid item xs={6}>

          <Typography style={styles.text} color="textSecondary" variant="caption">Duration</Typography>
          <Typography style={styles.text} color="textPrimary" variant="body2">{signal.backtest?.time_complete ? `${signal.backtest?.time_complete} hours` : "-"} </Typography>

        </Grid>

        </Grid>


    </Grid>
    </Card>
  )
}

const styles = {
  container: {
    padding: 6,
  },
  text: {
    fontSize: 10
  }
}