import { colors } from "@mui/material"

export const getSliderColor = (threshold: number | undefined) =>
{
  if(!threshold) return colors.blue[400]
  if(threshold === 0) return colors.blue[500]
  if(threshold > 0) return colors.green[400]
  if(threshold < 0) return colors.red[400]


  return colors.blue[400]

}