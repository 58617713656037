import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';

// MUI
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// Icons
import CogIcon from '@mui/icons-material/Settings';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionStatusBegin } from '../../redux/actions/billingActions';

// Model
import { ApplicationState } from '../../interface/ApplicationState';

// Misc
import moment from 'moment';

// Custom
import { ManageSubscriptionModal } from './ManageSubscriptionModal';
import { SubscriptionModal } from './SubscriptionModal';
import { SubscriptionSkeleton } from './SubscriptionSkeleton';

export const SubscriptionSection = () =>
{
  const dispatch = useDispatch()

  // Redux State
  const user = useSelector((state: ApplicationState) => state.userStore.user)
  const subscriptionStatus = useSelector((state: ApplicationState) => state.billing.subscriptionStatus);
  const isGettingSubscriptions = useSelector((state: ApplicationState) => state.billing.isGettingSubscriptions);
  const subscriptionsError = useSelector((state: ApplicationState) => state.billing.subscriptionsError);

  // Local State
  const [ openModal, setOpenModal ] = useState(false)
  const [ openManageModal, setOpenManageModal ] = useState(false)

  const theme = useTheme()
  const cardBackGroundColor = user.isDarkModeOn ? theme.palette.grey[900] : theme.palette.background.paper

  useEffect(() => {

    dispatch(getSubscriptionStatusBegin())

  }, [])


  const getSubscriptionStatus = () =>
  {
    try
    {
      // @ts-ignore
      if(subscriptionsError && subscriptionsError.message.response.data.error) return subscriptionsError.message.response.data.error
      if(subscriptionStatus?.isActive && subscriptionStatus?.mode === "admin") return "Active (Admin User)"
      if(subscriptionStatus?.isActive && subscriptionStatus?.mode === "paid") return "Active (Paid membership)"
      if(subscriptionStatus?.isActive && subscriptionStatus?.mode === "trial") return "Active (Free full feature trial, includes webapp, no ads, 5 trading pairs) Reverts to free mode after trial period (Ads, no webapp and only 1 trading pair)"
      if(!subscriptionStatus?.isActive && subscriptionStatus?.mode === "free") return "Active (Free) - includes ads, no webapp access and only 1 trading pair"
      return "Inactive"
    }
    catch(error)
    {
      return "Unknown"
    }
  }

  const handleCloseModal = () =>
  {
    setOpenModal(false)
    dispatch(getSubscriptionStatusBegin())

  }

  const handleCloseSubscriptionModal = () =>
  {
    setOpenManageModal(false)
    dispatch(getSubscriptionStatusBegin())
  }

  return(
    <Card style={{ backgroundColor: cardBackGroundColor, borderRadius: 12, margin: 12, padding: 16 }} >

    { isGettingSubscriptions ? <SubscriptionSkeleton /> :
    <>
      <Grid item>
        <Typography>Subscription Status: {getSubscriptionStatus()}</Typography>
      </Grid>


        { subscriptionStatus?.isActive && subscriptionStatus.mode === "paid" && <> 
          <Typography variant="subtitle2" color="textSecondary">Last Payment: {moment(subscriptionStatus.lastBill).format('ll')} ({moment(subscriptionStatus.lastBill).fromNow()})</Typography>
          <Typography variant="subtitle2" color="textSecondary">Next Payment: {moment(subscriptionStatus.nextBill).format('ll')} ({moment(subscriptionStatus.nextBill).fromNow()})</Typography>
        </>}

        { subscriptionStatus?.mode !== "paid" && <Button onClick={() => setOpenModal(true)} startIcon={<CogIcon />}>Setup Subscription</Button> }
        {/* // Allow paid users to manage their membership */}
        { subscriptionStatus?.isActive && subscriptionStatus?.mode === "paid" && <Button onClick={() => setOpenManageModal(true)} startIcon={<CogIcon />}>Manage Subscription</Button> }

      </>
  }

    { openModal && <SubscriptionModal open={openModal} handleClose={handleCloseModal} user={user}/> }
    <ManageSubscriptionModal open={openManageModal} handleClose={handleCloseSubscriptionModal} user={user}/>

      
    </Card>
  )
}