import { Line, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart, XAxis} from 'recharts';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

import { LogApi } from '../../api/LogApi';
import { useState } from 'react';
import { useEffect } from 'react';
import { TripleEma } from '../../interface/EMASMA';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { CustomizedDotSell, CustomizedDotBuy } from '../CustomDot';
import { useLogs } from '../../hooks/useLogs';

interface TripleEmaChartProps
{
  chartInterval: string;
  tradingPair: string;
  emaShortPeriod: number;
  emaMediumPeriod: number;
  emaLongPeriod: number;
  volumeShort: number;
  volumeLong: number;
}


export const TripleEmaChart = ({ chartInterval, tradingPair, emaShortPeriod, emaMediumPeriod, emaLongPeriod, volumeShort, volumeLong }: TripleEmaChartProps) =>
{

  const [ emaSma, setEmaSma ] = useState<TripleEma[] | undefined>(undefined)
  const [ loading, setLoading ] = useState(false)

  const { findLogAtCandle, getLogs } = useLogs()

  useEffect(() =>
  {
    combineLogsAndChart(true)
  }, [])

  useEffect(() =>
  {
    combineLogsAndChart(false)
  }, [chartInterval])

  const getEmaSma = async () =>
  {
    try
    {
      if(tradingPair && chartInterval) return new LogApi().getTripleEma(tradingPair, chartInterval, emaShortPeriod, emaMediumPeriod, emaLongPeriod, volumeShort, volumeLong)
    }
    catch(error)
    {
      return []
    }

  }

  const combineLogsAndChart = async (load: boolean) =>
  {  
    try
    {
      load && setLoading(true)
      const combinedChart: TripleEma[] = []
      let fromTime = new Date().toISOString();
      const chartData = await getEmaSma()
      if(chartData)
      {
        fromTime = chartData[0].timestamp;
        await getLogs(fromTime)

        for(const candle of chartData)
        {
          // Find log that corresponds to the candle
          const foundLog = findLogAtCandle(candle)
          if(foundLog && foundLog.didBuy) candle.didBuy = foundLog.lastPrice
          if(foundLog && foundLog.didSell) candle.didSell = foundLog.lastPrice
          combinedChart.push(candle)
        }
      }
      load && setLoading(false)
      setEmaSma(combinedChart)
    }
    catch(error)
    {
      load && setLoading(false)  
    }

  }

  return(
  !loading ? <Card style={styles.container}>
    <Typography color="textSecondary">[{tradingPair}] Triple EMA Crossover Chart Interval: {chartInterval}</Typography>
      <ResponsiveContainer width="100%" height={324}>
      <ComposedChart
        width={100}
        height={500}
        data={emaSma || []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <YAxis yAxisId="left" tickFormatter={(tick) => `$${tick}`} domain={['dataMin', 'dataMax']} orientation="left"/> 
        <YAxis yAxisId="right" domain={['dataMin', 'dataMax']} orientation="right" />
        <XAxis dataKey="timestamp" tickFormatter={(ts: number) => moment(ts).format("DD-MMM hh:mmA")} minTickGap={100} />

        <Legend formatter={(e) => {
          if(e === "shortEma") return `Short EMA Period (${emaShortPeriod})`
          if(e === "mediumEma") return `Medium EMA Period (${emaMediumPeriod})`
          if(e === "longEma") return `Long EMA Period (${emaLongPeriod})`
          if(e === "close") return "Close Price"
          if(e === "didSell") return "Sell"
          if(e === "didBuy") return "Buy"
          if(e === "shortEmaVolume") return "Short EMA Volume"
          if(e === "longEmaVolume") return "Long Ema Volume"

        }} />
        <Line  yAxisId="left" type="monotone" dataKey="shortEma" stroke="#E91E63" dot={false} strokeWidth={2} />
        <Line  yAxisId="left" type="monotone" dataKey="mediumEma" stroke="#03A9F4" dot={false} strokeWidth={2} />
        <Line  yAxisId="left" type="monotone" dataKey="longEma" stroke="#4CAF50" dot={false} strokeWidth={2} />
        <Line  yAxisId="left" type="monotone" dataKey="close" stroke="#FFB300" dot={false} strokeWidth={1} />

        <Line  yAxisId="right" type="monotone" dataKey="shortEmaVolume" stroke="#00796B" dot={false} strokeWidth={2} />
        <Line  yAxisId="right" type="monotone" dataKey="longEmaVolume" stroke="#3949AB" dot={false} strokeWidth={2} />

        <Line  yAxisId="left" type="monotone" dataKey="didSell" dot={<CustomizedDotSell />}  />
        <Line  yAxisId="left" type="monotone" dataKey="didBuy" dot={<CustomizedDotBuy />}  />


        <Tooltip 
          formatter={(tick, itemName, data) => {

            const { longEma, shortEma, mediumEma } = data.payload

            if(itemName === "shortEmaVolume") return `Short EMA Volume: ${Number(tick).toFixed(2)}`
            if(itemName === "longEmaVolume") return `Long EMA Volume: ${Number(tick).toFixed(2)}`

            if(itemName === "didSell") return `Sold at price ${Number(tick).toFixed(2)} USD}`
            if(itemName === "didBuy") return  `Bought at price ${Number(tick).toFixed(2)} USD`

            if(shortEma > mediumEma && shortEma > longEma && mediumEma > longEma) return `Bullish $${Number(tick).toFixed(2)} USD (Should Buy)`
            if(shortEma < mediumEma && shortEma < longEma && mediumEma < longEma) return `Bearish $${Number(tick).toFixed(2)} USD (Should Sell)`

            return `$${Number(tick).toFixed(2)} USD (HOLD)`

          }} 
          labelStyle={{ color: "grey"}} 
          labelFormatter={ts => `${moment(ts).format("DD-MMM hh:mmA")} - ${moment(ts).fromNow()}`} />

      </ComposedChart>
      </ResponsiveContainer>
    </Card> : <div style={styles.loader}><CircularProgress /></div>
  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 10,
    padding: 12,
    margin: 8,
    textAlign: 'center' as 'center'
  },
  loader: {
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}