import { IChat } from "../../../interface/Chat";

export const useSearchFilter = (query: string, conversations: IChat[]) =>
{
  if(!query) return conversations;

  return conversations.filter(c => {
    if(c.subject.toLowerCase().includes(query.toLowerCase())) return true
    if(c.email.toLowerCase().includes(query.toLowerCase())) return true
  })
}