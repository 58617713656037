import { Line, YAxis, XAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart } from 'recharts';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment'
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { LogApi } from '../../api/LogApi';
import { useState } from 'react';
import { useEffect } from 'react';
import { AdxItem } from '../../interface/AdxItem';

interface AdxChartProps
{
  tradingPair: string;
  period: number
  chartInterval: string
}


export const AdxChart = ({ chartInterval, tradingPair, period }: AdxChartProps) =>
{

  const [ adx, setAdx ] = useState<AdxItem[] | undefined>(undefined)
  const [ loading, setLoading ] = useState(false)

  useEffect(() =>
  {
    getAdx(true)
  }, [])

  useEffect(() =>
  {
    getAdx(false)
  }, [chartInterval])

  const getAdx = async (load: boolean) =>
  {
    if(tradingPair && chartInterval)
    {
      const logApi = new LogApi()
      load && setLoading(true)
      const adxData = await logApi.getAdx(tradingPair, period, chartInterval)
      setAdx(adxData)
      load &&  setLoading(false)
    }

  }

  return(
   !loading ? <Card style={styles.container}>
    <Typography color="textSecondary">[{tradingPair}] ADX Chart Interval: {chartInterval}</Typography>
      <ResponsiveContainer width="100%" height={324}>
      <ComposedChart
        width={100}
        height={500}
        data={adx || []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >

        <YAxis yAxisId="left"  />
        <YAxis yAxisId="right" domain={['dataMin', 'dataMax']} orientation="right" />
        <XAxis dataKey="timestamp" tickFormatter={(ts: string) => moment(ts).format("DD-MMM hh:mmA")} minTickGap={100}  />
        
        <Legend formatter={(e) => {
              if(e === 'adx') return "Average direction index"
              if(e === 'pdi') return "DI+"
              if(e === 'mdi') return "DI-"
              if(e === 'close') return "Close Price"

        }} />

        <Line yAxisId="left" type="monotone" dataKey="adx" stroke="#03A9F4" dot={false} strokeWidth={3} />
        <Line yAxisId="left" type="monotone" dataKey="pdi" stroke="#4CAF50" dot={false} strokeWidth={3} />
        <Line yAxisId="left" type="monotone" dataKey="mdi" stroke="#BDBDBD" dot={false} strokeWidth={3} />
        <Line yAxisId="right" type="monotone" dataKey="close" stroke="#FFC107" dot={false} strokeWidth={3} />

        <Tooltip
        labelStyle={{ color: "grey" }} 
        labelFormatter={(ts) => `${moment(ts).format("DD-MMM hh:mmA")} - ${moment(ts).fromNow()}`}
        formatter={(tick, itemName) => {

          if(itemName === 'close') return `$${tick} USD`

          if(itemName === "adx" && tick > 25)  return `${tick} (Market is trending)`
          if(itemName === "adx" && tick <= 25)  return `${tick} (Market is flat)`

          return Number(tick).toFixed(2)
          
          }}/>

      </ComposedChart>
      </ResponsiveContainer>
    </Card> : <div style={styles.loader}><CircularProgress /></div>

  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    padding: 12,
    fontSize: 10,
    margin: 8,
    textAlign: 'center' as 'center'
  },
  loader: {
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}