import Skeleton from "@mui/material/Skeleton"


export const LoadingUsers = () =>
{
  return(
    <div>
              <Skeleton height={80} />
              {
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i, k) => <Skeleton key={k} height={60} />)
              }

        </div>
  )
}