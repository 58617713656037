import { AxiosError } from "axios"
import { IChat } from "../../interface/Chat"



export enum ChatActions
{
  CREATE_CHAT_START = "CREATE_CHAT_START",
  CREATE_CHAT_SUCCESS = "CREATE_CHAT_SUCCESS",

  CHAT_WITH_USER_START = "CHAT_WITH_USER_START",
  CHAT_WITH_USER_START_SUCCESS = "CHAT_WITH_USER_START_SUCCESS",

  READ_CONVERSATION_START = "READ_CONVERSATION_START",
  READ_CONVERSATION_SUCCESS = "READ_CONVERSATION_SUCCESS",

  SEND_MESSAGE_START = "SEND_MESSAGE_START",
  SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS",

  GET_ALL_MY_CHATS_START = "GET_ALL_MY_CHATS_START",
  GET_ALL_MY_CHATS_SUCCESS = "GET_ALL_MY_CHATS_SUCCESS",

  REMOVE_MY_CHAT_START = "REMOVE_MY_CHAT_START",
  REMOVE_MY_CHAT_SUCCESS = "REMOVE_MY_CHAT_SUCCESS",

  SET_CURRENT_CHAT = "SET_CURRENT_CHAT",

  // Admin
  REMOVE_CONVERSATION_START = "REMOVE_CONVERSATION_START",
  REMOVE_CONVERSATION_SUCCESS = "REMOVE_CONVERSATION_SUCCESS",

  // Admin
  GET_ALL_CHATS_START = "GET_ALL_CHATS_START",
  GET_ALL_CHATS_SUCCESS = "GET_ALL_CHATS_SUCCESS",

  // Admin
  MARK_READ_BEGIN = "MARK_READ_BEGIN",
  MARK_READ_SUCCESS = "MARK_READ_SUCCESS",

  GET_UNSEEN_MESSAGE_COUNT_START = "GET_UNSEEN_MESSAGE_COUNT_START",
  GET_UNSEEN_MESSAGE_COUNT_SUCCESS = "GET_UNSEEN_MESSAGE_COUNT_SUCCESS",

  CLEAR_ERROR = "CLEAR_ERROR",
  ON_CHAT_ERROR = "ON_CHAT_ERROR",

  TOGGLE_CHAT_MODAL = "TOGGLE_CHAT_MODAL"

}


export const toggleChatModal = (show: boolean) =>
{
  return {
    type: ChatActions.TOGGLE_CHAT_MODAL,
    data: { show }
  }
}


export const setCurrentChat = (conversationId: string) =>
{
  return {
    type: ChatActions.SET_CURRENT_CHAT,
    data: { conversationId }
  }
}

export const createChatStart = (subject: string) =>
{
  return {
    type: ChatActions.CREATE_CHAT_START,
    data: { subject }
  }
}

export const startChatWithUser = (subject: string, destinationUserId: string) =>
{
  return {
    type: ChatActions.CHAT_WITH_USER_START,
    data: { subject, destinationUserId }
  }
}

export const createChatSuccess = (chat: IChat) =>
{
  return {
    type: ChatActions.CREATE_CHAT_SUCCESS,
    data: { chat }
  }
}

export const readConversationStart = (conversationId: string) =>
{
  return {
    type: ChatActions.READ_CONVERSATION_START,
    data: { conversationId }
  }
}

export const readConversationSuccess = (chat: IChat) =>
{
  return {
    type: ChatActions.READ_CONVERSATION_SUCCESS,
    data: { chat }
  }
}


export const sendMessageStart = (conversationId: string, from: string, message: string) =>
{
  return {
    type: ChatActions.SEND_MESSAGE_START,
    data: { conversationId, from, message }
  }
}

export const sendMessageSuccess = (chat: IChat) =>
{
  return {
    type: ChatActions.SEND_MESSAGE_SUCCESS,
    data: { chat }
  }
}

export const getMyChatsStart = () =>
{
  return {
    type: ChatActions.GET_ALL_MY_CHATS_START,
  }
}

export const getMyChatsSuccess = (chats: IChat[]) =>
{
  return {
    type: ChatActions.GET_ALL_MY_CHATS_SUCCESS,
    data: { conversations: chats }
  }
}

export const markReadBegin = (conversationId: string) =>
{
  return {
    type: ChatActions.MARK_READ_BEGIN,
    data: { conversationId }
  }
}

export const getUnseenMessageCountStart = () =>
{
  return {
    type: ChatActions.GET_UNSEEN_MESSAGE_COUNT_START
  }
}

export const getUnseenMessageCountSuccess = (count: number) =>
{
  return {
    type: ChatActions.GET_UNSEEN_MESSAGE_COUNT_SUCCESS,
    data: { count }
  }
}


export const markReadSuccess = (chat: IChat) =>
{
  return {
    type: ChatActions.MARK_READ_SUCCESS,
    data: { chat }
  }
}

export const removeMyChatStart = (conversationId: string) =>
{
  return {
    type: ChatActions.REMOVE_MY_CHAT_START,
    data: { conversationId }
  }
}

export const removeMyChatSuccess = (conversationId: string) =>
{
  return {
    type: ChatActions.REMOVE_MY_CHAT_SUCCESS,
    data: { conversationId }
  }
}


/**
 * Admin action creators
 */
export const removeConversationStart = (conversationId: string) =>
{
  return {
    type: ChatActions.REMOVE_CONVERSATION_START,
    data: { conversationId }
  }
}

export const removeConversationSuccess = (conversationId: string) =>
{
  return {
    type: ChatActions.REMOVE_CONVERSATION_SUCCESS,
    data: { conversationId }
  }
}

export const getAllChatsStart = () =>
{
  return {
    type: ChatActions.GET_ALL_CHATS_START,
  }
}

export const getAllChatsSuccess = (chats: IChat[]) =>
{
  return {
    type: ChatActions.GET_ALL_CHATS_SUCCESS,
    data: { conversations: chats }
  }
}

export const clearError = () =>
{
  return {
    type: ChatActions.CLEAR_ERROR
  }
}

export const onChatError = (error: AxiosError) =>
{
  return {
    type: ChatActions.ON_CHAT_ERROR,
    error: error.message
  }
}