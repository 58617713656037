import Typography from "@mui/material/Typography"
import { GREEN, RED } from "../../Theme/theme"
import { UserSignalProfit } from "../../interface/SignalInvestmentsResponse"
import { green } from "@mui/material/colors"
import Badge from "@mui/material/Badge"


interface ProfitPillProps
{
  item: UserSignalProfit
}

export const ProfitPill = ({ item }: ProfitPillProps) =>
{
  return(
    <div style={{ borderRadius: 12, backgroundColor: item.profit > 0 ? GREEN : RED, padding: 4, textAlign: 'center' }}>
    <Typography fontWeight={700} variant="subtitle2">Profit: ${item.profit}</Typography>
    <Badge badgeContent={`${item.percentProfit}%`} color="secondary" anchorOrigin={{ vertical: "top", horizontal: "left" }} />
    </div>
  )
}