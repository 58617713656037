import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import InsightsIcon from '@mui/icons-material/Insights';
import Typography from '@mui/material/Typography';
import { Copyright } from '../components/Copyright';
import Snackbar from '@mui/material/Snackbar'
import bgImage from '../assets/background.jpg'
import { LoginApi } from '../api/LoginApi';
import { KEY_TOKEN } from '../const';
import { ForgotPasswordModal } from '../components/modals/ForgotPasswordModal';
import SmartBanner from 'react-smartbanner';


export const  LoginV2 = () => {

  // Login
  const [ username, setUsername ] = useState("")
  const [ password, setPassword ] = useState("")
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState("")

  // Password reset
  const [ showForgotPasswordModal, setShowForgotPasswordModal ] = useState(false)
  const [ resettingPassword, setResettingPassword ] = useState(false)
  const [ resetEmail, setResetEmail ] = useState("")
  const [ resetSuccess, setResetSuccess ] = useState(false)

  const handleLogin = async () =>
  {
    try
    {
      setLoading(true)
      const loginApi = new LoginApi();
      const response = await loginApi.login(username, password)
      if(response && response.token)
      {
        localStorage.setItem(KEY_TOKEN, JSON.stringify(response))
        window.location.href = "/dashboard"
      }
    }
    catch(error)
    {
      setError("Invalid credentials")
      setLoading(false)
    }

  }

  const handleReset = async () =>
  {

    if(!resetEmail) return
    try
    {

      setResettingPassword(true)
      await new LoginApi().resetPassword(resetEmail)
      setResettingPassword(false)
    }
    catch(error)
    {
      setResettingPassword(false)
    }

    setResetSuccess(true)
    setShowForgotPasswordModal(false)
    setResetEmail("")

  }


  const handleKeyDown = (e: any) =>
  {
    if(e.keyCode === 13) handleLogin()
  }

  return (
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />

        <SmartBanner title="AlgoTrader" />

        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <InsightsIcon />
            </Avatar>
            <Typography component="h1" variant="h5" fontWeight="700">
              AlgoTrader Login
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                focused
                value={username}
                onChange={(e: any) => setUsername(e.target.value)}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address or Username"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onKeyDown={handleKeyDown}
              />

              <Button
                disabled={loading}
                startIcon={loading && <CircularProgress size={16} />}
                onClick={handleLogin}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                { loading ? "Logging in..." : "Sign In" }
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" onClick={() => setShowForgotPasswordModal(true)} variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>

        <Snackbar open={!!error} message={error} color="error" onClose={() => setError("")} autoHideDuration={5000} />
        <Snackbar autoHideDuration={6000} onClose={() => setResetSuccess(false)} open={resetSuccess} ><Alert severity="success">If you email exists, a password reset has been sent to your email</Alert></Snackbar>

      
       {showForgotPasswordModal && <ForgotPasswordModal 
          loading={resettingPassword} 
          resetEmail={resetEmail}
          onEmailChange={(email) => setResetEmail(email)}
          handleReset={handleReset}
          open={showForgotPasswordModal} 
          handleClose={() => setShowForgotPasswordModal(false)} /> }

      </Grid>
  );
}