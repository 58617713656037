import { Typography } from "@mui/material"
import SupportIcon from "@mui/icons-material/Support"

interface NoConversationsProps
{ 
  isAdmin: boolean | undefined
}


export const NoConversations = ({ isAdmin }: NoConversationsProps) =>
{
  return(
    <div style={styles.container}>

     { !isAdmin ? <>
      <SupportIcon style={styles.icon} />
      <Typography fontWeight="700" variant="subtitle1" color="textPrimary">Looks like you don't have any open support requests</Typography>
      <Typography variant="body2" color="textSecondary">If you need help with something enter a subject below and press start request</Typography>

      </> :
      <>
        <SupportIcon style={styles.icon} />
        <Typography fontWeight="700" variant="subtitle1" color="textPrimary">It's pretty quite here</Typography>
        <Typography variant="body2" color="textSecondary">No open support requests from customers</Typography>
      </>
      
      } 
    

    </div>
  )
}

const styles = {
  container: {
    justifyContent: 'center' as 'center',
    flex: 1,
    height: "40vh",
    textAlign: 'center' as 'center',
    aligItems: 'center' as 'center',
    flexDirection: "column" as 'column',
    display: 'flex'as 'flex'
  },
  icon: {
    height: 64,
    width: 64,
    alignSelf: 'center',
    color: "grey"
  }
}