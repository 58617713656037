
import Slider from '@mui/material/Slider';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid'


interface MACDSliderProps
{
  onChangeValue: (threshold: number) => void
  currentThreshold: number | undefined
  title: string
}

export const MACDSlider = ({ currentThreshold, onChangeValue, title }: MACDSliderProps) =>
{
  return(
  <div style={styles.container}>
   <Grid item >
        <FormLabel component="legend">{title}</FormLabel>
          <Slider
              onChange={(e: any) => onChangeValue(e.target.value)}
              style={styles.item}
              aria-label="Small steps"
              defaultValue={4}
              value={currentThreshold}
              step={1}
              marks
              min={1}
              max={20}
              valueLabelDisplay="on"
            />
      </Grid>
    </div>
  )
}

const styles = {
  container: {
    margin: 16,
  },
  item: {
    marginTop: 36,
    marginBottom: 12,
  }
}