import Table from "@mui/material/Table"
import { IRunEvent } from "../../interface/IRunEvent"
import { LogsHeader } from "./LogsHeader"
import { LogItem } from "./LogItem"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"

interface LogsTableProps
{
  logs: IRunEvent[] | undefined
  setShowOrderDetails: (show: IRunEvent | undefined) => void
}

export const LogsTable = ({ logs, setShowOrderDetails }: LogsTableProps) =>
{
  return(
    <TableContainer style={styles.body}>
      <Table component={Paper}>
      <LogsHeader />
      <TableBody>
        {logs && logs.map((row, key) => <LogItem key={key} row={row} setShowOrderDetails={setShowOrderDetails} />)}
      </TableBody>
    </Table>
  </TableContainer>
  )
}

const styles = {
  body: {
    overflow: "auto",
    height: "90vh"
  }
}