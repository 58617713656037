import { useState } from 'react'
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';
import { SignalItem } from "../../interface/SignalItem";
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../interface/ApplicationState';
import { SignalDetails } from './SignalDetailsModal';
import { SignalCard } from './SignalCard';
import { useTheme } from '@mui/material';
import { SearchBar } from '../../components/SearchBar';
import QueryStats from '@mui/icons-material/QueryStats';
interface SignalsListProps
{
  signals: SignalItem[] | undefined
}

export const SignalsList = ({ signals }: SignalsListProps) =>
{
  const [ showSignalDetailsModal, setShowSignalDetailsModal ] = useState<SignalItem | undefined>()
  const user = useSelector((state: ApplicationState) => state.userStore.user)
  const theme = useTheme()
  const [ query, setQuery ] = useState("")

  const cardBackGroundColor = user.isDarkModeOn ? theme.palette.grey[900] : theme.palette.background.paper
  const expiredColor = user.isDarkModeOn ? "#424242" : "#BDBDBD"


  const isSourceEnabled = (source: string) =>
  {
    if(user && user.signalsInvestSources && user.signalsInvestSources.length > 0 && user.signalsInvestSources.includes(source)) return true
    return false
  }

  const filterSignals = () =>
  {
    if(query && query.length > 2 && signals)
    {
      return signals.filter(signal => {
        if(signal.pair.toLowerCase().includes(query.toLowerCase())) return true
        if(signal.source.toLowerCase().includes(query.toLowerCase())) return true

      })
    }

    return signals || []
  }


  return(
    <div> 
    <Typography color="textSecondary" variant="subtitle1" style={styles.subText}>Signals Listings</Typography>

    <div style={styles.bar}>
    <SearchBar placeholder="Search Signals Listings" setQuery={setQuery} />
    </div>    


      <Grid container>

      {
        filterSignals().length > 0
         ?
         filterSignals().map((signal, key) => <SignalCard key={key} expiredColor={expiredColor} cardBackGroundColor={cardBackGroundColor} signal={signal} isEnabled={isSourceEnabled(signal.source)} onClickSignal={(signal) => setShowSignalDetailsModal(signal)}/>) 
         : <div style={styles.noSigals}>
           <QueryStats style={styles.icon} htmlColor="grey" />
           <Typography fontWeight="700" variant="subtitle1" color="textSecondary" >Looks like we could not find any signals</Typography>
         </div>
      }
       </Grid>

       { showSignalDetailsModal && <SignalDetails signal={showSignalDetailsModal} open={!!showSignalDetailsModal} handleClose={() => setShowSignalDetailsModal(undefined)} /> }

      </div>
  )
}


const styles = {
  container: {
    margin: 16,
  },
  subText: {
    marginTop: 12,
  },
  icon: {
    width: 100,
    height: 100
  },

  noSigals: {
    paddingTop: 16,
    paddingBottom: 16,
    height: "20vh",
    display: "flex" as 'flex',
    flex: 1,
    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
    flexDirection: "column" as "column"
  },
  item: {
    marginTop: 36,
    marginBottom: 12,
  },
  bar: {
    margin: 16,
  }
}