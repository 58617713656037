
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IRunEvent } from '../../interface/IRunEvent';
import { LogStatusItem } from './LogStatusItem';

interface RunEventTableProps
{
  runEvents: IRunEvent[]
}

export const RunEventTable = ({ runEvents }: RunEventTableProps) =>
{
  return(
    <TableContainer sx={{ maxHeight: 200  }} component={Paper} >
    <Table size="small" aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Event</TableCell>
          <TableCell>Market</TableCell>
          <TableCell>Reason</TableCell>
          <TableCell align="right">Buy time</TableCell>
          <TableCell align="right">Quantity</TableCell>
          <TableCell align="right">Price</TableCell>
          <TableCell align="right">Profit</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      { runEvents && runEvents.map((runEvent, key) => <LogStatusItem key={key} runEvent={runEvent} />) }
      </TableBody>
    </Table>
  </TableContainer>
  )
}