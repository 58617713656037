import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CheckCircle from '@mui/icons-material/CheckCircle'
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';

import moment from 'moment'
import { SignalItem } from '../../interface/SignalItem';
import { CardActionArea } from '@mui/material';
import { blue } from '@mui/material/colors';
import { StepsSection } from './StepsSection';
import { BacktestingSection } from './BacktestingSection';

interface SignalCardProps
{
  signal: SignalItem
  isEnabled: boolean
  onClickSignal: (signal: SignalItem) => void
  cardBackGroundColor: string
  expiredColor: string
}

export const SignalCard = ({ signal, isEnabled, onClickSignal, cardBackGroundColor, expiredColor }: SignalCardProps) =>
{

  const isSignalExpired = () =>
  {
    if(signal.timeout < new Date().valueOf()) return true
    return false
  }

  const getBadgeColor = () =>
  {
    const price = Number(signal.currentPrice)

    if(signal.direction === "LONG")
    {
      if(price > signal.entry) return "success"
      if(price < signal.entry) return "error"
    }

    if(signal.direction === "SHORT")
    {
      if(price > signal.entry) return "error"
      if(price < signal.entry) return "success"
    }

    return "default"
  }

  const getDirectionColor = () =>
  {
    if(signal.direction === "LONG") return "success"
    if(signal.direction === "SHORT") return "secondary"
  }    


  return(
    <Grid item xs={12} md={12} lg={6} xl={6}>
    <CardActionArea onClick={() => onClickSignal(signal)}>
      <Card sx={{ margin: 2, height: 220, backgroundColor: isSignalExpired() ? expiredColor : cardBackGroundColor }}>

      <CardHeader
        avatar={
          <Badge badgeContent={`${signal.percentIncrease}%`} color={getBadgeColor()} >
            <Avatar sx={{ bgcolor: blue[500], height: 60, width: 60 }} aria-label="recipe">
              {signal.pair[0]}
            </Avatar>
          </Badge>
        }
        action={isEnabled && <Tooltip title="This source is enabled"><CheckCircle htmlColor="#66BB6A" /></Tooltip>}
        title={signal.source}
        subheader={signal.coin}
      />

        <div style={styles.backtesting}>
          <BacktestingSection signal={signal} />
        </div>

        <CardContent>

          <Typography gutterBottom variant="caption" component="div">
          {isSignalExpired() && `Signal has expired | `} Created { moment(signal.time_generated).fromNow() }

          </Typography>

          <Badge style={styles.badge} color={getDirectionColor()} badgeContent={signal.direction}/>

          <Typography variant="caption" color="text.secondary">
            Base: { signal.base} |
            Pair: { signal.pair} | 
            Timeout { moment(signal.timeout).fromNow() } ({ moment(signal.timeout).format('DD/MM/YY h:mmA')})
          </Typography>
        </CardContent>
        <CardActions>

          <StepsSection signal={signal}/>

        </CardActions>
        
      </Card>
    </CardActionArea>
    </Grid>
  )
}

const styles = {
  badge: {
    marginLeft: 24, 
    marginRight: 34
  },
  backtesting: {
    position: "absolute" as "absolute", 
    right: 55, 
    top: 28,
  }
}
