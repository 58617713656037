import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { KEY_TOKEN } from "../const"



export class HttpService
{
  public request = async <T>(method: string, endpoint: string, body?: any) =>
  {
    const config: AxiosRequestConfig = {
      method,
      headers: {
        Authorization: this.getToken() || undefined,
      },
      url: `${process.env.REACT_APP_BASE_URL}/${endpoint}`,
      data: body ? body : undefined,
    }

    try
    {
      return await axios<T>(config)
    }
    catch(error)
    {
      const errorMessage = error as any
      if (this.isExpiryError(errorMessage)) return this.clearAndRedirect()
      throw new AxiosError(errorMessage)
    }
  }

  public requestWithToken = async <T>(token: string, method: string, endpoint: string, body?: any) =>
  {
    const config: AxiosRequestConfig = {
      method,
      headers: {
        Authorization: token,
      },
      url: `${process.env.REACT_APP_BASE_URL}/${endpoint}`,
      data: body ? body : undefined,
    }

    try
    {
      return await axios<T>(config)
    }
    catch(error)
    {
      const errorMessage = error as any
      throw new AxiosError(errorMessage)
    }
  }


  // Redirect to login if token expired
  private isExpiryError = (errorMessage: any) =>
  {
    if(errorMessage && errorMessage.response && errorMessage.response.data && errorMessage.response.data.error && errorMessage.response.data.error.message === "jwt expired") return true;
    return false
  }

  private clearAndRedirect = () =>
  {
    localStorage.clear();
    window.location.href = "/"
  }

  private getToken = () =>
  {
    try
    {
      const credentials = localStorage.getItem(KEY_TOKEN)
      if(credentials) return JSON.parse(credentials).token
      return undefined
    }
    catch(error)
    {
      return undefined
    }

  }
}