import { Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart, Label } from 'recharts';
import moment from 'moment'
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';

import { useEffect, useState } from 'react';
import { BackTestApi } from '../../api/BackTestApi';
import { BackTestChartItem } from '../../interface/BackTest';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export const BacktestingChart = () =>
{

  const [ marketId, setMarketId ] = useState("BTCUSDT")
  const [ loading, setLoading ] = useState(false)
  const [ results, setResults ] = useState<BackTestChartItem[] | undefined>([])

  const [ showRsi3060, setShowRsi3060 ] = useState(true)
  const [ showRsi3070, setShowRsi3070 ] = useState(false)
  const [ showMfi, setShowMfi ] = useState(true)
  const [ showSma, setShowSma ] = useState(false)
  const [ showCandles, setShowCandles ] = useState(false)
  const [ showCustom, setShowCustom ] = useState(true)
  const [ showMacd, setShowMacd ] = useState(true)
  const [ showDoubleEmaCrossOver1250, setShowDoubleEmaCrossOver1250 ] = useState(true)
  const [ showDoubleEmaCrossOver50200, setShowDoubleEmaCrossOver50200 ] = useState(false)
  const [ showTripleEmaZeroLoss81321, setShowTripleEmaZeroLoss81321 ] = useState(false)
  const [ showTripleEma81321, setShowTripleEma81321 ] = useState(false)
  const [ showTripleEma5813, setShowTripleEma5813 ] = useState(false)
  const [ showDoubleEmaCrossOver515, setShowdoubleEmaCrossOver515 ] = useState(false)


  useEffect(() => {

    getChart()

  }, [])


  const getChart = async () =>
  {

    setLoading(true)
    const btApi = new BackTestApi()
    const results = await btApi.getChart(marketId);
    setResults(results)
    setLoading(false)
  }

  return(
    <Card style={styles.container}>

      { loading ? <Skeleton height={500} /> : 
      <ResponsiveContainer width="100%" height={500}>
      <ComposedChart
        width={300}
        height={500}
        data={results} // Graph needs ASC so we reverse the results
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="timestamp" tickFormatter={(ts: string) => moment(ts).format("DD-MMM hh:mmA")} minTickGap={200} />

        <YAxis yAxisId="left" tickFormatter={(tick) => `${tick}%`}>
          <Label angle={-90} value='Win Percent' position='insideLeft' style={{ textAnchor: 'middle', fill: "grey" }} />
        </YAxis>


        <Legend formatter={(key)=> {

          if(key === "rsi3060") return "RSI 30/60"
          if(key === "rsi3070") return "RSI 30/70"
          if(key === "mfi") return "MFI"
          if(key === "sma") return "SMA"
          if(key === "candles") return "Candles"
          if(key === "custom") return "Custom"
          if(key === "macd") return "MACD"
          if(key === "doubleEmaCrossOver1250") return "EMA 12/50"
          if(key === "doubleEmaCrossOver50200") return "EMA 50/200"
          if(key === "tripleEmaZeroLoss81321") return "EMA 8/13/21 Zero Min Sell"
          if(key === "tripleEma81321") return "EMA 8/13/21"
          if(key === "tripleEma5813") return "EMA 5/8/13"
          if(key === "doubleEmaCrossOver515") return "EMA 5/15"

        }}/>
        { showRsi3060 && <Line yAxisId="left" type="monotone" dataKey="rsi3060" stroke="#F06292" dot={false} strokeWidth={3} /> }
        { showRsi3070 && <Line yAxisId="left" type="monotone" dataKey="rsi3070" stroke="#BA68C8" dot={false} strokeWidth={3} /> }
        { showMfi && <Line yAxisId="left" type="monotone" dataKey="mfi" stroke="#1E88E5" dot={false} strokeWidth={3} /> }
        { showSma && <Line yAxisId="left" type="monotone" dataKey="sma" stroke="#7986CB" dot={false} strokeWidth={3} /> }
        { showCandles && <Line yAxisId="left" type="monotone" dataKey="candles" stroke="#64B5F6" dot={false} strokeWidth={3} /> }
        { showCustom && <Line yAxisId="left" type="monotone" dataKey="custom" stroke="#4DD0E1" dot={false} strokeWidth={3} /> }
        { showMacd && <Line yAxisId="left" type="monotone" dataKey="macd" stroke="#26A69A" dot={false} strokeWidth={3} /> }
        { showDoubleEmaCrossOver1250 && <Line yAxisId="left" type="monotone" dataKey="doubleEmaCrossOver1250" stroke="#FFD54F" dot={false} strokeWidth={3} /> }
        { showDoubleEmaCrossOver50200 && <Line yAxisId="left" type="monotone" dataKey="doubleEmaCrossOver50200" stroke="#66BB6A" dot={false} strokeWidth={3} /> }
        { showTripleEmaZeroLoss81321 && <Line yAxisId="left" type="monotone" dataKey="tripleEmaZeroLoss81321" stroke="#0288D1" dot={false} strokeWidth={3} /> }
        { showTripleEma81321 && <Line yAxisId="left" type="monotone" dataKey="tripleEma81321" stroke="#303F9F" dot={false} strokeWidth={3} /> }
        { showTripleEma5813 && <Line yAxisId="left" type="monotone" dataKey="tripleEma5813" stroke="#C2185B" dot={false} strokeWidth={3} /> }
        { showDoubleEmaCrossOver515 && <Line yAxisId="left" type="monotone" dataKey="doubleEmaCrossOver515" stroke="#80CBC4" dot={false} strokeWidth={3} /> }

        <Tooltip 
          labelStyle={{ color: "grey"}}
          formatter={(tick) => `${Number(tick).toFixed(2)}%`}  
          labelFormatter={(ts) => `${moment(ts).format("DD-MMM hh:mmA")} - ${moment(ts).fromNow()}`} />

      </ComposedChart>


      </ResponsiveContainer> }


      <div style={styles.controls}>
      <Typography color='textSecondary' variant="subtitle2">Chart Controls</Typography>

        <FormControlLabel control={<Switch size="small" checked={showRsi3060} onChange={(e: any) => setShowRsi3060(e.target.checked)} />} label="RSI 30/60" />
        <FormControlLabel control={<Switch size="small" checked={showRsi3070} onChange={(e: any) => setShowRsi3070(e.target.checked)} />} label="RSI 30/70" />
        <FormControlLabel control={<Switch size="small" checked={showMfi} onChange={(e: any) => setShowMfi(e.target.checked)} />} label="MFI" />
        <FormControlLabel control={<Switch size="small" checked={showSma} onChange={(e: any) => setShowSma(e.target.checked)} />} label="SMA" />
        <FormControlLabel control={<Switch size="small" checked={showCandles} onChange={(e: any) => setShowCandles(e.target.checked)} />} label="Candles" />
        <FormControlLabel control={<Switch size="small" checked={showCustom} onChange={(e: any) => setShowCustom(e.target.checked)} />} label="Custom" />
        <FormControlLabel control={<Switch size="small" checked={showMacd} onChange={(e: any) => setShowMacd(e.target.checked)} />} label="MACD" />
        <FormControlLabel control={<Switch size="small" checked={showDoubleEmaCrossOver1250} onChange={(e: any) => setShowDoubleEmaCrossOver1250(e.target.checked)} />} label="EMA 12/50" />
        <FormControlLabel control={<Switch size="small" checked={showDoubleEmaCrossOver50200} onChange={(e: any) => setShowDoubleEmaCrossOver50200(e.target.checked)} />} label="EMA 50/200" />
        <FormControlLabel control={<Switch size="small" checked={showTripleEmaZeroLoss81321} onChange={(e: any) => setShowTripleEmaZeroLoss81321(e.target.checked)} />} label="EMA 8/13/21 Zero Loss" />
        <FormControlLabel control={<Switch size="small" checked={showTripleEma81321} onChange={(e: any) => setShowTripleEma81321(e.target.checked)} />} label="EMA 8/13/21" />
        <FormControlLabel control={<Switch size="small" checked={showTripleEma5813} onChange={(e: any) => setShowTripleEma5813(e.target.checked)} />} label="EMA 5/8/13" />
        <FormControlLabel control={<Switch size="small" checked={showDoubleEmaCrossOver515} onChange={(e: any) => setShowdoubleEmaCrossOver515(e.target.checked)} />} label="EMA 5/15" />

      </div>
      

    </Card>

  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    padding: 16,
    marginBottom: 32
  },
  controls: {
    margin: 32
  }
}