import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { SignalItem } from '../../interface/SignalItem';

interface StepsSectionProps
{
  signal: SignalItem
}



export const StepsSection = ({ signal }: StepsSectionProps) =>
{

  const getPriceText = (step: string) =>
  {
    if(step === "Stop Loss") return Number(signal.loss).toFixed(8)
    if(step === "Entry") return Number(signal.entry).toFixed(8)
    if(step === "Take Profit") return Number(signal.profit).toFixed(8)
    if(step === "Current Price") return Number(signal.currentPrice).toFixed(8)

  }

  const getStepsAndIndex = () =>
  {
    const currentPrice = Number(signal.currentPrice)

    if(currentPrice < signal.loss)
    {
      const steps = ['Current Price', 'Stop Loss', 'Entry', 'Take Profit'];
      return { steps, currentStep: 0 }
    }

    if(currentPrice > signal.loss && currentPrice < signal.profit && currentPrice < signal.entry)
    {
      const steps = ['Stop Loss', 'Current Price', 'Entry', 'Take Profit'];
      return { steps, currentStep: 1 }
    }

    if(currentPrice > signal.loss && currentPrice < signal.profit && currentPrice > signal.entry)
    {
      const steps = ['Stop Loss', 'Entry', 'Current Price', 'Take Profit'];
      return { steps, currentStep: 2}
    }

    if(currentPrice > signal.profit)
    {
      const steps = ['Stop Loss', 'Entry', 'Take Profit', "Current Price"];
      return { steps, currentStep: 3}
    }

    return { steps: [], currentStep: 0 }
  }
  const { steps, currentStep } = getStepsAndIndex()


  return(<Stepper activeStep={currentStep} style={{ flex: 1}}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {optional?: React.ReactNode, error?: boolean } = {};
       
        stepProps.completed = false
        labelProps.optional = (<Typography variant="caption">{getPriceText(label)}</Typography>);
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
  )
}