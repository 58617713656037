
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/ClearOutlined";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";

interface SearchViewProps
{
  setSearchQuery: (query: string) => void
  searchQuery: string
}

export const SearchView = ({ searchQuery, setSearchQuery }: SearchViewProps) =>
{
  return(
    <TextField 
    InputProps={{
      startAdornment: <SearchIcon htmlColor="grey" />,
      endAdornment: <IconButton onClick={() => setSearchQuery("")}><ClearIcon /></IconButton>
    }}
    style={styles.search} 
    fullWidth 
    value={searchQuery}
    variant="outlined"
    placeholder="Search users"
    onChange={(e: any) => setSearchQuery(e.target.value)}/>
  )
}


const styles = {
  search: {
    marginTop: 12,
    marginBottom: 12,
  }
}