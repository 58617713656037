import createTheme from "@mui/material/styles/createTheme";

export const GREEN = "#66BB6A"
export const BLUE = "#64B5F6"
export const RED = "#EF5350"

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  shape: {
    borderRadius: 12
  }
});

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
  shape: {
    borderRadius: 12
  }
});