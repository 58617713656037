import "../Theme/styles/hideScroll.css"
import Card from '@mui/material/Card'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography'

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { BtcMarkets } from '../api/BtcMarkets';
import { useState } from 'react';
import { OrderItem } from '../interface/OrderItem';
import moment from 'moment'
import { User } from "../interface/User";
import { DEFAULT_TRADING_PAIR, REFRESH_INTERVAL } from "../const";
import { getOrderType } from "../utils/limitMarket";
import { LottieLoading } from "./placeholder/LottieLoading";
let ID: any;


interface OrderListProps
{
  side: "Bid" | "Ask"
  marketId: string
  user: User
  title: string
}

export const OrderList = ({ side, marketId, user, title }: OrderListProps) =>
{
  const [ orders, setOrders ] = useState<OrderItem[] | undefined>([])
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {

    getOrders()
    setRefresh()

    return function()
    {
      clearInterval(ID as unknown as any)
    }

  }, [])

  const getOrders = async () =>
  {
    try
    {
      setLoading(true)
      const btc = new BtcMarkets()
      const orders = await btc.getOrders(marketId, side)
  
      setTitle(orders)
      setOrders(orders?.reverse())
      setLoading(false)
    }
    catch(error)
    {
      setLoading(false)
    }

  }

  const setTitle = (orders: OrderItem[] | undefined) =>
  {
    try
    {
      if(orders)
      {
        const order = orders[orders.length - 1]
        document.title = `AlgoTrader :: ${order.isBuyer ? "BUY @ " : "SELL @"} $${Number(order.quoteQty).toFixed(2)}`
      }
    }
    catch(error)
    {

    }

  }

  const getTodayOrders = () =>
  {
    let count = 0
    if(orders)
    {
      for(const order of orders) if(moment.unix(order.time / 1000).isAfter(moment().subtract(48, 'hours'))) count++
    }

    return count

  }

  const setRefresh = () =>
  {
    ID = setInterval(() => {

      getOrders()

    }, REFRESH_INTERVAL)
  }

  return(<Card style={styles.card}>

    { loading ? <div>

      <Skeleton height={80} />
      {
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i, k) => <Skeleton key={k} height={60} />)
      }

    </div> :
    <>
 <Typography fontWeight={700} variant="subtitle1">[{user.tradingPair || DEFAULT_TRADING_PAIR}] {title} - {getTodayOrders()} orders in the last 48 hours</Typography>

  { orders && orders.length === 0 && <LottieLoading animation={require("../animations/not-found-lottie.json")} height={200} width={200} loop={false} text={`No recent ${title} orders`} containerHeight="50vh"/> }
  { orders && orders.length > 0 && <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Creation Time</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Market Price (USD)</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">MarketId</TableCell>
            <TableCell align="right">Qty. (USD)</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {orders && orders.map((row, key) => (
            <TableRow key={key} >
              <TableCell component="th" scope="row">
                {moment.unix(row.time / 1000).format("lll")}
                <small style={{ display: 'flex', alignSelf: 'center', color: 'grey'}}> ({moment.unix(row.time / 1000).fromNow()})</small>
              </TableCell>
              <TableCell align="right">{getOrderType(row)}</TableCell>
              <TableCell align="right">${Number(row.price).toFixed(2)}</TableCell>
              <TableCell align="right">{row.qty}</TableCell>
              <TableCell align="right">{row.symbol}</TableCell>
              <TableCell align="right">${(Number(row.qty) * Number(row.price)).toFixed(2)}</TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> }
    </>
    }
  </Card>)
}

const styles = {
  card: {
    height: "55vh",
    padding: 16,
    overflow: "auto"
  }
}