

import FormLabel from '@mui/material/FormLabel';
import Card from '@mui/material/Card';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

interface CandleStickItemForm
{
  name: string;
  label: string
}


interface CandleStickIntervalChooserProps
{
  title: string
  items: CandleStickItemForm[]
  currentCheckedItem: string
  onItemChanged: (item: string) => void
}

export const CandleStickIntervalChooser = ({ title, items, currentCheckedItem, onItemChanged }: CandleStickIntervalChooserProps) =>
{
  return(
    <Card style={styles.card}>
      <FormGroup>
        <FormLabel component="legend">{title}</FormLabel>

        { items && items.map((item, key) => (
          <FormControlLabel
          key={key}
          control={<Checkbox checked={currentCheckedItem === item.name} onChange={(e: any) => onItemChanged(e.target.name)} name={item.name} />}
          label={item.label}
          />
        )) }
        </FormGroup>

  </Card>
  )
}

const styles = {
  card: {
    padding: 32,
    marginTop: 16,
    marginRight: 32,
    width: 300,
  }
}