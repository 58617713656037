import { BackTest, BackTestChartItem } from "../interface/BackTest";
import { HttpService } from "./HttpService";

export class BackTestApi extends HttpService
{
  getResults = async (tradingPair?: string, indicator?: string, fromTime?: string, order?: string): Promise<BackTest[] | undefined> =>
  {
    const response = await this.request<BackTest[]>("GET", `backtesting?tradingPair=${tradingPair}&indicator=${indicator}&order=${order}&fromTime=${fromTime}`)
    if(response) return response.data
  }

  getChart = async (marketId: string): Promise<BackTestChartItem[] | undefined> =>
  {
    const response = await this.request<BackTestChartItem[]>("GET", `backtesting/chart?marketId=${marketId}`)
    if(response) return response.data
  }
}