import Typography from '@mui/material/Typography'

interface TextWithIconProps
{
  icon: React.ReactNode
  text: string
  style: any
}

export const TextWithIcon = ({ icon, text, style }: TextWithIconProps) =>
{
  return(
    <div style={{ ...styles.container, ...style }}>
      {icon}
      <Typography fontWeight="700" color="textSecondary">{text}</Typography>
    </div>)
}

const styles = {
  container: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    flex: 1,
    flexDirection: "row" as 'row',
  }
}