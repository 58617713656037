import { darkTheme } from "../Theme/theme"
import { ThemeProvider } from '@mui/material';
import { Route, Routes } from "react-router-dom";
import { SignUp } from "../Pages/SignUp";
import { LoginV2 } from "../Pages/LoginV2";
import { Terms } from "../Pages/Terms";
import { Privacy } from "../Pages/Privacy";
import { ResetPassword } from "../Pages/ResetPassword";

export const NonAuthRoutes = () =>
{
  return(
    <ThemeProvider theme={darkTheme}>

    <Routes>
      <Route path="/" element={<LoginV2 />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
   </Routes>
   
  </ThemeProvider>
 )
}