import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Fab from '@mui/material/Fab';
import CssBaseline from '@mui/material/CssBaseline';
import SellIcon from '@mui/icons-material/SellRounded'

import MACDChartIcon from '@mui/icons-material/Analytics'
import VolumeProfileIcon from '@mui/icons-material/LineWeight'

import EMAChartIcon from '@mui/icons-material/StackedLineChart'
import SingleEmaIcon from '@mui/icons-material/ShowChart'

import TripleEmaIcon from '@mui/icons-material/MultilineChart'
import RsiIcon from '@mui/icons-material/SsidChart'
import MfiIcon from '@mui/icons-material/WaterfallChart'
import BBIcon from '@mui/icons-material/LegendToggle'


import Timer1 from '@mui/icons-material/Timer'
import Timer2 from '@mui/icons-material/Schedule'
import Timer3 from '@mui/icons-material/Update'
import Timer4 from '@mui/icons-material/BrowseGallery'
import Timer5 from '@mui/icons-material/History'
import Timer6 from '@mui/icons-material/AvTimer'


import { OrderList } from '../components/OrderList'
import { blue, green, purple } from '@mui/material/colors'
import { useState } from 'react'
import { Summary } from '../interface/Summary'
import { useEffect } from 'react'
import { LogApi } from '../api/LogApi'
import { MarketChart } from '../components/charts/MarketChart'
import { Candle } from '../interface/Candle'
import { MACDChart } from '../components/charts/MACDChart'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../interface/ApplicationState'
import { CurrentFunds } from '../components/cards/CurrentFunds'
import { DailyStat } from '../components/cards/DailyStat'
import { ProfitAndLoss } from '../components/cards/ProfitAndLoss'
import { ProfitSummary } from '../interface/ProfitSummary'
import { REFRESH_INTERVAL } from '../const'
import { RSIChart } from '../components/charts/RSIChart'
import { EMASMAChart } from '../components/charts/EMASMAChart'
import { SellModal } from '../components/modals/SellModal/SellModal'
import { MFIChart } from '../components/charts/MFIChart';
import { SMAChart } from '../components/charts/SMAChart';
import { VWAPChart } from '../components/charts/VWAPChart';
import { BollingerBandsChart } from '../components/charts/BollingerBandsChart';
import { CustomChart } from '../components/charts/CustomChart';
import { TripleEmaChart } from '../components/charts/TripleEmaChart';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { RSICrossOver } from '../components/charts/RSIMAChart';
import { AdxChart } from '../components/charts/AdxChart';
import { UserApi } from '../api/UserApi';
import { updateLocalUser } from '../redux/actions/userActions';
import { TripleRSI } from '../components/charts/TripleRSI';
import { VolumeProfileChart } from '../components/charts/VolumeProfileChart';
import { OnboardModal } from '../components/modals/Onboard/OnboardModal';
import { TrialOverModal } from '../components/modals/TrialOver/TrialOverModal';

export const AVATAR_SIZE = 150
let ID: any;

export const Dashboard = () =>
{
  const user = useSelector(({ userStore }: ApplicationState) => userStore.user)
  const isGettingUserProfile = useSelector(({ userStore }: ApplicationState) => userStore.isGettingUserProfile)
  const subscriptionStatus = useSelector((state: ApplicationState) => state.billing.subscriptionStatus)


  const [ summary, setSummary ] = useState<Summary | undefined>(undefined)
  const [ error, setError ] = useState<any | undefined>(undefined)

  const [ candles, setCandles ] = useState<Candle[] | undefined>(undefined)
  const [ openModal, setOpenModal ] = useState(false)

  const [ profitSummary, setProfitSummary ] = useState<ProfitSummary[] | undefined>(undefined)
  const [ loading, setLoading ] = useState(false)
  const [ tabIndex, setTabIndex ] = useState(8)
  const [ showSellModal, setShowSellModal ] = useState(false)
  const [ isValid, setIsValid ] = useState(false)

  const dispatch = useDispatch()

  const getSummary = async () =>
  {
    try
    {
      const logApi = new LogApi()
      const summary = await logApi.getSummary(user.tradingPair || "")
      setSummary(summary)
    }
    catch(error)
    {
      console.error("Error getting summary")
    }

  }

  const getCandles = async () =>
  {
    if(user && user.tradingPair && user.chartInterval)
    {
      try
      {
        const logApi = new LogApi()
        const candles = await logApi.getCandles(user.tradingPair, user.chartInterval)
        setCandles(candles)
      }
      catch(error)
      {
        console.error("Error getting Candles")
      }

    }
  }

  const getProfitSummary = async () =>
  {
    try
    {
      const logApi = new LogApi()
      const profitSummaryData = await logApi.getProfitSummary()
      setProfitSummary(profitSummaryData)
    }
    catch(error)
    {
      console.error("Error getting profit summary")
    }

  }

  useEffect(() => {

    getAllDashboardData()
    setRefresh()

    return function()
    {
      clearInterval(ID as unknown as any)
    }

  }, [ ])

  useEffect(() => {

    getAllDashboardData()

  }, [ user.tradingPair ])

  useEffect(() => {

    getCandles()

  }, [ user.chartInterval ])


  const setRefresh = () =>
  {
    ID = setInterval(() => {

      getAllDashboardData()

    }, REFRESH_INTERVAL) // Refresh every 10 mins
  }

  const getAllDashboardData = async () =>
  {
    setLoading(true)
    await validateKey()
    await getSummary()
    await getCandles()
    await getProfitSummary()
    setLoading(false)
  }

  const validateKey = async () =>
  {
    try
    {
      // Will throw if it's invalid
      await new UserApi().validateApiKey()
      setError("")
      setIsValid(true)
      setOpenModal(false)

    }
    catch(error)
    {
      const errorCast = error as any
      setIsValid(false)
      setOpenModal(true)
      setError(errorCast?.message?.response?.data?.error)

    }
  }

  const handleChartIntervalChange = async (interval: string) =>
  {
    dispatch(updateLocalUser({ chartInterval: interval }))
  }

  return(

    <div>
      <CssBaseline />

    { subscriptionStatus?.mode === 'free' && <TrialOverModal open handleClose={() => {} }/>}
    { !isGettingUserProfile && !loading && !isValid && openModal && <OnboardModal open handleClose={() => setOpenModal(true)} /> }
    
      { user.binanceApiKey && user.binanceApiSecret && isValid && <Grid container spacing={3}>

      <CssBaseline />
      <Grid item container direction="row" spacing={2}>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <CurrentFunds loading={loading} user={user} summary={summary} />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <DailyStat 
            color={purple[500]} 
            assetName={summary?.tradingPairSummary.asset || "Unknown"}
            summary={summary}
            summaryItem={summary?.tradingPairSummary} loading={loading} title={`Current ${summary?.tradingPairSummary.asset}`} />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <ProfitAndLoss summary={summary} loading={loading} profitSummary={profitSummary} />
        </Grid>
      </Grid>

      <Grid item container>

      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>


        { loading && <Skeleton height={500} style={{ margin: 2 }}/>}
        { !loading && user && user.tradingPair && user.chartInterval && 
        
        <>

        <Tabs value={user.chartInterval} onChange={(e, v) => handleChartIntervalChange(v)} aria-label="indicators" centered>
          <Tab label="1m" value="1m" icon={<Timer1 />} iconPosition="start" />
          <Tab label="5m" value="5m" icon={<Timer2 />} iconPosition="start" />
          <Tab label="15m" value="15m" icon={<Timer3 />} iconPosition="start" />
          <Tab label="30m" value="30m" icon={<Timer4 />} iconPosition="start" />
          <Tab label="1h" value="1h" icon={<Timer5 />} iconPosition="start" />
          <Tab label="4h" value="4h" icon={<Timer6 />} iconPosition="start" />
          <Tab label="12h" value="12h" icon={<Timer1 />} iconPosition="start" />
          <Tab label="1d" value="1d" icon={<Timer2 />} iconPosition="start" />

        </Tabs>
          <MarketChart candles={candles} chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair}/>
        
        </>}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
      { loading && <Skeleton height={500} style={{ margin: 2 }} />}

      { !loading && user && user.tradingPair && user.chartInterval && 
      <>
        <Tabs value={tabIndex} onChange={(e, v) => setTabIndex(v)} aria-label="indicators" variant="scrollable" scrollButtons>
          <Tab label="MACD" value={0} icon={<MACDChartIcon />} iconPosition="start"  />
          <Tab label="Triple EMA" value={8} icon={<TripleEmaIcon />} iconPosition="start"  />
          <Tab label="Volume Profile" value={12} icon={<VolumeProfileIcon />} iconPosition='start' />
          <Tab label="ADX" value={10} icon={<SingleEmaIcon />} iconPosition="start"  />
          <Tab label="Custom" value={7} icon={<MACDChartIcon />} iconPosition="start"  />
          <Tab label="EMA Crossover" value={1} icon={<EMAChartIcon />} iconPosition="start"  />
          <Tab label="Bollinger Bands" value={2} icon={<BBIcon />} iconPosition="start"  />
          <Tab label="RSI" value={3} icon={<RsiIcon />} iconPosition="start"  />
          <Tab label="RSI Crossover" value={9} icon={<RsiIcon />} iconPosition="start"  />
          <Tab label="Triple RSI" value={11} icon={<RsiIcon />} iconPosition="start"  />
          <Tab label="MFI" value={4}  icon={<MfiIcon />} iconPosition="start" />
          <Tab label="SMA" value={5} icon={<SingleEmaIcon />} iconPosition="start" />
          <Tab label="VWAP" value={6} icon={<SingleEmaIcon />} iconPosition='start' />
        </Tabs>

      { tabIndex === 0 && <MACDChart chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} /> }
      { tabIndex === 1 && <EMASMAChart emaType={user.emaType} chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} emaShortPeriod={user.emaShortPeriod || 8} emaLongPeriod={user.emaLongPeriod || 20}/> }
      { tabIndex === 2 && <BollingerBandsChart chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair}/> }
      { tabIndex === 3 && <RSIChart chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} rsiThresholdBuy={user.rsiThresholdBuy} rsiThresholdSell={user.rsiThresholdSell} /> }
      { tabIndex === 4 && <MFIChart chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} mfiThresholdBuy={user.mfiThresholdBuy} mfiThresholdSell={user.mfiThresholdSell} /> }
      { tabIndex === 5 && <SMAChart period={10} chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} /> }
      { tabIndex === 6 && <VWAPChart chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} /> }
      { tabIndex === 7 && <CustomChart emaType={user.emaType} chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} emaShortPeriod={user.emaShortPeriod || 8} emaLongPeriod={user.emaLongPeriod || 20}/> }
      { tabIndex === 8 && <TripleEmaChart chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} emaShortPeriod={user.tripleEmaShortPeriod || 5} emaMediumPeriod={user.tripleEmaMediumPeriod || 8} emaLongPeriod={user.tripleEmaLongPeriod || 13} volumeShort={user.shortVolumeCrossover || 9} volumeLong={user.longVolumeCrossover || 21}/> }
      { tabIndex === 9 && <RSICrossOver chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} rsiShort={user.rsiShort} rsiMedium={user.rsiMedium} rsiLong={user.rsiLong} /> }
      { tabIndex === 10 && <AdxChart period={14} chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} /> }
      { tabIndex === 11 && <TripleRSI tradingPair={user.tradingPair} /> }
      { tabIndex === 12 && <VolumeProfileChart tradingPair={user.tradingPair} chartInterval={user.chartInterval || "1m"} bars={user.vpNumberOfBars || 14}/> }

      </> }

      </Grid>

      </Grid>

      <Grid item container direction="row" spacing={2} >

        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
        { user && user.tradingPair && <OrderList user={user} side="Bid" marketId={user.tradingPair} title="Buy Orders (Bid)" /> }
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} >
        { user && user.tradingPair && <OrderList user={user} side="Ask" marketId={user.tradingPair} title="Sell Orders (Ask)"/> }
        </Grid>

      </Grid>

      { showSellModal && summary && user && user.tradingPair && <SellModal waitForBearSignal maxSell={Number(summary?.tradingPairSummary.estValueInUsd.toFixed(2))} open={showSellModal} handleClose={() => setShowSellModal(false)} tradingPair={user.tradingPair} /> }

      <Fab variant="extended" style={styles.fab} onClick={() => setShowSellModal(true)}>
        <SellIcon sx={{ mr: 1 }} /> Sell
      </Fab>

      <Snackbar
      open={!!error} 
      onClose={() => {}} autoHideDuration={3000}>
        <Alert variant='filled' severity="error" >
        {error}
      </Alert>
      </Snackbar>

      </Grid> }
    </div>
  
  )

}

export const styles = {
  card: {
    height: 210,
    padding: 16,
  },
  fab: { 
    position: "fixed" as "fixed",
    margin: 0, 
    top: "auto", 
    right: 32, 
    bottom: 32, 
    left: "auto" 
  },
  loading: {
    float: "right" as "right"
  },
  avatar1: {
    backgroundColor: blue[500],
    fontWeight: "700",
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
  },
  avatar4: {
    backgroundColor: green["A400"],
    fontWeight: "700",
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    float: "right" as "right",
  }
}