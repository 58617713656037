import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { CheckProfitResponse } from '../../../interface/CheckProfitResponse';

interface CheckProfitSectionProps
{
  checkProfitResponse: CheckProfitResponse
}

export const CheckProfitSection = ({ checkProfitResponse }: CheckProfitSectionProps) =>
{


  const getProfitStyle = (profit: number) =>
  {
    try
    {
      if(profit === 0) return styles.profitneutral
      if(profit > 0) return styles.profitPositive
      return styles.profitNegative
    }
    catch(error)
    {
      return {}
    }
  }

  return(
      <List dense>
            <ListItem>
              <ListItemText
                primary="Current Market Price (USD)"
                secondary={`$${checkProfitResponse.currentMarketPrice}`} />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Last Buy Price (USD)"
                secondary={`$${checkProfitResponse.lastBuy}`} />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Price Difference"
                secondary={`$${checkProfitResponse.priceDifference}`} />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Profit Percentage"
                secondaryTypographyProps={{
                  style: getProfitStyle(checkProfitResponse.percent)
                }}
                secondary={`${checkProfitResponse.percent}%`} />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Profit (USD)"
                secondaryTypographyProps={{
                  style: getProfitStyle(checkProfitResponse.profit)
                }}
                secondary={`$${checkProfitResponse.profit}`} />
            </ListItem>

          
      </List>
  )
}

const styles = {
  profitPositive: {
    backgroundColor: "#4CAF50", borderRadius: 12, padding: 4, width: 80
  },
  profitNegative: {
    backgroundColor: "#EF5350", borderRadius: 12, padding: 4, width: 80
  },
  profitneutral: {
    backgroundColor: "#42A5F5", borderRadius: 12, padding: 4, width: 80

  }
}