import { Typography } from "@mui/material"
import LogIcon from '@mui/icons-material/ReceiptLong'

export const NoLogs = () =>
{
  return(
    <div style={styles.container}>

      <LogIcon style={styles.icon} />
      <Typography fontWeight="700" variant="subtitle1" color="textPrimary">Looks like there's no logs for that time range</Typography>
      <Typography variant="body2" color="textSecondary">Try adjusting the time range filter to look back further</Typography>

    </div>
  )
}

const styles = {
  container: {
    height: '90vh',
    justifyContent: 'center' as 'center',
    flex: 1,
    textAlign: 'center' as 'center',
    aligItems: 'center' as 'center',
    flexDirection: "column" as 'column',
    display: 'flex'as 'flex'
  },
  icon: {
    height: 128,
    width: 128,
    alignSelf: 'center',
    color: "grey"
  }
}