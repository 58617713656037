import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"

import { SignalItem } from "../../interface/SignalItem"
import { SignalSource } from "./SignalSource"
import { useEffect, useState } from "react"
import { UserApi } from "../../api/UserApi"
import { Skeleton } from "@mui/material"

export const SignalSourceSection = () =>
{

  const [ loading, setLoading ] = useState(false)
  const [ signals, setSignals ] = useState<SignalItem[] | undefined>([])

  useEffect(() => {
    getSignals()
  }, [])

  const getSignals = async () =>
  {
    try
    {
      setLoading(true)
      const userApi = new UserApi()
      const signals = await userApi.getSignalsList()
      setSignals(signals?.sort((a, b) => b.timeout - a.timeout))
      setLoading(false)
    }
    catch(error)
    {
      setLoading(false)
    }

  }

  const getUniqueSignalSources = (signalItems: SignalItem[]) =>
  {
    const uniquieSignals: SignalItem[] = []

    for(const signal of signalItems)
    {
      if(!uniquieSignals.find(s => s.source === signal.source)) uniquieSignals.push(signal)
    }

    return uniquieSignals
  }
  
  return( 
    <div>

      <Typography color="textSecondary" variant="subtitle1" style={styles.subText}>Signal Sources</Typography>

      <Card style={styles.switchesCard}>
        <>
        { !loading && signals ? getUniqueSignalSources(signals).map((signal, key) => <SignalSource key={key} signal={signal} />) : 
        
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </> }
        </>
      </Card>
    </div>
  )
}

const styles = {
  switchesCard: {
    margin: 12,
    padding: 12,
  },
  subText: {
    marginTop: 12,
  },
}