import { CheckProfitResponse } from "../interface/CheckProfitResponse";
import { MarketSellResponse } from "../interface/MarketSellResponse";
import { HttpService } from "./HttpService";

export class SellApi extends HttpService
{
  checkProfit = async (tradingPair: string, amountToSell: number): Promise<CheckProfitResponse | undefined> =>
  {
    const response = await this.request<CheckProfitResponse>("GET", `sell/checkProfit?tradingPair=${tradingPair}&amountToSell=${amountToSell}`)
    if(response) return response.data
    return undefined
  }

  makeMarketSell = async (tradingPair: string, amountToSell: number, waitForBears: boolean): Promise<MarketSellResponse | undefined> =>
  {
    const response = await this.request<MarketSellResponse>("POST", `sell/market?tradingPair=${tradingPair}&amountToSell=${amountToSell}&waitForBears=${waitForBears}`)
    if(response) return response.data
    return undefined
  }

}