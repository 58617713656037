import { useState } from "react"
import { LogApi } from "../api/LogApi"
import { IRunEvent } from "../interface/IRunEvent"
import moment from "moment"
import { LOG_RANGE } from "../const"


export const useLogs = () =>
{
  const [ logs, setLogs ] = useState<IRunEvent[] | undefined>([])
  const [ isLoadingLogs, setLoading ] = useState(false)

  const getLogs = async (fromTime: string, limit = 100) =>
  {
    try
    {
      setLoading(true)
      const response = await new LogApi().getRunEvents("asc", limit, 0, fromTime)
      setLogs(response)
      setLoading(false)
    }
    catch(error)
    {
      console.error(error)
      setLoading(false)
    }
  }

  const findLogAtCandle = (candle: Partial<{timestamp: string}>) =>
  {
    return logs?.find(log => moment(log.timestamp).isBetween(moment(candle.timestamp).subtract(LOG_RANGE, 'minute'), moment(candle.timestamp).add(LOG_RANGE, 'minute')));
  }

  return { isLoadingLogs, logs, getLogs, findLogAtCandle }
}