import { AdxItem } from "../interface/AdxItem";
import { BollingerBandItem } from "../interface/BollingerBandItem";
import { BullishBearish } from "../interface/BullishBearish";
import { Candle } from "../interface/Candle";
import { EMASMA, SMAItem, TripleEma } from "../interface/EMASMA";
import { EMA_TYPES } from "../interface/EMA_TYPES";
import { IRunEvent } from "../interface/IRunEvent";
import { MACDItem } from "../interface/MACDItem";
import { MFIItem } from "../interface/MFIItem";
import { ProfitSummary } from "../interface/ProfitSummary";
import { RSIItem, RSIMCrossOver, TripleRSIItem } from "../interface/RSIItem";
import { Summary } from "../interface/Summary";
import { VWAPItem } from "../interface/VWAPItem";
import { VolumeProfileItem } from "../interface/VolumeProfileItem";
import { HttpService } from "./HttpService";

export class LogApi extends HttpService
{
  getRunEvents = async (order: "asc" | "desc", limit: number, skip: number, fromTime?: string): Promise<IRunEvent[] | undefined> =>
  {
    const response = await this.request<IRunEvent[]>("GET", `logs?order=${order}&limit=${limit}&skip=${skip}&fromTime=${fromTime}`)
    if(response) return response.data
    return []
  }

  getProfitSummary = async (): Promise<ProfitSummary[] | undefined> =>
  {
    const response = await this.request<ProfitSummary[]>("GET", `logs/profit`)
    if(response) return response.data
    return []
  }

  getSummary = async (tradingPair: string): Promise<Summary | undefined> =>
  {
    const response = await this.request<Summary>("GET", `logs/summary?tradingPair=${tradingPair}`)
    if(response) return response.data
  }

  getCandles = async (marketId: string, interval: string) =>
  {
    const response = await this.request<Candle[]>("GET", `logs/candles?marketId=${marketId}&interval=${interval}`)
    if(response) return response.data
  }

  getMACD = async (marketId: string, interval: string) =>
  {
    const response = await this.request<MACDItem[]>("GET", `logs/macd?marketId=${marketId}&interval=${interval}`)
    if(response) return response.data
  }

  getEMA = async (marketId: string, interval: string, emaShortPeriod: number, emaLongPeriod: number, emaType: EMA_TYPES) =>
  {
    const response = await this.request<EMASMA[]>("GET", `logs/ema?marketId=${marketId}&interval=${interval}&emaShortPeriod=${emaShortPeriod}&emaLongPeriod=${emaLongPeriod}&emaType=${emaType}`)
    if(response) return response.data
  }

  getTripleEma = async (marketId: string, interval: string, emaShortPeriod: number, emaMediumPeriod: number, emaLongPeriod: number, volumeShort: number, volumeLong: number) =>
  {
    const response = await this.request<TripleEma[]>("GET", `logs/tripleEma?marketId=${marketId}&interval=${interval}&emaShortPeriod=${emaShortPeriod}&emaMediumPeriod=${emaMediumPeriod}&emaLongPeriod=${emaLongPeriod}&volumeShort=${volumeShort}&volumeLong=${volumeLong}`)
    if(response) return response.data
  }

  getVWAP = async (marketId: string, interval: string) =>
  {
    const response = await this.request<VWAPItem[]>("GET", `logs/vwap?marketId=${marketId}&interval=${interval}`)
    if(response) return response.data
  }

  getBollingerBands = async (marketId: string, interval: string) =>
  {
    const response = await this.request<BollingerBandItem[]>("GET", `logs/bollingerbands?marketId=${marketId}&interval=${interval}`)
    if(response) return response.data
  }

  getRSI = async (marketId: string, interval: string) =>
  {
    const response = await this.request<RSIItem[]>("GET", `logs/rsi?marketId=${marketId}&interval=${interval}`)
    if(response) return response.data
  }

  getVolumeProfile = async (marketId: string, interval: string, bars: number) =>
  {
    const response = await this.request<VolumeProfileItem[]>("GET", `logs/volumeProfile?marketId=${marketId}&interval=${interval}&bars=${bars}`)
    if(response) return response.data
  }

  getTripleRSI = async (marketId: string, rsi1: string, rsi2: string, rsi3: string) =>
  {
    const response = await this.request<TripleRSIItem[]>("GET", `logs/triplersi?marketId=${marketId}&rsi1=${rsi1}&rsi2=${rsi2}&rsi3=${rsi3}`)
    if(response) return response.data
  }

  getRSICrossOver = async (marketId: string, interval: string, rsiShort: number, rsiMedium: number, rsiLong: number) =>
  {
    const response = await this.request<RSIMCrossOver[]>("GET", `logs/rsicrossover?marketId=${marketId}&interval=${interval}&rsiShort=${rsiShort}&rsiMedium=${rsiMedium}&rsiLong=${rsiLong}`)
    if(response) return response.data
  }

  getSMA = async (marketId: string, interval: string, period: number) =>
  {
    const response = await this.request<SMAItem[]>("GET", `logs/sma?marketId=${marketId}&interval=${interval}&period=${period}`)
    if(response) return response.data
  }

  getMfi = async (marketId: string, interval: string) =>
  {
    const response = await this.request<MFIItem[]>("GET", `logs/mfi?marketId=${marketId}&interval=${interval}`)
    if(response) return response.data
  }

  checkIsBullishOrBearish = async (marketId: string, interval: string) =>
  {
    const response = await this.request<BullishBearish>("GET", `logs/bullish?marketId=${marketId}&interval=${interval}`)
    if(response) return response.data
  }

  getAdx = async (marketId: string, period: number, interval: string) =>
  {
    const response = await this.request<AdxItem[]>("GET", `logs/adx?marketId=${marketId}&period=${period}&interval=${interval}`)
    if(response) return response.data
  }
}