import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InsightsIcon from '@mui/icons-material/Insights';
import Typography from '@mui/material/Typography';
import { Copyright } from '../components/Copyright';
import bgImage from '../assets/signupbackground.jpg'
import { useState } from 'react';
import { LoginApi } from '../api/LoginApi';
import { KEY_TOKEN } from '../const';
import Snackbar from '@mui/material/Snackbar'
import { AxiosError } from 'axios';
import Alert from '@mui/material/Alert';
import SmartBanner from 'react-smartbanner';

export const  SignUp = () => {

  const [ loading, setLoading ] = useState(false)
  const [ email, setEmail ] = useState("")
  const [ password, setPassword ] = useState("")
  const [ confirmPassword, setConfirmPassword ] = useState("")
  const [ error, setError ] = useState("")
  const [ termsChecked, setTermsChecked ] = useState(false)

  const signUp = async () =>
  {

    if(!termsChecked) return setError("Please accept the terms and conditions")
    try
    {
      setLoading(true)
      const loginApi = new LoginApi()
      const response = await loginApi.signup(email, password, confirmPassword)

      if(response && response.token)
      {
        localStorage.setItem(KEY_TOKEN, JSON.stringify(response))
        window.location.href = "/dashboard"
      }
    }
    catch(error)
    {
      setError(getSignupErrorResponse(error as AxiosError))
      setLoading(false)
    }
  }

  const getSignupErrorResponse = (error: AxiosError) =>
  {
    try
    {
      console.log(error)
      // @ts-ignore
      return error.message.response.data.error
    }
    catch(error)
    {
      return ""
    }
  }

  return (
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />


        <SmartBanner title="AlgoTrader" />

        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <InsightsIcon />
            </Avatar>
            <Typography component="h1" variant="h5" fontWeight="700">
              AlgoTrader Sign up
            </Typography>
            <Box component="form" noValidate sx={{ m: 2 }}>
              <TextField
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
              />
               <TextField
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
              />
              <FormControlLabel
                value={termsChecked}
                onChange={(e: any) => setTermsChecked(e.target.checked)}
                control={<Checkbox value="remember" color="primary" />}
                label="I Accept the Terms and Conditions"
              />
              <Button
                disabled={loading}
                onClick={signUp}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {loading ? "Signing up..." : "Sign Up"}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/terms" variant="body2">
                    Terms and Conditions
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/privacy" variant="body2">
                    Privacy Policy
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>

        <Snackbar open={!!error} onClose={() => setError("")} autoHideDuration={5000} >
          <Alert severity="error" icon={<></>} style={{ backgroundColor: "#EF5350", color: "white" }}>{error}</Alert>
        </Snackbar>

      </Grid>
  );
}