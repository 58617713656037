import { BackTest } from "../../interface/BackTest"
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from 'moment'
import { ProfitTablet } from "./ProfitTablet";

interface BackTestResultRowProps
{
  row: BackTest
  onShowRunEvents: (row: BackTest) => void
}


export const BackTestResultRow = ({ row, onShowRunEvents }: BackTestResultRowProps) =>
{
  return(
    <TableRow
            onClick={() => onShowRunEvents(row)}
            hover
             key={row.timestamp}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">{`${moment(row.timestamp).format("lll")} (${moment(row.timestamp).fromNow()})`}</TableCell>
              <TableCell align="right">{row.marketId}</TableCell>
              <TableCell align="right">{row.indicator}</TableCell>
              <TableCell align="right">{row.winPercent.toFixed(0)}% ({row.wins} wins)</TableCell>
              <TableCell align="right"><ProfitTablet profit={Math.round(row.profit * 100) / 100} /></TableCell>
              <TableCell align="right">${row.totalAmountSold}</TableCell>
              <TableCell align="right">${row.totalAmountPurchased}</TableCell>
              <TableCell align="right">{row.candle}</TableCell>
              <TableCell align="right">{`S/L ${row.stopLoss}% - T/P ${row.takeProfit}% - Min Profit ${row.minSellProfit}%`}</TableCell>


            </TableRow>
  )
}