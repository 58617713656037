import { EMA_TYPES } from "../interface/EMA_TYPES"
import Button from '@mui/material/Button'


interface EmaChooserProps
{
  onChange: (type: EMA_TYPES) => void
  emaType: EMA_TYPES | undefined
}

export const EmaChooser = ({ onChange, emaType }: EmaChooserProps) =>
{
  return(
    <div style={styles.container}>
      <Button style={styles.button} onClick={() => onChange(EMA_TYPES.SINGLE_EMA)} variant={emaType === EMA_TYPES.SINGLE_EMA ? "contained" : "outlined"} >Single EMA</Button>
      <Button style={styles.button} onClick={() => onChange(EMA_TYPES.DOUBLE_EMA)} variant={emaType === EMA_TYPES.DOUBLE_EMA ? "contained" : "outlined"}>Double EMA</Button>
      <Button style={styles.button} onClick={() => onChange(EMA_TYPES.TRIPLE_EMA)} variant={emaType === EMA_TYPES.TRIPLE_EMA ? "contained" : "outlined"}>Triple EMA</Button>
    </div>
  )
}

const styles = {
  container: {
    flexDirection: "row" as "row",
    display: 'flex' as 'flex'
  },
  button: {
    margin: 6,
  }
}