import { ApiKeyResponse } from "../interface/ApiKeyResponse";
import { ExchangeRate } from "../interface/ExchangeRate";
import { SignalItem } from "../interface/SignalItem";
import { Symbol } from "../interface/Symbol";
import { User } from "../interface/User";
import { HttpService } from "./HttpService";

export class UserApi extends HttpService
{
  setSettings = async (payload: any): Promise<User | undefined> =>
  {
    const response = await this.request<User>("POST", `user/settings`, payload)
    if(response) return response.data
  }

  getAudUsdExchangeRate = async (): Promise<ExchangeRate | undefined> =>
  {
    const response = await this.request<ExchangeRate>("GET", `user/exchangerate`)

    if(response) return response.data
  }

  getSignalsList = async (): Promise<SignalItem[] | undefined> =>
  {
    const response = await this.request<SignalItem[]>("GET", `user/signals`)

    if(response) return response.data
  }

  getSettings = async (): Promise<User | undefined> =>
  {
    const response = await this.request<User>("GET", `user`)

    if(response) return response.data
  }

  getSymbols = async (): Promise<Symbol[] | undefined> =>
  {
    const response = await this.request<Symbol[]>("GET", `user/pairs`)

    if(response) return response.data
  }

  validateApiKey = async (): Promise<ApiKeyResponse | undefined> =>
  {
    const response = await this.request<ApiKeyResponse | undefined>("POST", `user/validateApiKey`)
    if(response) return response.data
  }
}