import { IChat } from "../../../../interface/Chat";

/**
 * 
 * @param conversations 
 * @param chat 
 * @returns 
 */
export const addMessageToConversation = (conversations: IChat[], chat: IChat) =>
{
  return conversations.map(conversation => {
    if(conversation._id === chat._id) return chat
    return conversation
  })
}